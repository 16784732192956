// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, Icon, useTheme } from "@merit/frontend-components";
import {
  OrgsGet200ResponseContainersInnerStateNameEnum as ContainerState,
  type ListTemplates200ResponseTemplatesInnerTypeEnum,
  type OrgsGet200ResponseContainersInner,
  type OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum,
  type GetDatasource200ResponseMappedTemplatesInner as Template,
} from "../../gen";
import { CredentialsTab } from "./CredentialsTab";
import { Datagrid, DatagridBody } from "../../components/Datagrid";
import { DetailsDrawer } from "../../layouts/DetailsDrawer";
import { EllipsisText } from "../../components/EllipsisText";
import { Failures } from "../Approvals/Failures";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Image, Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import { Spin, Tabs, Tooltip } from "../../components";
import { doesActivityContainsAllRequiredFields } from "../../utils/Activity";
import { getContainerFieldValue } from "../../utils/getContainerFieldValue";
import { getDateTimeString } from "../../utils/time";
import { getFullName } from "../../utils/getFullName";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useInferContainerTemplateType } from "../../utils/inferContainerTemplateType";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { ReactNode } from "react";

const { None, Some } = Helpers;

const SCREEN_NAME = "RecordDetails";

type TabName = "credentials" | "failures" | "fields" | "overview";
type Tab = { readonly key: TabName; readonly label: ReactNode | string };

type Props = {
  readonly templateType?: ListTemplates200ResponseTemplatesInnerTypeEnum;
  readonly id: string;
  readonly onDrawerClose: () => void;
  readonly onPressDelete: () => void;
  readonly onPressRevoke: () => void;
  readonly onPressReissue: () => void;
  readonly templateID?: string;
};

const containerStatesToShowReissueButton: readonly string[] = [
  ContainerState.Revoked,
  ContainerState.Rejected,
];

export const RecordDetails = ({
  id,
  onDrawerClose,
  onPressDelete,
  onPressReissue,
  onPressRevoke,
  templateID,
  templateType: templateTypeOpt,
}: Props) => {
  const { allowOrgPortalRevokeContainer, showStudioFrontend } = useFlags<LDFeatureFlags>();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { folioFieldNames, meritFieldNames } = useAppConstantsStore();
  const [selectedTab, setSelectedTab] = useState<TabName>("overview");
  const { theme } = useTheme();
  const { errorHandler } = useServerErrorHandler();
  const [record, setRecord] = useState<OrgsGet200ResponseContainersInner>();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const { inferContainerTemplateType } = useInferContainerTemplateType();
  const [templateType, setTemplateType] =
    useState<ListTemplates200ResponseTemplatesInnerTypeEnum>("Merit");
  const [template, setTemplate] = useState<Template>();

  const { configuration } = useLoadedConfigurationState();

  useEffect(() => {
    const fetchRecord = async () => {
      if (record !== undefined) {
        return;
      }

      try {
        const res = await api.getContainer({
          containerID: id,
          orgID: selectedOrgId,
        });

        setRecord(res.container);
        setTemplateType(
          templateTypeOpt === undefined
            ? inferContainerTemplateType(res.container)
            : templateTypeOpt
        );
      } catch (e: unknown) {
        errorHandler(e);
        setFetchError(true);
      }
    };

    fetchRecord();
  }, [api, errorHandler, id, inferContainerTemplateType, record, selectedOrgId, templateTypeOpt]);

  useEffect(() => {
    if (templateID === undefined) {
      return;
    }

    const getTemplate = async () => {
      try {
        const response = await api.getTemplate({
          orgID: selectedOrgId,
          templateID,
        });
        setTemplate(response);
      } catch (error) {
        errorHandler(error);
      }
    };

    getTemplate();
  }, [api, errorHandler, selectedOrgId, templateID]);

  const styles = StyleSheet.create({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    tabContainer: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      paddingHorizontal: 32,
    },
  });

  type fieldsRowDataType = {
    readonly description?: string;
    readonly name?: string;
    readonly templateFieldID?: string;
    readonly value?: string;
    readonly dataType?: OrgsGet200ResponseContainersInnerFieldsInnerFieldKindFieldTypeEnum;
  };

  const columns: readonly DatagridColumn<fieldsRowDataType>[] = [
    {
      key: "value",
      label: "Value",
      renderer: (thisRecord: fieldsRowDataType, testProps) => {
        const value = thisRecord.value;

        return thisRecord.dataType === "JSON" ? (
          <>
            <EllipsisText testProps={testProps} text={value ?? "--"} />
            {Some(value) && doesActivityContainsAllRequiredFields(value) && (
              <>
                <HorizontalSpacer />
                <Pressable
                  onPress={() => {
                    window.open(configuration.meritCheckinURL);
                  }}
                  {...Helpers.generateTestIdProps({
                    elementId: thisRecord.templateFieldID,
                    elementName: "detailsViewFieldsTabFieldViewLink",
                    screenName: SCREEN_NAME,
                  })}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Body color={theme.colors.border.highlight.default}>View</Body>
                    <HorizontalSpacer size={theme.spacing.s} />
                    <Image
                      accessibilityLabel="link-to-checkin"
                      source={Images.arrowExpand}
                      style={{ height: 20, width: 20 }}
                    />
                  </View>
                </Pressable>
              </>
            )}
          </>
        ) : (
          <EllipsisText testProps={testProps} text={value ?? "--"} />
        );
      },
      size: "flex",
    },
    { key: "name", label: "Field name", size: "flex" },
    {
      key: "description",
      label: "Field description",
      renderer: ({ description }, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(description) && description.length > 0 ? description : "--"}
        />
      ),
      size: "flex",
    },
    { key: "dataType", label: "Data type", size: "flex" },
    {
      key: "Access",
      label: "Access",
      renderer: ({ templateFieldID }, testProps) => {
        const fieldKind = template?.templateFields?.find(
          templateField => templateField.fieldID === templateFieldID
        );

        if (None(fieldKind) || None(fieldKind.permissions)) {
          return undefined;
        }

        const perms = fieldKind.permissions.reduce<readonly string[]>((prev, permission) => {
          if (
            permission.permitted.grantedToName === "All" &&
            permission.permitted.action === "read_data"
          ) {
            return [...prev, permission.action];
          }

          return prev;
        }, []);

        if (perms.length > 0) {
          return (
            <Tooltip
              icon="viewMediumDefault"
              testProps={testProps}
              text="This information can be accessed by everyone in your organization."
            />
          );
        }

        return (
          <Tooltip
            icon="viewOffMediumDefault"
            testProps={testProps}
            text="This information is for internal only and can not be accessed by individuals in your organization."
          />
        );
      },
      size: "flex",
    },
  ];

  if (fetchError) {
    return (
      <View
        style={{
          alignItems: "center",
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Body>{`${templateType} not found`}</Body>
      </View>
    );
  }

  if (record === undefined) {
    return (
      <View
        style={{
          alignItems: "center",
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  const fieldValidationErrors = record.fields
    ?.filter(field => Some(field.validationErrors))
    .map(field => field.validationErrors ?? [])
    .flat();

  const hasFailures =
    Some(record.activenessFailures) ||
    Some(record.completenessFailures) ||
    (Some(fieldValidationErrors) && fieldValidationErrors.length > 0);

  const recordStateName = record.state?.name;
  const recordIsRevocable =
    allowOrgPortalRevokeContainer &&
    Some(record.state) &&
    record.templateId !== configuration.accountMeritTemplateUUID &&
    record.templateId !== configuration.accountFolioTemplateUUID &&
    (recordStateName === ContainerState.Pending ||
      recordStateName === ContainerState.Accepted ||
      recordStateName === ContainerState.Rejected);

  const tabs: readonly Tab[] = (() => {
    const defaultTabs: readonly Tab[] = [
      { key: "overview", label: "Overview" },
      { key: "fields", label: "Fields" },
      {
        key: "failures",
        label: (
          <View style={{ flexDirection: "row" }}>
            <Body
              testProps={{ elementName: "recordsDetailsViewFailuresTab", screenName: SCREEN_NAME }}
            >
              Failures
            </Body>
            {hasFailures && (
              <>
                <HorizontalSpacer size={theme.spacing.xs} />
                <Icon
                  name="warningMediumCritical"
                  testProps={{
                    elementName: "recordsDetailsViewFailuresTabIcon",
                    screenName: SCREEN_NAME,
                  }}
                />
              </>
            )}
          </View>
        ),
      },
    ];
    const credentialsTab: Tab = { key: "credentials", label: "Credentials" };

    return showStudioFrontend ? [...defaultTabs, credentialsTab] : defaultTabs;
  })();

  return (
    <DetailsDrawer
      actionButtons={
        <View style={{ flexDirection: "row" }}>
          <View style={{ maxWidth: 100 }}>
            <Button
              onPress={onPressDelete}
              size="small"
              testProps={{
                elementId: record.id,
                elementName: "recordsDetailsViewRemoveButton",
                screenName: SCREEN_NAME,
              }}
              text="Remove"
              type="destructive"
            />
          </View>
          {Some(record.state) && containerStatesToShowReissueButton.includes(record.state.name) && (
            <>
              <HorizontalSpacer />
              <View style={{ maxWidth: 100 }}>
                <Button
                  onPress={onPressReissue}
                  size="small"
                  testProps={{
                    elementId: record.id,
                    elementName: "recordsDetailsViewReissueButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Reissue"
                  type="secondary"
                />
              </View>
            </>
          )}
          {recordIsRevocable && (
            <>
              <HorizontalSpacer />
              <View style={{ maxWidth: 100 }}>
                <Button
                  onPress={onPressRevoke}
                  size="small"
                  testProps={{
                    elementId: record.id,
                    elementName: "recordsDetailsViewRevokeButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Revoke"
                  type="secondary"
                />
              </View>
            </>
          )}
        </View>
      }
      onPressClose={() => {
        setSelectedTab(tabs[0].key);
        onDrawerClose();
      }}
      subTitle={
        <View style={{ flexDirection: "row" }}>
          <Heading
            level="6"
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewContainerId",
              screenName: SCREEN_NAME,
            }}
          >
            Container ID: {record.id}
          </Heading>
          <HorizontalSpacer size={theme.spacing.m} />
          <Heading color={theme.colors.text.subdued} level="6">
            |
          </Heading>
          <HorizontalSpacer size={theme.spacing.m} />
          <Heading
            capitalize
            level="6"
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewStatus",
              screenName: SCREEN_NAME,
            }}
          >
            Status: {Some(record.active) && record.active ? "Active" : "Inactive"}
          </Heading>
          <HorizontalSpacer size={theme.spacing.m} />
          <Heading color={theme.colors.text.subdued} level="6">
            |
          </Heading>
          <HorizontalSpacer size={theme.spacing.m} />
          <Heading
            capitalize
            level="6"
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewState",
              screenName: SCREEN_NAME,
            }}
          >
            State: {Some(record.state) ? record.state.name : "--"}
          </Heading>
        </View>
      }
      testProps={{
        elementId: record.id,
        elementName: "recordsDetailsView",
        screenName: SCREEN_NAME,
      }}
      title={record.name === undefined ? "" : record.name}
    >
      <View style={styles.tabContainer}>
        <Tabs
          items={tabs}
          onChange={tab => {
            setSelectedTab(tab);
          }}
          selected={selectedTab}
          testProps={{
            elementId: record.id,
            elementName: "recordsDetailsView",
            screenName: SCREEN_NAME,
          }}
        />
      </View>

      {selectedTab === "overview" && (
        <View style={{ paddingHorizontal: 32, paddingVertical: theme.spacing.xxl }}>
          <Body style={styles.bold}>Description</Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <Body
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewOverviewTabDescription",
              screenName: SCREEN_NAME,
            }}
          >
            {record.description}
          </Body>

          <VerticalSpacer size={theme.spacing.xxl} />

          <Body style={styles.bold}>Type</Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <Body
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewOverviewTabType",
              screenName: SCREEN_NAME,
            }}
          >
            {templateType}
          </Body>

          <VerticalSpacer size={theme.spacing.xxl} />

          <Body style={styles.bold}>Issuing Org</Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <Body
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewOverviewTabIssuingOrg",
              screenName: SCREEN_NAME,
            }}
          >
            {getContainerFieldValue(
              templateType === "Merit"
                ? meritFieldNames.issuingOrgName
                : folioFieldNames.issuingOrgName,
              record
            ) ?? "--"}
          </Body>

          <VerticalSpacer size={theme.spacing.xxl} />

          <Body style={styles.bold}>Recipient</Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <View style={{ flexDirection: "row" }}>
            {templateType === "Merit" && (
              <Body
                testProps={{
                  elementId: record.id,
                  elementName: "recordsDetailsViewOverviewTabRecipientName",
                  screenName: SCREEN_NAME,
                }}
              >
                {getFullName(
                  getContainerFieldValue(meritFieldNames.firstName, record),
                  getContainerFieldValue(meritFieldNames.lastName, record)
                )}
              </Body>
            )}
            {templateType === "Folio" && (
              <Body
                testProps={{
                  elementId: record.id,
                  elementName: "recordsDetailsViewOverviewTabRecipient",
                  screenName: SCREEN_NAME,
                }}
              >
                {getContainerFieldValue(folioFieldNames.organizationName, record) ?? "--"}
              </Body>
            )}
            <HorizontalSpacer size={10} />
            <Body style={{ color: theme.colors.text.subdued }}>|</Body>
            <HorizontalSpacer size={10} />
            {templateType === "Merit" && (
              <Body
                testProps={{
                  elementId: record.id,
                  elementName: "recordsDetailsViewOverviewTabRecipientEmail",
                  screenName: SCREEN_NAME,
                }}
              >
                {getContainerFieldValue(meritFieldNames.email, record) ?? "--"}
              </Body>
            )}
            {templateType === "Folio" && (
              <Body
                testProps={{
                  elementId: record.id,
                  elementName: "recordsDetailsViewOverviewTabRecipientId",
                  screenName: SCREEN_NAME,
                }}
              >
                {record.recipient?.id ?? "--"}
              </Body>
            )}
          </View>

          <VerticalSpacer size={theme.spacing.xxl} />

          <Body style={styles.bold}>Created at</Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <Body
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewOverviewTabCreatedAt",
              screenName: SCREEN_NAME,
            }}
          >
            {Some(record.createdAt) ? getDateTimeString(record.createdAt) : "--"}
          </Body>

          <VerticalSpacer size={theme.spacing.xxl} />

          <Body style={styles.bold}>Accepted on</Body>
          <VerticalSpacer size={theme.spacing.xs} />
          <Body
            testProps={{
              elementId: record.id,
              elementName: "recordsDetailsViewOverviewTabAcceptedOn",
              screenName: SCREEN_NAME,
            }}
          >
            {Some(record.acceptedAt) ? getDateTimeString(record.acceptedAt) : "--"}
          </Body>

          <VerticalSpacer size={theme.spacing.xxl} />
        </View>
      )}

      {selectedTab === "fields" && (
        <>
          {Some(record.fields) ? (
            <ScrollView>
              <VerticalSpacer size={theme.spacing.xxl} />

              <Datagrid
                columns={columns}
                testProps={{
                  elementName: "recordsDetailsViewFieldsTab",
                  screenName: SCREEN_NAME,
                }}
              >
                <DatagridBody
                  columns={columns}
                  data={record.fields.map(field => ({
                    description: field.description,
                    name: field.name,
                    templateFieldID: field.templateFieldID,
                    value: field.value,
                    ...(Some(field.fieldKind) && {
                      dataType: field.fieldKind.fieldType,
                    }),
                  }))}
                  testProps={{
                    elementName: "recordsDetailsViewFieldsTab",
                    screenName: SCREEN_NAME,
                  }}
                  testPropsElementIdKey="templateFieldID"
                />
              </Datagrid>
            </ScrollView>
          ) : (
            <View style={{ alignItems: "center" }}>
              <Body
                testProps={{
                  elementName: "recordsDetailsViewFieldsTabNoDataPlaceholder",
                  screenName: SCREEN_NAME,
                }}
              >
                There are no fields
              </Body>
            </View>
          )}
        </>
      )}

      {selectedTab === "failures" && Some(record.fields) && (
        <Failures
          activenessFailures={record.activenessFailures}
          closeDrawer={() => {
            setSelectedTab(tabs[0].key);
            onDrawerClose();
          }}
          completenessFailures={record.completenessFailures}
          fieldValidationErrors={fieldValidationErrors}
          templateFields={record.fields}
          testProps={{
            elementName: "RecordDetailsFailuresTab",
            screenName: SCREEN_NAME,
          }}
        />
      )}

      {selectedTab === "credentials" && Some(record.fields) && (
        <CredentialsTab
          containerId={record.id}
          testProps={{
            elementName: "RecordDetailsCredentialsTab",
            screenName: SCREEN_NAME,
          }}
        />
      )}
    </DetailsDrawer>
  );
};
