/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateAgentRequestCapabilitiesInner } from './CreateAgentRequestCapabilitiesInner';
import {
    CreateAgentRequestCapabilitiesInnerFromJSON,
    CreateAgentRequestCapabilitiesInnerFromJSONTyped,
    CreateAgentRequestCapabilitiesInnerToJSON,
} from './CreateAgentRequestCapabilitiesInner';
import type { GetAgents200ResponseAgentsInnerAuth0Options } from './GetAgents200ResponseAgentsInnerAuth0Options';
import {
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON,
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSONTyped,
    GetAgents200ResponseAgentsInnerAuth0OptionsToJSON,
} from './GetAgents200ResponseAgentsInnerAuth0Options';

/**
 * An existing or newly created agent
 * @export
 * @interface CreateAgentRequest
 */
export interface CreateAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAgentRequest
     */
    accessType: CreateAgentRequestAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentRequest
     */
    privacyTos: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentRequest
     */
    usageTos: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentRequest
     */
    dataTos: string;
    /**
     * The capabilities that this agent requires
     * @type {Array<CreateAgentRequestCapabilitiesInner>}
     * @memberof CreateAgentRequest
     */
    capabilities: Array<CreateAgentRequestCapabilitiesInner>;
    /**
     * URI to which the user is re-directed after a successful login. Must be https.
     * @type {string}
     * @memberof CreateAgentRequest
     */
    loginURI?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateAgentRequest
     */
    additionals?: { [key: string]: string; };
    /**
     * 
     * @type {GetAgents200ResponseAgentsInnerAuth0Options}
     * @memberof CreateAgentRequest
     */
    auth0Options?: GetAgents200ResponseAgentsInnerAuth0Options | null;
}


/**
 * @export
 */
export const CreateAgentRequestAccessTypeEnum = {
    Login: 'login',
    Link: 'link',
    Webapp: 'webapp',
    Native: 'native'
} as const;
export type CreateAgentRequestAccessTypeEnum = typeof CreateAgentRequestAccessTypeEnum[keyof typeof CreateAgentRequestAccessTypeEnum];


/**
 * Check if a given object implements the CreateAgentRequest interface.
 */
export function instanceOfCreateAgentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "privacyTos" in value;
    isInstance = isInstance && "usageTos" in value;
    isInstance = isInstance && "dataTos" in value;
    isInstance = isInstance && "capabilities" in value;

    return isInstance;
}

export function CreateAgentRequestFromJSON(json: any): CreateAgentRequest {
    return CreateAgentRequestFromJSONTyped(json, false);
}

export function CreateAgentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAgentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessType': json['accessType'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'privacyTos': json['privacyTos'],
        'usageTos': json['usageTos'],
        'dataTos': json['dataTos'],
        'capabilities': ((json['capabilities'] as Array<any>).map(CreateAgentRequestCapabilitiesInnerFromJSON)),
        'loginURI': !exists(json, 'loginURI') ? undefined : json['loginURI'],
        'additionals': !exists(json, 'additionals') ? undefined : json['additionals'],
        'auth0Options': !exists(json, 'auth0Options') ? undefined : GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON(json['auth0Options']),
    };
}

export function CreateAgentRequestToJSON(value?: CreateAgentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessType': value.accessType,
        'name': value.name,
        'description': value.description,
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'dataTos': value.dataTos,
        'capabilities': ((value.capabilities as Array<any>).map(CreateAgentRequestCapabilitiesInnerToJSON)),
        'loginURI': value.loginURI,
        'additionals': value.additionals,
        'auth0Options': GetAgents200ResponseAgentsInnerAuth0OptionsToJSON(value.auth0Options),
    };
}

