// Copyright 2023 Merit International Inc. All Rights Reserved

import { useCallback, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { DefaultApi, GetPolicies200Response, GetPoliciesRequest } from "../../gen";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

export const usePoliciesData = (api: DefaultApi, requestParams: GetPoliciesRequest) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<GetPolicies200Response>();
  const { errorHandler } = useServerErrorHandler();
  const [params, setParams] = useState<GetPoliciesRequest>(requestParams);

  // Computed Data
  const data = response?.policies ?? [];
  const pagination: PaginationMeta = {
    hasNextPage: response?.paginationInfo?.nextStartAfter !== undefined,
    hasPrevPage: response?.paginationInfo?.previousEndBefore !== undefined,
    nextStartAfter: response?.paginationInfo?.nextStartAfter,
    prevEndBefore: response?.paginationInfo?.previousEndBefore,
  };

  // Actions
  const refresh = useCallback(
    (nextParams: Partial<GetPoliciesRequest> = {}) => {
      if (isLoading) {
        return;
      }

      setParams({
        ...params,
        ...nextParams,
      });
    },
    [isLoading, params]
  );

  const nextPage = useCallback(() => {
    if (pagination.nextStartAfter === undefined) {
      return;
    }

    refresh({
      end: undefined,
      start: pagination.nextStartAfter,
    });
  }, [pagination.nextStartAfter, refresh]);

  const prevPage = useCallback(() => {
    if (pagination.prevEndBefore === undefined) {
      return;
    }

    refresh({
      end: pagination.prevEndBefore,
      start: undefined,
    });
  }, [pagination.prevEndBefore, refresh]);

  const fetchPolicies = useCallback(async () => {
    try {
      if (params.orgID === "") {
        throw new Error("Organization ID is undefined");
      }

      setIsLoading(true);

      const res = await api.getPolicies(params);

      setResponse(res);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, errorHandler, params]);

  return {
    data,
    fetchPolicies,
    isLoading,
    nextPage,
    pagination,
    prevPage,
    refresh,
    setParams,
  };
};
