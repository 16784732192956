// Copyright 2023 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { DefaultApi, GetContainersRequest, OrgsGet200Response } from "../../gen";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

export const useRecordsData = (api: DefaultApi, body: GetContainersRequest, isFocused: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<OrgsGet200Response>();
  const [params, setParams] = useState<GetContainersRequest>(body);
  const { errorHandler } = useServerErrorHandler();
  const [isDirty, setDirty] = useState<boolean>(false);

  // Initial value is undefined to track no filter is applied by the user yet.
  const data = response?.containers ?? undefined;
  const nextStartAfter = response?.paginationInfo?.nextStartAfter;
  const previousEndBefore = response?.paginationInfo?.previousEndBefore;
  const pagination: PaginationMeta = {
    hasNextPage: nextStartAfter !== "" && nextStartAfter !== undefined,
    hasPrevPage: previousEndBefore !== "" && previousEndBefore !== undefined,
    nextStartAfter: response?.paginationInfo?.nextStartAfter,
    prevEndBefore: response?.paginationInfo?.previousEndBefore,
  };

  const refresh = (nextParams: Partial<GetContainersRequest> = {}) => {
    if (isLoading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
    setDirty(true);
  };

  const nextPage = () => {
    if (pagination.nextStartAfter === undefined) {
      return;
    }

    refresh({
      end: undefined,
      start: pagination.nextStartAfter,
    });
  };

  const prevPage = () => {
    if (pagination.prevEndBefore === undefined) {
      return;
    }

    refresh({
      end: pagination.prevEndBefore,
      start: undefined,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setIsLoading(true);

        const res = await api.getContainers(params);

        setResponse(res);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (isDirty && isFocused) {
      fetchData();
    }
  }, [isDirty, api, errorHandler, params, isFocused]);

  return {
    data,
    isLoading,
    nextPage,
    pagination,
    prevPage,
    refresh,
    setParams,
  };
};
