// Copyright 2024 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useAppConstantsStore } from "../../stores";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  ListTemplates200Response,
  ListTemplatesRequest,
  ListTemplatesStatusEnum,
  ListTemplatesTypeEnum,
} from "../../gen";
import type { Filters } from "./TemplatesFilter";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

const { Some } = Helpers;

export const useTemplateDataList = (
  api: DefaultApi,
  orgID: string,
  ownerID?: string | undefined,
  searchQuery?: string | undefined,
  status?: ListTemplatesStatusEnum,
  type?: ListTemplatesTypeEnum
) => {
  const appConstants = useAppConstantsStore();
  // Constants
  const DEFAULT_PARAMS: ListTemplatesRequest & { readonly searchQuery?: string } = {
    limit: appConstants.templatesLimit,
    orgID,
    ownerID,
    searchQuery,
    status,
    type,
  } as const;

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<ListTemplates200Response>();
  const { errorHandler } = useServerErrorHandler();
  const [params, setParams] = useState<ListTemplatesRequest>(DEFAULT_PARAMS);

  // Computed Data
  const data = response?.templates ?? [];
  const pagination: PaginationMeta = {
    hasNextPage: response?.paginationInfo?.nextStartAfter !== undefined,
    hasPrevPage: response?.paginationInfo?.previousEndBefore !== undefined,
    nextStartAfter: response?.paginationInfo?.nextStartAfter,
    prevEndBefore: response?.paginationInfo?.previousEndBefore,
  };

  // Actions
  const refresh = (nextParams: Partial<ListTemplatesRequest> = {}) => {
    if (loading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const nextPage = () => {
    if (pagination.nextStartAfter === undefined) {
      return;
    }

    refresh({
      end: undefined,
      start: pagination.nextStartAfter,
    });
  };

  const prevPage = () => {
    if (pagination.prevEndBefore === undefined) {
      return;
    }

    refresh({
      end: pagination.prevEndBefore,
      start: undefined,
    });
  };

  const filterData = (filterValues: Filters | undefined) => {
    if (Some(orgID)) {
      const payload = {
        limit: 10,
        orgID,
        ...(Some(filterValues) && {
          status: filterValues.status ?? params.status,
          type: filterValues.type ?? params.type,
        }),
      };
      setParams(payload);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        const res = await api.listTemplates(params);
        setResponse(res);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [api, errorHandler, params]);

  return {
    data,
    filterData,
    loading,
    nextPage,
    pagination,
    prevPage,
    refresh,
  };
};
