// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../components/Datagrid";
import { EllipsisText } from "../../components/EllipsisText";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { Pagination, Spin } from "../../components";
import { ScrollView, StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { getDateTimeString } from "../../utils/time";
import { useApi } from "../../api/api";
import { useDatasourcesListData } from "../Datasources/useDatasourcesListData";
import { useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import React from "react";
import type { ListDatasources200ResponseDatasourcesInner as DataSources } from "../../gen";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

const { Some } = Helpers;

const SCREEN_NAME = "SelectDataSource";

export const DataSourceList = () => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const isFocused = useIsFocused();
  const {
    params: { id: templateID, templateName },
  } = useRoute<RouteProp<RouteParams, "SelectDataSource">>();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "SelectDataSource">>();

  const { data, loading, nextPage, pagination, prevPage } = useDatasourcesListData(
    api,
    selectedOrgId,
    isFocused
  );

  const styles = StyleSheet.create({
    content: {
      alignItems: "center",
      backgroundColor: theme.colors.background.default,
      flex: 1,
      paddingVertical: theme.spacing.xxl,
    },
  });

  const columns: readonly DatagridColumn<DataSources>[] = [
    {
      key: "name",
      label: "Name",
      size: "flex",
    },
    {
      key: "id",
      label: "Data source ID",
      size: 190,
    },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(description) && description !== "" ? description : "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "lastIngestionTime",
      label: "Last ingestion",
      renderer: ({ lastIngestion }, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(lastIngestion) && lastIngestion !== "" ? getDateTimeString(lastIngestion) : "--"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: ({ id }) => (
        <View style={{ maxWidth: 76 }}>
          <Button
            onPress={() => {
              navigation.navigate("MapDataSource", { datasourceID: id, templateID });
            }}
            size="small"
            testProps={{
              elementId: id,
              elementName: "actionDataSourceListViewRowItemMapButton",
              screenName: SCREEN_NAME,
            }}
            text="Map"
            type="secondary"
          />
        </View>
      ),
      size: 150,
    },
  ];

  return (
    <FullScreenModalLayout
      onClose={() => {
        if (navigation.canGoBack()) {
          navigation.goBack();

          return;
        }

        navigation.navigate("Templates", {});
      }}
      testProps={{ elementName: "configureDataSource", screenName: SCREEN_NAME }}
      title={`Configure ${templateName}`}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View style={styles.content}>
          <View style={{ width: 988 }}>
            <Heading
              level="1"
              testProps={{ elementName: "dataSourceAndMappingText", screenName: SCREEN_NAME }}
            >
              Data source and mapping
            </Heading>
            <VerticalSpacer size={theme.spacing.xxl} />
            {loading ? (
              <View style={{ justifyContent: "center", minHeight: 200 }}>
                <Spin />
              </View>
            ) : (
              <View style={{ backgroundColor: theme.colors.background.white }}>
                <Datagrid
                  columns={columns}
                  testProps={{ elementName: "dataSourceListView", screenName: SCREEN_NAME }}
                >
                  <DatagridBody
                    columns={columns}
                    data={data}
                    testProps={{ elementName: "DataSourceListView", screenName: SCREEN_NAME }}
                    testPropsElementIdKey="id"
                  />
                </Datagrid>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
      <Pagination
        disableNext={!pagination.hasNextPage}
        disablePrev={!pagination.hasPrevPage}
        onNext={nextPage}
        onPrev={prevPage}
        testProps={{ elementName: "dataSourceListView", screenName: SCREEN_NAME }}
      />
    </FullScreenModalLayout>
  );
};
