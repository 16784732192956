// Copyright 2024 Merit International Inc. All Rights Reserved

import { CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum } from "@src/gen";

type MatcherFunction = (fieldName: string, fieldSampleValue: string) => boolean;

const nameMatches = (match: RegExp | string): MatcherFunction => {
  const reg = match instanceof RegExp ? match : new RegExp(match, "iu");

  return (name: string): boolean => name.match(reg) !== null;
};

const valueMatches = (match: RegExp | string): MatcherFunction => {
  const reg = match instanceof RegExp ? match : new RegExp(match, "iu");

  return (_: string, value?: string): boolean => value?.match(reg) !== null;
};

const matchAnything = () => true;
const matchNothing = () => false;

const orMatcher = (matchers: readonly MatcherFunction[]): MatcherFunction => {
  if (matchers.length === 0) {
    return matchNothing;
  } else if (matchers.length === 1) {
    return matchers[0];
  }

  const head = matchers[0];
  const alt = orMatcher(matchers.slice(1));

  return (name, value) => head(name, value) || alt(name, value);
};

const matchers: readonly (readonly [
  MatcherFunction,
  CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum
])[] = [
  [
    orMatcher([nameMatches(/telephone/iu), nameMatches(/tel/iu), nameMatches("phone")]),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.PhoneNumber,
  ],
  [
    orMatcher([
      nameMatches(/datetime/iu),
      valueMatches(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[\+\-]\d{2}:\d{2})?$/u),
    ]),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.DateTime,
  ],
  [
    orMatcher([nameMatches(/date/iu), valueMatches(/^\d{4}-\d{2}-\d{2}$/u)]),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Date,
  ],
  [
    orMatcher([nameMatches(/number/iu), valueMatches(/(^\.?\d+$)|(^\d+\.\d+$)/u)]),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Number,
  ],
  [
    orMatcher([nameMatches("email"), valueMatches("@")]),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Email,
  ],
  [
    orMatcher([nameMatches("json"), valueMatches(/^\s*(\{.*\}|\[.*\])\s*$/u)]),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Json,
  ],
  [nameMatches("first"), CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Text],
  [nameMatches("last"), CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Text],
  [
    valueMatches("^(true)|(false)$"),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Bool,
  ],
  [
    valueMatches(/\[([^\]]+)\]\(([^)]+)\)/u),
    CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Markdown,
  ],
  [matchAnything, CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Text],
];

export const getColumnDataType = (
  fieldName: string,
  sampleValue: string
): CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum => {
  const match = matchers.find(([matcher]) => matcher(fieldName, sampleValue));

  return match?.[1] ?? CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum.Text;
};

export const firstNameMatchers = [
  "first name",
  "firstname",
  "f name",
  "first",
  "forename",
  "nombre",
  "name",
];
export const lastNameMatchers = [
  "last name",
  "lastname",
  "l name",
  "last",
  "surname",
  "family name",
];
