// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Checkbox, Heading, useTheme } from "@merit/frontend-components";
import { Errors, Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum as Predicate } from "../../../gen";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { StyleSheet, View } from "react-native";
import { Tooltip } from "../../../components";
import { getDateString, getDateTimeString } from "../../../utils/time";
import { getFieldType } from "../../../utils/getFieldType";
import { noop } from "../../../utils/noop";
import { v4 as uuidv4 } from "uuid";
import React, { useMemo } from "react";
import type {
  GetTemplateRules200ResponseRules,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule as InheritedActivityRule,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner as RuleCondition,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule as Rules,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as TemplateField,
} from "../../../gen";

const { None, Some } = Helpers;
const { UnreachableCaseError } = Errors;

type Props = {
  readonly inheritedCompletenessRules: readonly Rules[];
  readonly inheritedActivenessRules: readonly InheritedActivityRule[];
  readonly templateFields: readonly TemplateField[] | undefined;
  readonly orgRules: GetTemplateRules200ResponseRules;
};

export const InheritedRules = ({
  inheritedActivenessRules,
  inheritedCompletenessRules,
  orgRules,
  templateFields,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    fakeField: {
      backgroundColor: theme.colors.action.disabled,
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 32,
      justifyContent: "center",
      paddingHorizontal: theme.spacing.m,
    },
    fieldWrapper: {
      width: 208,
    },
    listHeader: {
      backgroundColor: theme.colors.surface.disabled,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: 10,
    },
    listItem: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    noFields: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      paddingHorizontal: 32,
      paddingVertical: 22,
    },
  });

  const getPredicateReadableName = (fieldId: string, predicateKey: string) => {
    const fieldType = getFieldType(fieldId, templateFields ?? []);

    const fieldTypeInCamelCase = fieldType.charAt(0).toLowerCase() + fieldType.slice(1);

    // Hack: We do not have a specific type for field types at the moment
    const predicates = orgRules[fieldTypeInCamelCase as keyof GetTemplateRules200ResponseRules];

    if (None(predicates)) {
      throw new Error(`Couldn't fetch the predicates with the field type: ${fieldTypeInCamelCase}`);
    }

    const predicateInCamelCase = predicateKey.charAt(0).toLowerCase() + predicateKey.slice(1);

    // Hack: We do not have a specific type for predicates at the moment
    const predicate = predicates[predicateInCamelCase as keyof typeof predicates];

    return Some(predicate) ? predicate.readable : "";
  };

  const getFieldArguments = (ruleCondition: RuleCondition) => {
    const { arguments: argValues, predicate } = ruleCondition;

    switch (predicate) {
      case "BeforeNow":
      case "AfterToday":
      case "BeforeToday":
        return getDateString(new Date());
      case "BeforeThisDate":
      case "AfterThisDate":
        return getDateString(argValues[0]);
      case "AfterThisDatetime":
      case "BeforeThisDatetime":
        return getDateTimeString(argValues[0]);
      case "AfterThisTimeOfDay":
      case "BeforeThisTimeOfDay":
        return argValues.join(":");
      case "BeforeNowMinusXDuration":
      case "BeforeTodayMinusXDays":
      case "EqualToX":
      case "FieldHasValue":
      case "IsFalse":
      case "IsTrue":
      case "IsEmailDomain":
      case "LessThanX":
      case "MatchesThisString":
      case "MoreThanX":
      case "ReceivedXContainersFromTemplates":
        return argValues[0];
      default:
        throw new UnreachableCaseError(predicate);
    }
  };

  const activenessRules = inheritedActivenessRules.map(_ => _.ruleConditions).flat();
  const [baseRules, ...completenessRules] = inheritedCompletenessRules;

  const allCompletenessRules = completenessRules.map(_ => _.ruleConditions).flat();

  const existingActivenessRules = useMemo(
    () =>
      activenessRules.map(rule => ({
        ...rule,
        required: allCompletenessRules.some(
          _ => _.target === rule.target && _.predicate === Predicate.FieldHasValue
        ),
      })),
    [activenessRules, allCompletenessRules]
  );

  const existingCompletenessRules = useMemo(
    () =>
      allCompletenessRules
        .filter(rule => !activenessRules.some(activityRule => activityRule.target === rule.target))
        .map(rule => ({
          ...rule,
          required: rule.predicate === Predicate.FieldHasValue,
        })),
    [activenessRules, allCompletenessRules]
  );

  const rules = [...existingCompletenessRules, ...existingActivenessRules];

  return (
    <>
      <View style={{ alignItems: "center", flexDirection: "row", zIndex: 1 }}>
        <Heading
          bold
          level="3"
          testProps={{
            elementName: "rulesTabRulesText",
            screenName: SCREEN_NAME,
          }}
        >
          Rules
        </Heading>
        <HorizontalSpacer size={theme.spacing.s} />
        <View style={{ maxWidth: 200 }}>
          <Tooltip text="Inherited rules cannot be modified, however, you can add new rules to the template." />
        </View>
      </View>
      <VerticalSpacer size={theme.spacing.s} />
      <Body>These are the rules specified for this template</Body>
      <VerticalSpacer size={theme.spacing.xxl} />

      <View>
        <View style={styles.listHeader}>
          <View style={styles.fieldWrapper}>
            <Body
              style={{ fontWeight: theme.fontWeights.semiBold }}
              testProps={{
                elementName: "rulesTabCompletenessRulesInheritedListItemHeaderFieldName",
                screenName: SCREEN_NAME,
              }}
            >
              Field name
            </Body>
          </View>
          <HorizontalSpacer size={theme.spacing.xxl} />
          <View style={styles.fieldWrapper}>
            <Body
              style={{ fontWeight: theme.fontWeights.semiBold }}
              testProps={{
                elementName: "rulesTabCompletenessRulesInheritedListItemHeaderRequired",
                screenName: SCREEN_NAME,
              }}
            >
              Required
            </Body>
          </View>
        </View>

        <>
          {baseRules.ruleConditions.map(rule => (
            <View key={rule.target} style={styles.listItem}>
              <View style={styles.fieldWrapper}>
                <View style={styles.fakeField}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementId: rule.target,
                      elementName: "rulesTabCompletenessRulesInheritedListItemFieldName",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {rule.targetName}
                  </Body>
                </View>
              </View>
              <HorizontalSpacer size={theme.spacing.xxl} />
              <View style={[styles.fieldWrapper, { justifyContent: "center" }]}>
                <Checkbox
                  defaultChecked={rule.predicate === Predicate.FieldHasValue}
                  disabled
                  onChange={noop}
                  size="medium"
                  testProps={{
                    elementId: rule.target,
                    elementName: "rulesTabCompletenessRulesInheritedListItemRequired",
                    screenName: SCREEN_NAME,
                  }}
                />
              </View>
            </View>
          ))}
          <VerticalSpacer size={theme.spacing.xxl} />
        </>
      </View>

      {rules.length > 0 && (
        <View>
          <View style={styles.listHeader}>
            <View style={styles.fieldWrapper}>
              <Body
                style={{ fontWeight: theme.fontWeights.semiBold }}
                testProps={{
                  elementName: "rulesTabActivityRulesInheritedListItemHeaderFieldName",
                  screenName: SCREEN_NAME,
                }}
              >
                Field name
              </Body>
            </View>
            <HorizontalSpacer size={theme.spacing.xxl} />
            <View style={styles.fieldWrapper}>
              <Body
                style={{ fontWeight: theme.fontWeights.semiBold }}
                testProps={{
                  elementName: "rulesTabActivityRulesInheritedListItemHeaderCriteria",
                  screenName: SCREEN_NAME,
                }}
              >
                Criteria
              </Body>
            </View>
            <HorizontalSpacer size={theme.spacing.xxl} />
            <View style={styles.fieldWrapper}>
              <Body
                style={{ fontWeight: theme.fontWeights.semiBold }}
                testProps={{
                  elementName: "rulesTabActivityRulesInheritedListItemHeaderArgument",
                  screenName: SCREEN_NAME,
                }}
              >
                Value
              </Body>
            </View>
            <HorizontalSpacer size={theme.spacing.xxl} />
            <View style={styles.fieldWrapper}>
              <Body
                style={{ fontWeight: theme.fontWeights.semiBold }}
                testProps={{
                  elementName: "rulesTabCompletenessRulesInheritedListItemHeaderRequired",
                  screenName: SCREEN_NAME,
                }}
              >
                Required
              </Body>
            </View>
          </View>

          <View>
            {rules.map((rule, ruleIndex) => (
              <View
                key={`rule-${uuidv4()}`}
                style={[
                  styles.listItem,
                  { flexDirection: "column", paddingVertical: theme.spacing.xxl },
                ]}
              >
                <View style={{ flexDirection: "row", zIndex: 1 }}>
                  <View style={styles.fieldWrapper}>
                    <View style={styles.fakeField}>
                      <Body
                        numberOfLines={1}
                        testProps={{
                          elementId: `${ruleIndex}rule.target`,
                          elementName: "rulesTabActivityRulesInheritedListItemFieldName",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        {rule.targetName}
                      </Body>
                    </View>
                  </View>
                  <HorizontalSpacer size={theme.spacing.xxl} />
                  <View style={styles.fieldWrapper}>
                    {rule.predicate !== Predicate.FieldHasValue && (
                      <View style={styles.fakeField}>
                        <Body
                          numberOfLines={1}
                          testProps={{
                            elementId: `${ruleIndex}rule.target`,
                            elementName: "rulesTabActivityRulesInheritedListItemCriteria",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          {getPredicateReadableName(rule.target, rule.predicate)}
                        </Body>
                      </View>
                    )}
                  </View>

                  <HorizontalSpacer size={theme.spacing.xxl} />

                  <View style={styles.fieldWrapper}>
                    {rule.predicate !== Predicate.FieldHasValue && (
                      <View style={styles.fakeField}>
                        <Body
                          numberOfLines={1}
                          testProps={{
                            elementId: `${ruleIndex}rule.target`,
                            elementName: "rulesTabActivityRulesInheritedListItemArgument",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          {getFieldArguments(rule)}
                        </Body>
                      </View>
                    )}
                  </View>

                  <HorizontalSpacer size={theme.spacing.xxl} />

                  <View style={{ justifyContent: "center", width: 180 }}>
                    <Checkbox
                      defaultChecked={rule.required}
                      disabled
                      onChange={noop}
                      size="medium"
                      testProps={{
                        elementId: `${ruleIndex}rule.target`,
                        elementName: "rulesTabActivityRulesInheritedListItemRequired",
                        screenName: SCREEN_NAME,
                      }}
                    />
                  </View>
                  <Tooltip text="Inherited fields cannot be edited." />
                </View>

                <VerticalSpacer size={theme.spacing.xxl} />

                <View style={{ width: 824 }}>
                  <Body>Error message</Body>
                  <View style={[styles.fakeField, { height: 48 }]}>
                    <Body numberOfLines={1}>{rule.errorMessage}</Body>
                  </View>
                  <VerticalSpacer size={theme.spacing.s} />
                  <Body>120 maximum character limit</Body>
                </View>
              </View>
            ))}
          </View>
        </View>
      )}

      {inheritedActivenessRules.length <= 0 && inheritedCompletenessRules.length <= 0 && (
        <>
          <View style={styles.noFields}>
            <Body
              testProps={{
                elementName: "rulesTabInheritedRulesNoDataPlaceholder",
                screenName: SCREEN_NAME,
              }}
            >
              There are no inherited rules for this template
            </Body>
          </View>
          <VerticalSpacer size={theme.spacing.xxl} />
        </>
      )}
    </>
  );
};
