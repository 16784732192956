// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Popover } from "../Popover";
import { View } from "react-native";
import React, { useState } from "react";
import type { IconName } from "@merit/frontend-components";
import type { OPTestProps } from "../../../src/types/TestProps";

const Some = Helpers.Some;

type Props = {
  readonly text: string;
  readonly testProps?: OPTestProps;
  readonly icon?: IconName;
};

export const Tooltip = ({ icon, testProps, text }: Props) => {
  const { theme } = useTheme();
  const [originElement, setOriginElement] = useState<HTMLDivElement | undefined>();
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => {
          setIsShown(true);
        }}
        onMouseLeave={() => {
          setIsShown(false);
        }}
        ref={ref => {
          if (ref !== null) {
            setOriginElement(ref);
          }
        }}
      >
        <Icon
          alt="Tooltip Information"
          name={icon ?? "informationOutlinedMediumDefault"}
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `${testProps.elementName}Icon` }
              : testProps
          }
        />
      </div>

      {isShown && originElement !== undefined && (
        <Popover originElement={originElement} placement="top-start">
          <View
            style={{
              backgroundColor: theme.colors.brand.oceanBlue,
              minWidth: 320,
              paddingHorizontal: theme.spacing.l,
              paddingVertical: theme.spacing.m,
            }}
          >
            <Body color={theme.colors.surface.default}>{text}</Body>
          </View>
        </Popover>
      )}
    </>
  );
};
