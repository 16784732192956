// Copyright 2023 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { ConfirmationModal } from "../../../components/Modals";
import { Drawer } from "../../../components/Drawer";
import { FieldKindsList } from "./FieldKindsList";
import { Footer } from "../Footer";
import { Form, Formik, type FormikProps } from "formik";
import {
  type GetFieldKind200Response,
  ResponseError,
  type GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as TemplateField,
  type GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner as TemplateFieldPermission,
} from "../../../gen";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { ScrollView, View } from "react-native";
import { Spin } from "../../../components";
import { TemplateFields } from "./TemplateFields";
import { useAlertStore } from "../../../stores";
import { useApi } from "../../../api/api";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useState } from "react";
import type { FormFields, FormValues } from "./TemplateFields";

const { None, Some } = Helpers;

type Props = {
  readonly templateFields: readonly TemplateField[];
  readonly unSaveConfirmation: () => void;
  readonly templateID: string;
  readonly getTemplatesContainer: () => void;
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
};

type FieldKinds = FormFields & {
  readonly fieldKindID: string;
};

export const Fields = ({
  formRef,
  getTemplatesContainer,
  templateFields,
  templateID,
  unSaveConfirmation,
}: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const [showFieldsListModal, setShowFieldsListModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useServerErrorHandler();
  const [fieldKindsToAddTemplate, setFieldKindsToAddTemplate] = useState<readonly FieldKinds[]>([]);

  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();

  if (None(selectedOrgId)) {
    throw new Error("Somehow org id not found");
  }

  const getPermissions = useCallback(
    (isVisible: boolean) => [
      {
        action: "read_data",
        permissibleToPermit: {
          action: "read_data",
          grantedToName: isVisible ? "All" : "None",
        },
        permitted: {
          action: "read_data",
          grantedToName: isVisible ? "All" : "None",
        },
      } as const,
    ],
    []
  );

  if (None(templateFields)) {
    return null;
  }

  const submitForm = async () => {
    if (Some(formRef.current)) {
      await formRef.current.submitForm();
    }
  };

  const createTemplateFields = async (fieldKinds: readonly FieldKinds[]) => {
    const addTemplateFields = fieldKinds.map(field => ({
      contact: field.contact,
      description: field.description,
      fieldKindID: field.fieldKindID,
      name: field.name,
      permissions: getPermissions(field.isVisible),
    }));

    try {
      setIsLoading(true);
      const requestPayload = {
        addTemplateFields,
        orgID: selectedOrgId,
        templateID,
      };
      const { fields: fieldsResponse } = await api.createTemplateFields(requestPayload);

      const fields = fieldsResponse.filter(_ => Some(_.fieldKindID));

      fields.forEach(({ error, fieldKindID }) => {
        const field = addTemplateFields.find(
          selectedField => selectedField.fieldKindID === fieldKindID
        );
        if (Some(field)) {
          if (Some(error)) {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              text: error.errors[0],
              type: "error",
            });
          } else {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              text: `Successfully added ${field.name} field`,
              type: "success",
            });
          }
        }
      });

      return fieldsResponse;
    } catch (error) {
      errorHandler(error);

      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const hasReadPermission = (fieldPermission: readonly TemplateFieldPermission[]) => {
    const perms = fieldPermission.reduce<readonly string[]>((prev, permission) => {
      if (
        permission.permitted.grantedToName === "All" &&
        permission.permitted.action === "read_data"
      ) {
        return [...prev, permission.action];
      }

      return prev;
    }, []);

    return perms.length > 0;
  };

  const editTemplateFields = async (
    formValues: readonly FormFields[],
    tempFields: readonly TemplateField[]
  ) => {
    const editedFields = formValues.filter(
      ({ description, fieldID, isVisible, name }) =>
        !tempFields.some(
          field =>
            field.name === name &&
            field.description === description &&
            field.fieldID === fieldID &&
            hasReadPermission(field.permissions ?? []) === isVisible
        )
    );

    setIsLoading(true);
    await Promise.all(
      editedFields.map(async (field, index) => {
        const payload = {
          editTemplateField: {
            contact: field.contact,
            description: field.description,
            name: field.name,
            permissions: getPermissions(field.isVisible),
          },
          orgID: selectedOrgId,
          templateFieldID: field.fieldID,
          templateID,
        };

        // delay 1 sec to prevent concurrency issue
        await new Promise(resolve => setTimeout(resolve, 1000 * index));

        try {
          await api.editTemplateField(payload);
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            text: "Template has been updated",
            type: "success",
          });
        } catch (error) {
          if (error instanceof ResponseError) {
            error.response.json().then(err => {
              setAlert({
                closable: true,
                id: uuidv4(),
                onPressDelete: id => {
                  deleteAlert(id);
                },
                text: err.errors[0],
                type: "error",
              });
            });
          }
        }
      })
    );

    setIsLoading(false);

    const templateIDsToReorder = formValues
      .filter(_ => _.fieldID !== "")
      .map(({ fieldID }) => fieldID);
    const initialTemplateIDs = tempFields.map(({ fieldID }) => fieldID);

    if (JSON.stringify(initialTemplateIDs) !== JSON.stringify(templateIDsToReorder)) {
      try {
        setIsLoading(true);
        await api.reorderTemplateFields({
          orgID: selectedOrgId,
          templateFieldReorderRequest: {
            newTemplateFieldsOrder: templateIDsToReorder,
          },
          templateID,
        });
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: "Template fields successfully reordered",
          type: "success",
        });
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }

    getTemplatesContainer();
  };

  const saveTemplate = async () => {
    if (None(formRef.current)) {
      return;
    }

    if (fieldKindsToAddTemplate.length > 0) {
      const updatedFieldKindsToAddTemplate = fieldKindsToAddTemplate.map(field => {
        const formValues = formRef.current?.values.fields.find(
          _ => _.fieldKindID === field.fieldKindID
        );

        if (Some(formValues)) {
          return {
            ...field,
            ...formValues,
          };
        }

        return field;
      });

      const newTemplateFields = await createTemplateFields(updatedFieldKindsToAddTemplate);

      if (None(newTemplateFields)) {
        return;
      }

      const fieldsWithNewTemplateFieldID = formRef.current.values.fields.map(formField => {
        const newField = newTemplateFields.find(
          ({ fieldKindID }) => formField.fieldKindID === fieldKindID
        );

        if (Some(newField)) {
          return {
            ...formField,
            fieldID: newField.templateFieldID ?? "",
          };
        }

        return formField;
      });

      try {
        const templateResponse = await api.getTemplate({ orgID: selectedOrgId, templateID });
        editTemplateFields(fieldsWithNewTemplateFieldID, templateResponse.templateFields ?? []);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      editTemplateFields(formRef.current.values.fields, templateFields);
    }
  };

  const validationSchema = yup.object({
    fields: yup.array().of(
      yup.object().shape({
        description: yup.string().optional().max(300, "300 maximum character limit"),
        name: yup
          .string()
          .trim()
          .required("Please enter field name")
          .max(90, "90 maximum character limit"),
      })
    ),
  });

  const addFieldsToTemplate = (selectedFields: readonly GetFieldKind200Response[]) => {
    const selectedFieldsWithoutDuplicates = selectedFields.filter(field => {
      const fieldKind = formRef.current?.values.fields.find(({ name }) => name === field.name);

      if (Some(fieldKind)) {
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: `A field with the name ${fieldKind.name} already exists`,
          type: "error",
        });

        return undefined;
      }

      return field;
    });

    const fieldToAdd = selectedFieldsWithoutDuplicates.map(field => ({
      contact: false,
      description: field.description ?? "",
      fieldID: uuidv4(),
      fieldKindID: field.fieldKindID,
      isInherited: false,
      isVisible: true,
      name: field.name,
      nameToDisplay: field.name,
    }));

    formRef.current?.setValues({
      fields: [...formRef.current.values.fields, ...fieldToAdd],
    });
    setFieldKindsToAddTemplate(prevState => [...prevState, ...fieldToAdd]);
  };

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ alignItems: "center", flex: 1 }}>
          <View style={{ paddingVertical: theme.spacing.xxl, width: 960 }}>
            <Formik
              enableReinitialize
              initialValues={{
                fields: templateFields.map(field => ({
                  contact: field.contact,
                  description: field.description ?? "",
                  fieldID: field.fieldID ?? "",
                  isInherited: Some(field.lineage) && field.lineage.length > 0,
                  isVisible: hasReadPermission(field.permissions ?? []),
                  name: field.name ?? "",
                  nameToDisplay: field.name ?? "",
                })),
              }}
              innerRef={formRef}
              onSubmit={() => {
                setIsConfirmationModalOpen(true);
              }}
              validationSchema={validationSchema}
            >
              {props => (
                <Form>
                  <Spin
                    spinning={isLoading}
                    {...Helpers.generateTestIdProps({
                      elementName: "configureTemplateFieldsTabLoader",
                      screenName: SCREEN_NAME,
                    })}
                  >
                    <TemplateFields
                      displayFieldsListModal={() => {
                        setShowFieldsListModal(true);
                      }}
                      formProps={props}
                    />
                  </Spin>
                </Form>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
      <Footer
        onCancel={unSaveConfirmation}
        onSave={() => {
          submitForm();
        }}
        testProps={{ elementName: "configureTemplateFieldsTab", screenName: SCREEN_NAME }}
      />

      <Drawer isOpen={showFieldsListModal}>
        <FieldKindsList
          onAdd={addFieldsToTemplate}
          onDrawerClose={() => {
            setShowFieldsListModal(false);
          }}
          templateID={templateID}
        />
      </Drawer>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            saveTemplate();
            setIsConfirmationModalOpen(false);
          }}
          testProps={{
            elementName: "configureTemplateFieldsTabSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save template"
        />
      )}
    </>
  );
};
