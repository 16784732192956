// Copyright 2023 Merit International Inc. All Rights Reserved

import { Drawer } from "../../../components/Drawer";
import { Helpers } from "@merit/frontend-utils";
import { LinkedAppDetails } from "../LinkedAppDetails/LinkedAppDetails";
import { LinkedAppsTable } from "./LinkedAppsTable";
import { Spin } from "../../../components";
import { View } from "react-native";
import { useApi } from "../../../api/api";
import { useCallback, useEffect, useState } from "react";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import type { GetAgents200ResponseAgentsInner } from "../../../gen";

const { Some } = Helpers;

export const LinkedApps = () => {
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { errorHandler } = useServerErrorHandler();

  const [linkedApps, setLinkedApps] = useState<readonly GetAgents200ResponseAgentsInner[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAppID, setSelectedAppID] = useState<string>();

  const getLinkedApps = useCallback(async () => {
    try {
      const orgLinkedAppsResponse = await api.getAgentsLinkedToEntity({
        entityID: selectedOrgId,
        orgID: selectedOrgId,
      });

      setLinkedApps(orgLinkedAppsResponse.agents ?? []);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, errorHandler, selectedOrgId]);

  useEffect(() => {
    getLinkedApps();
  }, [getLinkedApps]);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Spin />
      </View>
    );
  }

  return (
    <>
      <LinkedAppsTable
        linkedApps={linkedApps}
        onPressView={id => {
          setSelectedAppID(id);
        }}
      />

      <Drawer isOpen={Some(selectedAppID)}>
        <LinkedAppDetails
          appID={selectedAppID}
          onClose={() => {
            setSelectedAppID(undefined);
          }}
        />
      </Drawer>
    </>
  );
};
