// Copyright 2023 Merit International Inc. All Rights Reserved

import { useAppConstantsStore } from "../../stores";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { DefaultApi, ListDatasources200Response, ListDatasourcesRequest } from "../../gen";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

export const useDatasourcesListData = (api: DefaultApi, orgID: string, isFocused: boolean) => {
  const appConstants = useAppConstantsStore();
  const DEFAULT_PARAMS = {
    limit: appConstants.datasourcesLimit,
    orgID,
  } as const;

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<ListDatasources200Response>();
  const { errorHandler } = useServerErrorHandler();

  const [params, setParams] = useState<ListDatasourcesRequest>(DEFAULT_PARAMS);

  const data = response?.datasources ?? [];
  const pagination: PaginationMeta = {
    hasNextPage: response?.paginationInfo?.nextStartAfter !== undefined,
    hasPrevPage: response?.paginationInfo?.previousEndBefore !== undefined,
    nextStartAfter: response?.paginationInfo?.nextStartAfter,
    prevEndBefore: response?.paginationInfo?.previousEndBefore,
  };

  const refresh = (nextParams: Partial<ListDatasourcesRequest> = {}) => {
    if (loading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const nextPage = () => {
    if (pagination.nextStartAfter === undefined) {
      return;
    }

    refresh({
      end: undefined,
      start: pagination.nextStartAfter,
    });
  };

  const prevPage = () => {
    if (pagination.prevEndBefore === undefined) {
      return;
    }

    refresh({
      end: pagination.prevEndBefore,
      start: undefined,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        const res = await api.listDatasources(params);

        setResponse(res);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    if (isFocused) {
      fetchData();
    }
  }, [api, errorHandler, isFocused, params]);

  return {
    data,
    loading,
    nextPage,
    pagination,
    prevPage,
    refresh,
  };
};
