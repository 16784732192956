// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { StyleSheet, View } from "react-native";
import { getFieldType } from "../../utils/getFieldType";
import { getPredicateReadableName } from "../../utils/getPredicateReadableName";
import { getRuleArguments } from "../../utils/getRuleArguments";
import capitalize from "lodash/capitalize";
import type {
  GetTemplateRules200ResponseRules,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner as RuleCondition,
  GetDatasource200ResponseMappedTemplatesInner as Template,
} from "../../gen";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly templateFields: Template["templateFields"];
  readonly ruleConditions: readonly RuleCondition[];
  readonly rules: GetTemplateRules200ResponseRules;
  readonly testProps: OPTestProps;
};

// TODO will remove the list once BE returns correct predicate value for these keys
const predicatesToBeExcluded = [
  "BeforeTodayMinusXDays",
  "BeforeTodayPlusXDays",
  "BeforeNowMinusXDuration",
  "BeforeNowPlusXDuration",
  "MappableTo",
];

export const RuleList = ({ ruleConditions, rules, templateFields, testProps }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    argumentText: {
      color: theme.colors.border.success.default,
      fontWeight: theme.fontWeights.semiBold,
    },
    container: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
    },
  });

  const getPredicate = (rule: RuleCondition, index: number) => {
    const predicate = getPredicateReadableName(
      getFieldType(rule.target, templateFields ?? []),
      rule.predicate,
      rules
    );

    return Some(predicate) ? (
      <>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={{
            ...testProps,
            elementId: `${index}${rule.target}`,
            elementName: `${testProps.elementName}Predicate`,
          }}
        >
          {predicate}
        </Body>
        <HorizontalSpacer size={theme.spacing.m} />
      </>
    ) : (
      <></>
    );
  };

  const getArgument = (fieldId: string, rule: RuleCondition, index: number) => {
    const fieldType = getFieldType(fieldId, templateFields ?? []);
    const argument = getRuleArguments(fieldType, rule);

    return Some(argument) ? (
      <>
        <Body
          style={styles.argumentText}
          testProps={{
            ...testProps,
            elementId: `${index}${rule.target}`,
            elementName: `${testProps.elementName}Argument`,
          }}
        >
          {argument}
        </Body>
        <HorizontalSpacer size={theme.spacing.m} />
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      {ruleConditions
        .filter(_ => !predicatesToBeExcluded.includes(_.predicate))
        .map((rule, index) => (
          <View key={rule.target} style={styles.container}>
            <VerticalSpacer />
            <Body>
              <Body
                testProps={{
                  ...testProps,
                  elementId: `${index}${rule.target}`,
                  elementName: `${testProps.elementName}FieldName`,
                }}
              >
                If the {rule.targetName}{" "}
              </Body>
              <HorizontalSpacer size={theme.spacing.m} />
              {getPredicate(rule, index)}
              {getArgument(rule.target, rule, index)}
              <Body>then validate</Body>
            </Body>

            <VerticalSpacer />
            <Body>
              <Body color={theme.colors.text.alert.critical}>{`Error message: `}</Body>
              <Body
                testProps={{
                  ...testProps,
                  elementId: `${index}${rule.target}`,
                  elementName: `${testProps.elementName}${rule.targetName}ErrorMessage`,
                }}
              >
                {Some(rule.errorMessage) && rule.errorMessage !== ""
                  ? capitalize(rule.errorMessage)
                  : "An error has occurred"}
              </Body>
            </Body>
            <VerticalSpacer />
          </View>
        ))}
    </>
  );
};
