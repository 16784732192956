// Copyright 2024 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import type { ListTemplates200ResponseTemplatesInner } from "@src/gen";

const { Some } = Helpers;

export const getTemplateName = (
  templateID: string,
  templatesList: readonly ListTemplates200ResponseTemplatesInner[]
) => {
  const template = templatesList.find(({ id }) => id === templateID);

  return Some(template) ? template.name : undefined;
};
