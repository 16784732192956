// Copyright 2023 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { getDateString, getDateTimeString } from "./time";
import type { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner as RuleCondition } from "../gen";

const { Some } = Helpers;

export const getRuleArguments = (fieldType: string, rule: RuleCondition) => {
  if (fieldType.toLowerCase().includes("datetime")) {
    switch (rule.predicate) {
      case "AfterThisTimeOfDay":
      case "BeforeThisTimeOfDay":
        return rule.arguments.join(":");
      case "BeforeNow":
      case "BeforeNowMinusXDuration":
        return `${getDateTimeString(new Date())} minus ${rule.arguments[0]}`;
      case "AfterThisDatetime":
      case "BeforeThisDatetime":
        return getDateTimeString(rule.arguments[0]);
      case "FieldHasValue":
        return rule.arguments[0];
      default:
        throw new Error("Unknown predicate type");
    }
  }

  if (fieldType.toLowerCase().includes("date")) {
    switch (rule.predicate) {
      case "AfterToday":
      case "BeforeToday":
        return getDateString(new Date());
      case "BeforeTodayMinusXDays":
        return `${getDateString(new Date())} minus ${rule.arguments[0]}`;
      case "FieldHasValue":
        return rule.arguments[0];
      case "AfterThisDate":
      case "BeforeThisDate":
        return getDateString(rule.arguments[0]);
      default:
        throw new Error("Unknown predicate type");
    }
  }

  return Some(rule.arguments) ? rule.arguments[0] : "";
};
