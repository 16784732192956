// Copyright 2024 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { ListTemplatesTypeEnum } from "@src/gen";
import { MappingView } from "./MappingView/MappingView";
import { PreviewDataSource } from "./Datasource/PreviewDataSource";
import { Spin } from "@src/components";
import { StyleSheet, View } from "react-native";
import { UploadCSV } from "./UploadCSV";
import { getColumnDataType } from "./matcher";
import { useAlertStore } from "../../stores";
import { useCallback, useState } from "react";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFocusEffect } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import type { Column } from "./types";
import type { FormValues as DataSource } from "./Datasource/PreviewDataSource";

const { Some } = Helpers;

type Step = "mappings" | "previewDataSource" | "uploadCSV";

export const AutoMapTemplateScreen = () => {
  const { theme } = useTheme();
  const { setAlert } = useAlertStore();

  const [templateType, setTemplateType] = useState<string>(ListTemplatesTypeEnum.Merit);
  const [selectedView, setSelectedView] = useState<Step>("uploadCSV");
  const [columns, setColumns] = useState<readonly Column[]>([]);
  const [fileName, setFileName] = useState("");
  const [dataSource, setDataSource] = useState<DataSource>();

  const { DefaultLayout } = useFlaggedLayout();

  const styles = StyleSheet.create({
    wrapper: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  const getColumnDataTypeAndFormat = (key: string, csvData: readonly unknown[]) => {
    const firstRow = csvData[0] as Record<string, string>;
    const suggestedType = getColumnDataType(key, firstRow[key]);

    switch (suggestedType) {
      case "Date":
        return {
          columnDataType: suggestedType,
          format: "YYYY-MM-DD",
        };
      case "DateTime":
        return {
          columnDataType: suggestedType,
          format: "YYYY-MM-DD HH:mm:ss",
        };
      case "PhoneNumber":
        return {
          columnDataType: suggestedType,
          format: "US",
        };

      default:
        return { columnDataType: suggestedType };
    }
  };

  const validateCSVAndPreviewDataSource = (
    data: readonly unknown[],
    name: string,
    template: string
  ) => {
    setTemplateType(template);
    if (data.length === 0) {
      setAlert({
        id: uuidv4(),
        onPressDelete: () => undefined,
        text: "There is no data in the file.",
        title: "Failed",
        type: "error",
      });

      return;
    }

    const headers = Object.keys(data[0] as object);

    setColumns(
      headers
        .filter(key => Some(key) && key.trim().length > 0)
        .map(key => ({
          ...getColumnDataTypeAndFormat(key, data),
          columnName: key,
          id: uuidv4(),
          isIdentifier: key === "Email",
          selected: true,
          usedAsDeletion: false,
        }))
    );
    setFileName(name.slice(0, name.indexOf(".")));
    setSelectedView("previewDataSource");
  };

  const resetData = useCallback(() => {
    setDataSource(undefined);
    setColumns([]);
    setSelectedView("uploadCSV");
  }, []);

  useFocusEffect(
    useCallback(
      () => () => {
        resetData();
      },
      [resetData]
    )
  );

  const handleView = () => {
    switch (selectedView) {
      case "uploadCSV":
        return <UploadCSV onNext={validateCSVAndPreviewDataSource} />;
      case "previewDataSource":
        return (
          <PreviewDataSource
            columns={Some(dataSource) ? dataSource.schemaColumns : columns}
            fileName={fileName}
            onBack={() => {
              resetData();
            }}
            onNext={datasourceDetails => {
              setDataSource(datasourceDetails);
              setSelectedView("mappings");
            }}
          />
        );
      case "mappings":
        return Some(dataSource) ? (
          <MappingView
            dataSource={{
              ...dataSource,
              schemaColumns: dataSource.schemaColumns.filter(column => column.selected),
            }}
            onBack={() => {
              setSelectedView("previewDataSource");
            }}
            templateType={templateType}
          />
        ) : (
          <Spin />
        );

      default:
        throw new Error("step  not found");
    }
  };

  return (
    <DefaultLayout
      breadcrumbs={[{ name: "Create & Configure" }, { name: "Auto Mapping" }]}
      title="Auto Mapping"
    >
      <View style={styles.wrapper}>{handleView()}</View>
    </DefaultLayout>
  );
};
