/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
 */
export interface GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    arguments: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    errorMessage: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    predicate: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    target: string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    targetName: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    isInvalid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner
     */
    invalidError?: string;
}


/**
 * @export
 */
export const GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum = {
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToX: 'EqualToX',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates'
} as const;
export type GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum = typeof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum[keyof typeof GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum];


/**
 * Check if a given object implements the GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner interface.
 */
export function instanceOfGetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "arguments" in value;
    isInstance = isInstance && "errorMessage" in value;
    isInstance = isInstance && "predicate" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "targetName" in value;

    return isInstance;
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerFromJSON(json: any): GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner {
    return GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerFromJSONTyped(json, false);
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arguments': json['arguments'],
        'errorMessage': json['errorMessage'],
        'predicate': json['predicate'],
        'target': json['target'],
        'targetName': json['targetName'],
        'isInvalid': !exists(json, 'isInvalid') ? undefined : json['isInvalid'],
        'invalidError': !exists(json, 'invalidError') ? undefined : json['invalidError'],
    };
}

export function GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerToJSON(value?: GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arguments': value.arguments,
        'errorMessage': value.errorMessage,
        'predicate': value.predicate,
        'target': value.target,
        'targetName': value.targetName,
        'isInvalid': value.isInvalid,
        'invalidError': value.invalidError,
    };
}

