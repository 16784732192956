// Copyright 2023 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  GetContainersRequest,
  OrgsGet200Response,
  OrgsGet200ResponseContainersInner,
} from "../../gen";

const { None } = Helpers;

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
};

export type ContainerCache = readonly OrgsGet200Response[];

export const useApprovalsData = (api: DefaultApi, body: GetContainersRequest) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<GetContainersRequest>(body);
  const { errorHandler } = useServerErrorHandler();

  // To paginate backwards when sorting in descending order, we need data about the previous page
  // which is not available from the API. This hook caches all of the response data so that we can
  // get around this restriction, with the added bonus of slightly improved performance.  It expects
  // to have refresh() called whenever a destructive action is taken.
  const [cache, setCache] = useState<ContainerCache>([]);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<readonly OrgsGet200ResponseContainersInner[]>();
  const [pagination, setPagination] = useState<PaginationMeta>({
    hasNextPage: false,
    hasPrevPage: false,
  });

  const resetData = () => {
    setData(undefined);
    setCache([]);
    setPagination({
      hasNextPage: false,
      hasPrevPage: false,
    });
    setPage(0);
  };

  useEffect(() => {
    async function fetchData(fetchParams: GetContainersRequest) {
      try {
        if (fetchParams.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setIsLoading(true);

        const res = await api.getContainers(fetchParams);
        setCache(prevCache => [...prevCache, res]);
        setData(res.containers);
        setPagination({
          hasNextPage: res.paginationInfo?.hasMore ?? false,
          hasPrevPage: page > 0,
        });
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }

    const pageInCache = cache.length > page;
    if (pageInCache) {
      setData(cache[page].containers);

      return;
    }

    if (isLoading) {
      return;
    }

    if (cache.length > 0) {
      fetchData({
        ...params,
        end: cache[page - 1].paginationInfo?.previousEndBefore,
      });
    } else {
      fetchData(params);
    }
  }, [api, errorHandler, params, cache, page, isLoading]);

  const refresh = (nextParams: Partial<GetContainersRequest> = {}) => {
    if (isLoading) {
      return;
    }

    setParams(oldParams => {
      if (
        nextParams.templateType !== undefined &&
        params.templateType !== nextParams.templateType
      ) {
        resetData();
      }

      return {
        ...oldParams,
        ...nextParams,
      };
    });
  };

  const nextPage = () => {
    if (None(cache[page])) {
      return;
    }

    setPage(prev => {
      const newPage = prev + 1;
      const movingToCachedPage = cache.length > newPage;

      setPagination({
        hasNextPage: movingToCachedPage ? cache[newPage].paginationInfo?.hasMore ?? false : true,
        hasPrevPage: true,
      });

      return newPage;
    });
  };

  const prevPage = () => {
    setPage(prev => {
      const newPage = prev - 1;

      setData(cache[newPage].containers);
      setPagination({
        hasNextPage: true,
        hasPrevPage: newPage > 0,
      });

      return newPage;
    });
  };

  return {
    data,
    isLoading,
    nextPage,
    pagination,
    prevPage,
    refresh,
  };
};
