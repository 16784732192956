// Copyright 2023 Merit International Inc. All Rights Reserved
/* eslint-disable no-console */

import { useBaseFieldIdStore } from "../stores";
import type { GetContainersTemplateTypeEnum, OrgsGet200ResponseContainersInner } from "../gen";

/*
  Infers the templateType of a container
  This will be deprecated once we have templateType included from the platform object
  Default case is Merit if we fail to infer
*/
export const useInferContainerTemplateType = () => {
  const { fieldIds } = useBaseFieldIdStore();

  const inferContainerTemplateType = (
    container: OrgsGet200ResponseContainersInner
  ): GetContainersTemplateTypeEnum => {
    if (container.recipient !== null && container.recipient !== undefined) {
      if (container.recipient.type === "organization") {
        return "Folio";
      }

      return "Merit";
    }

    if (container.fields === undefined) {
      console.error(`Found container with no fields ${container.id}`);

      return "Merit";
    }

    // Much easier to read this as a simple for loop
    // eslint-disable-next-line functional/no-loop-statement
    for (const field of container.fields) {
      switch (field.baseTemplateFieldID) {
        case undefined:
          return "Merit";
        case fieldIds?.folio.organizationName:
          return "Folio";
        case fieldIds?.merit.email:
        case fieldIds?.merit.email:
        case fieldIds?.merit.email:
          return "Merit";
        default:
          return "Merit";
      }
    }

    return "Merit";
  };

  return { inferContainerTemplateType };
};
