// Copyright 2023 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Body, Heading, Select, TextInput, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { ErrorMessage, Formik } from "formik";
import { Footer } from "./Footer";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "./ConfigureTemplate";
import { VerticalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import React, { useState } from "react";
import type { FormValues } from "./ConfigureTemplate";
import type { FormikProps } from "formik";
import type { GetDatasource200ResponseMappedTemplatesInnerTypeEnum as TemplateType } from "../../gen";

const { Some } = Helpers;

type Props = {
  readonly onFormSubmit: (values: FormValues) => void;
  readonly templateDetails: {
    readonly description: string | undefined;
    readonly name: string;
    readonly templateType: TemplateType;
  };
  readonly unSaveConfirmation: () => void;
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
};

const templateTypeOptions = [
  { label: "Merit", value: 1 },
  { label: "Folio", value: 2 },
];

export const Name = ({
  formRef,
  onFormSubmit,
  templateDetails: { description, name, templateType },
  unSaveConfirmation,
}: Props) => {
  const { theme } = useTheme();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    description: Yup.string().optional().trim().max(300, "300 maximum character limit"),
    name: Yup.string()
      .trim()
      .required("Template Name is required")
      .max(60, "60 maximum character limit"),
    templateType: Yup.string().required("Please select a Template Type"),
  });

  const errorText = (error: string, fieldName: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      <Body
        color={theme.colors.text.alert.critical}
        testProps={{
          elementName: `template${fieldName}FieldError`,
          screenName: SCREEN_NAME,
        }}
      >
        {error}
      </Body>
    </View>
  );

  const initialValues = {
    description,
    name,
    templateType,
  };

  return (
    <>
      <View style={{ alignItems: "center", flex: 1, paddingVertical: theme.spacing.xxl }}>
        <View style={{ minWidth: 960 }}>
          <Heading
            bold
            level="3"
            testProps={{
              elementName: "configureTemplateNameTabTemplateNameAndTypeText",
              screenName: SCREEN_NAME,
            }}
          >
            Template name and type
          </Heading>
          <VerticalSpacer size={theme.spacing.xxl} />

          <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formRef}
            onSubmit={(values: FormValues) => {
              onFormSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {({ handleBlur, handleChange, setFieldValue, values }) => (
              <>
                <View style={{ maxWidth: 468 }}>
                  <TextInput
                    label="Name *"
                    onBlur={handleBlur("name")}
                    onChangeText={handleChange("name")}
                    placeholder="Name"
                    size="large"
                    testProps={{
                      elementName: "configureTemplateNameTabNameTextInput",
                      screenName: SCREEN_NAME,
                    }}
                    value={values.name}
                  />
                </View>
                <ErrorMessage name="name">{error => errorText(error, "Name")}</ErrorMessage>

                <View
                  style={{
                    paddingTop: theme.spacing.xxl,
                  }}
                >
                  <View style={{ minWidth: 960 }}>
                    <TextInput
                      label="Description"
                      numberOfLines={4}
                      onBlur={handleBlur("description")}
                      onChangeText={handleChange("description")}
                      placeholder="Description"
                      size="large"
                      testProps={{
                        elementName: "configureTemplateNameTabDescriptionTextInput",
                        screenName: SCREEN_NAME,
                      }}
                      value={values.description ?? ""}
                    />
                    <VerticalSpacer size={theme.spacing.s} />
                    <Heading level="6">300 maximum character limit</Heading>
                    <ErrorMessage name="description">
                      {error => errorText(error, "Description")}
                    </ErrorMessage>
                  </View>
                </View>

                <View style={{ maxWidth: 468, paddingTop: theme.spacing.xxl }}>
                  <Select
                    defaultValue={templateTypeOptions.find(_ => _.label === templateType)}
                    disabled
                    label="Type *"
                    onSelectOption={option => {
                      setFieldValue("templateType", option.value);
                    }}
                    options={templateTypeOptions}
                    size="large"
                    testProps={{
                      elementName: "configureTemplateNameTabType",
                      screenName: SCREEN_NAME,
                    }}
                    usePortal
                  />
                  <ErrorMessage name="templateType">
                    {error => errorText(error, "Type")}
                  </ErrorMessage>
                </View>
              </>
            )}
          </Formik>
        </View>
      </View>
      <Footer
        onCancel={unSaveConfirmation}
        onSave={() => {
          if (Some(formRef.current) && formRef.current.isValid) {
            setIsConfirmationModalOpen(true);
          }
        }}
        testProps={{ elementName: "configureTemplateNameTab", screenName: SCREEN_NAME }}
      />
      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="save"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            formRef.current?.submitForm();
            setIsConfirmationModalOpen(false);
          }}
          testProps={{
            elementName: "configureTemplateNameTabSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save template"
        />
      )}
    </>
  );
};
