import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import { ViewPermissions } from "../../components/ViewPermissions";
import { getDateTimeString } from "../../utils/time";
import React from "react";
import type { GetFieldKind200Response } from "@src/gen/models/GetFieldKind200Response";
import type { OPTestProps } from "../../../src/types/TestProps";

type Props = {
  readonly fieldKindData: GetFieldKind200Response | undefined;
  readonly testProps?: OPTestProps;
};

const { None, Some } = Helpers;

export const Overview = ({ fieldKindData, testProps }: Props) => {
  const { theme } = useTheme();

  if (None(fieldKindData)) {
    throw new Error("couldn't find fieldKindData");
  }

  return (
    <View style={{ paddingHorizontal: 32 }}>
      <>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `DescriptionLabel${testProps.elementName}` }
              : testProps
          }
        >
          Description
        </Body>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `Description${testProps.elementName}` }
              : testProps
          }
        >
          {Some(fieldKindData.description) && fieldKindData.description !== ""
            ? fieldKindData.description
            : "--"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `DataTypeLabel${testProps.elementName}` }
              : testProps
          }
        >
          Data type
        </Body>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `DataType${testProps.elementName}` }
              : testProps
          }
        >
          {Some(fieldKindData.dataType) ? fieldKindData.dataType : "--"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `OwnerLabel${testProps.elementName}` }
              : testProps
          }
        >
          Owner
        </Body>
        <VerticalSpacer size={theme.spacing.xs} />
        <View
          style={{ flexDirection: "row" }}
          {...Helpers.generateTestIdProps(testProps, { componentName: "Owner" })}
        >
          <Body>
            {Some(fieldKindData.ownerEntityName) && fieldKindData.ownerEntityName !== ""
              ? fieldKindData.ownerEntityName
              : "--"}
          </Body>
          <HorizontalSpacer size={10} />
          <Body color={theme.colors.text.subdued}>|</Body>
          <HorizontalSpacer size={10} />
          <Body>{Some(fieldKindData.ownerEntityID) ? fieldKindData.ownerEntityID : "--"}</Body>
        </View>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Body
          style={{ fontWeight: theme.fontWeights.semiBold }}
          testProps={
            Some(testProps)
              ? { ...testProps, elementName: `CreatedByLabel${testProps.elementName}` }
              : testProps
          }
        >
          Created by
        </Body>
        <VerticalSpacer size={theme.spacing.xs} />
        <View
          style={{ flexDirection: "row" }}
          {...Helpers.generateTestIdProps(testProps, { componentName: "CreatedBy" })}
        >
          <Body>
            {Some(fieldKindData.createdBy) && fieldKindData.createdBy !== ""
              ? fieldKindData.createdBy
              : "--"}
          </Body>
          <HorizontalSpacer size={10} />
          <Body color={theme.colors.text.subdued}>|</Body>
          <HorizontalSpacer size={10} />
          <Body>{Some(fieldKindData) ? getDateTimeString(fieldKindData.createdAt) : "--"}</Body>
        </View>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />
      <ViewPermissions
        permissions={fieldKindData.permissions}
        testProps={{
          elementId: fieldKindData.fieldKindID,
          elementName: "OverviewTab",
          screenName: "FieldDetails",
        }}
      />
    </View>
  );
};
