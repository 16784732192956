// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading } from "@merit/frontend-components";
import { Image, View } from "react-native";
import { Images } from "../../utils/Image";
import { PreLoginLayout } from "../../layouts/PreLoginLayout";
import { VerticalSpacer } from "../../components/Spacer";
import { useAuthStore } from "../../stores";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PostLoginRouteParams } from "../../Router";

type Navigation = NativeStackNavigationProp<PostLoginRouteParams, "OrgRegistrationSuccess">;

export const OrgRegisterSuccess = () => {
  const navigation = useNavigation<Navigation>();
  const { setSelectedOrgId, setSelectedOrgName } = useAuthStore();

  return (
    <PreLoginLayout>
      <View style={{ alignItems: "center" }}>
        <VerticalSpacer size={40} />
        <Image
          accessibilityLabel="document-with-search"
          source={Images.partyPopper}
          style={{ height: 120, width: 120 }}
        />
        <VerticalSpacer size={40} />
        <View style={{ alignItems: "flex-start", width: 420 }}>
          <Heading level="1">Your registration has been submitted</Heading>
          <VerticalSpacer />
          <Body size="l">
            Your organization registration will be reviewed within the next 72 hours. Please be on
            the lookout for an email from our registration verification team. Once verified, you
            will be able to login to your org.
          </Body>
          <VerticalSpacer size={28} />
          <View style={{ flexDirection: "row", justifyContent: "space-between", width: 420 }}>
            <Button
              onPress={() => {
                navigation.navigate("OrgRegistration");
              }}
              size="small"
              text="Register another org"
            />
            <Button
              onPress={() => {
                setSelectedOrgId(null);
                setSelectedOrgName(null);
                navigation.navigate("OrgSelect");
              }}
              size="small"
              text="Back to Org Select"
              type="secondary"
            />
          </View>
        </View>
      </View>
    </PreLoginLayout>
  );
};
