// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { FieldFormComponent } from "./FieldFormComponent";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { Spin } from "../../components";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type {
  ExtendFieldKindOperationRequest as ExtendFieldKindRequest,
  GetFieldKind200Response as FieldKind,
  GetTemplateRules200ResponseRules as PredicateMap,
} from "../../gen";
import type { FormValues, Option } from "./FieldFormComponent";
import type { FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

const { None, Some } = Helpers;
const screenName = "ExtendField";
export const NULL_UUID = "00000000-0000-0000-0000-000000000000";

export const ExtendFieldScreen = () => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { errorHandler } = useServerErrorHandler();
  const { name, params } = useRoute<RouteProp<RouteParams, "CreateField" | "ExtendField">>();
  const { deleteAlert, setAlert } = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [parentFieldKind, setParentFieldKind] = useState<FieldKind>();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [baseFieldKinds, setBaseFieldKinds] = useState<readonly Option[]>([]);
  const [predicates, setPredicates] = useState<PredicateMap | undefined>(undefined);
  const [predicatesIsLoading, setPredicatesIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { configuration } = useLoadedConfigurationState();

  useEffect(() => {
    const fetchBaseFieldKinds = async () => {
      try {
        setIsLoading(true);
        const res = await api.getFieldKinds({
          limit: 100,
          orgID: selectedOrgId,
          ownerID: configuration.solUUID,
          parentID: NULL_UUID,
        });

        setBaseFieldKinds(
          res.fieldKinds.map(fk => ({ label: fk.dataType, value: fk.fieldKindID }))
        );
        setIsReady(true);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (None(params) || None(params.id)) {
      fetchBaseFieldKinds();
    }
  }, [api, configuration.solUUID, errorHandler, params, selectedOrgId]);

  useEffect(() => {
    const fetchParentFieldKind = async (fieldKindID: string, orgID: string) => {
      try {
        setIsLoading(true);
        const response = await api.getFieldKind({
          fieldKindID,
          orgID,
        });

        setParentFieldKind(response);
        setIsReady(true);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (Some(params) && Some(params.id)) {
      fetchParentFieldKind(params.id, selectedOrgId);
    }
  }, [api, selectedOrgId, params, errorHandler]);

  useEffect(() => {
    const fetchPredicates = async () => {
      try {
        setPredicatesIsLoading(true);
        const res = await api.getTemplateRules({
          orgID: selectedOrgId,
        });
        setPredicates(res.rules);
      } finally {
        setPredicatesIsLoading(false);
      }
    };
    if (!predicatesIsLoading && None(predicates)) {
      fetchPredicates();
    }
  }, [api, predicates, predicatesIsLoading, selectedOrgId]);

  const extendField = useCallback(
    async (field: ExtendFieldKindRequest) => {
      setIsLoading(true);
      try {
        await api.extendFieldKind(field);
        setAlert({
          customContent: <Body>New field has been added</Body>,
          id: uuidv4(),
          onPressDelete(id: string): void {
            deleteAlert(id);
          },
          testProps: {
            elementName: "createFieldSuccess",
            screenName,
          },
          type: "success",
        });
        navigation.navigate("Fields");
      } catch (error) {
        errorHandler(error, {
          elementName: "createFieldError",
          screenName,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [api, setAlert, navigation, deleteAlert, errorHandler]
  );

  const closeScreen = () => {
    if (Some(formRef.current) && formRef.current.dirty) {
      setShowConfirmationModal(true);

      return;
    }

    setIsReady(false);
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Fields");
  };

  return (
    <>
      <FullScreenModalLayout
        onClose={closeScreen}
        testProps={{ elementName: "FullScreenModalLayout", screenName }}
        title={
          name === "ExtendField" ? `Extend ${parentFieldKind?.name ?? "field"}` : "Create field"
        }
      >
        <Spin spinning={isLoading}>
          {Some(predicates) && isReady && (
            <FieldFormComponent
              baseFieldKinds={baseFieldKinds}
              formRef={formRef}
              onFormSubmit={extendField}
              onPressCancel={closeScreen}
              parentFieldKind={parentFieldKind}
              predicates={predicates}
              selectedOrgId={selectedOrgId}
              type="extend"
            />
          )}
        </Spin>
      </FullScreenModalLayout>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onOk={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();

              return;
            }

            navigation.navigate("Fields");
            setShowConfirmationModal(false);
          }}
          testProps={{ elementName: "UnsavedChangesModal", screenName }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
