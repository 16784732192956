// Copyright 2023 Merit International Inc. All Rights Reserved

import { DetailsDrawer } from "../../../layouts/DetailsDrawer";
import { Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../../components/Spacer";
import { Overview } from "./Overview";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin, Tabs } from "../../../components";
import { useApi } from "../../../api/api";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { GetAgents200ResponseAgentsInner } from "../../../gen";
type TabKey = "overview";

export const SCREEN_NAME = "LinkedAppDetails";

const { None } = Helpers;

type Props = {
  readonly appID: string | undefined;
  readonly onClose: () => void;
};

export const LinkedAppDetails = ({ appID, onClose }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const [activeTab, setActiveTab] = useState<TabKey>("overview");
  const [appDetails, setAppDetails] = useState<GetAgents200ResponseAgentsInner>();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    divider: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingHorizontal: 32,
    },
  });

  useEffect(() => {
    const fetchAppDetails = async () => {
      if (None(appID)) {
        return;
      }

      setIsLoading(true);
      try {
        const response = await api.getAgent({ agentID: appID, orgID: selectedOrgId });
        setAppDetails(response);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppDetails();
  }, [api, appID, errorHandler, selectedOrgId]);

  if (isLoading) {
    return (
      <View
        style={{
          alignItems: "center",
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  if (None(appDetails)) {
    return null;
  }

  const tabs: readonly { readonly key: TabKey; readonly label: string }[] = [
    {
      key: "overview",
      label: "Overview",
    },
  ];

  return (
    <>
      <DetailsDrawer
        actionButtons={<></>}
        onPressClose={() => {
          setActiveTab(tabs[0].key);
          onClose();
        }}
        subTitle={
          <View style={{ flexDirection: "row" }}>
            <Heading
              level="6"
              testProps={{
                elementId: appDetails.id,
                elementName: "appDetailsViewAppID",
                screenName: SCREEN_NAME,
              }}
            >
              App ID: {appDetails.id}
            </Heading>
            <HorizontalSpacer size={24} />

            <Heading color={theme.colors.border.subdued} level="6">
              |
            </Heading>
            <HorizontalSpacer size={24} />
            <Heading
              capitalize
              level="6"
              testProps={{
                elementId: appDetails.id,
                elementName: "appDetailsViewStatus",
                screenName: SCREEN_NAME,
              }}
            >
              Status: {appDetails.status ?? "--"}
            </Heading>
            <HorizontalSpacer size={24} />

            <Heading color={theme.colors.border.subdued} level="6">
              |
            </Heading>
            <HorizontalSpacer size={24} />
            <Heading
              capitalize
              level="6"
              testProps={{
                elementId: appDetails.id,
                elementName: "appDetailsViewState",
                screenName: SCREEN_NAME,
              }}
            >
              State: {appDetails.state ?? "--"}
            </Heading>
          </View>
        }
        testProps={{
          elementId: appDetails.id,
          elementName: "appDetailsView",
          screenName: SCREEN_NAME,
        }}
        title={appDetails.name}
      >
        <View style={styles.divider}>
          <Tabs
            items={tabs}
            onChange={event => {
              setActiveTab(event);
            }}
            selected={activeTab}
            testProps={{
              elementId: appDetails.id,
              elementName: "appDetailsView",
              screenName: SCREEN_NAME,
            }}
          />
        </View>
        <ScrollView>
          <Overview appDetails={appDetails} />
        </ScrollView>
      </DetailsDrawer>
    </>
  );
};
