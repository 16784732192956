// Copyright 2023 Merit International Inc. All Rights Reserved

import { Datagrid, DatagridBody } from "../../components/Datagrid";
import { EllipsisText } from "../../components/EllipsisText";
import { ScrollView } from "react-native";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { OrgsGet200ResponseContainersInnerFieldsInner as Field } from "../../gen";

type Props = {
  readonly fields: readonly Field[];
};

const SCREEN_NAME = "connectedOrganizationDetails";

export const Fields = ({ fields }: Props) => {
  const columns: readonly DatagridColumn<Field>[] = [
    {
      key: "value",
      label: "Value",
      size: "flex",
    },
    {
      key: "name",
      label: "Field name",
      size: "flex",
    },
    {
      key: "description",
      label: "Field description",
      size: "flex",
    },
    {
      key: "",
      label: "Data type",
      renderer: (item, testProps) => (
        <EllipsisText testProps={testProps} text={item.fieldKind?.fieldType ?? "--"} />
      ),
      size: "flex",
    },
  ];

  return (
    <ScrollView>
      <Datagrid
        columns={columns}
        testProps={{
          elementName: "connectedOrganizationDetailsViewFieldsTabFieldsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={fields}
          testProps={{
            elementName: "connectedOrganizationDetailsViewFieldsTabFieldsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="templateFieldID"
        />
      </Datagrid>
    </ScrollView>
  );
};
