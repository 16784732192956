// Copyright 2023 Merit International Inc. All Rights Reserved

import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useState } from "react";
import type { DefaultApi } from "../../gen";

export const useDatasourceUpload = (api: DefaultApi, orgID: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { errorHandler } = useServerErrorHandler();
  const { profile } = useLoggedInAuthState();

  const upload = async (
    fileName: string,
    fileUri: string,
    datasourceID: string
  ): Promise<string> => {
    try {
      setLoading(true);

      const req = {
        datasourceID,
        orgID,
      };
      const uploadLinkResp = await api.getDatasourceUploadLink(req);

      await fetch(uploadLinkResp.link, {
        body: fileUri,
        headers: {
          "Content-Type": "text/csv",
        },
        method: "PUT",
      });

      const logReq = {
        adminEmail: profile.name,
        correlationID: uploadLinkResp.correlationID,
        datasourceID,
        fileName,
        fileUrl: uploadLinkResp.link,
        orgID,
      };

      await api.ingestDatasourceLog(logReq);

      return uploadLinkResp.correlationID;
    } catch (error) {
      errorHandler(error);

      return "";
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    upload,
  };
};
