// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { FailuresList } from "./FailuresList";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin } from "../../components/Spin";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type {
  OrgsGet200ResponseContainersInnerCompletenessFailuresInner as FailureRules,
  GetTemplateRules200ResponseRules,
  OrgsGet200ResponseContainersInnerFieldsInner,
} from "../../gen";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly activenessFailures: readonly FailureRules[] | undefined;
  readonly completenessFailures: readonly FailureRules[] | undefined;
  readonly templateFields: readonly OrgsGet200ResponseContainersInnerFieldsInner[];
  readonly fieldValidationErrors: readonly FailureRules[] | undefined;
  readonly closeDrawer: () => void;
  readonly testProps: OPTestProps;
};

export const Failures = ({
  activenessFailures,
  closeDrawer,
  completenessFailures,
  fieldValidationErrors,
  templateFields,
  testProps,
}: Props) => {
  const { theme } = useTheme();
  const [rules, setRules] = useState<GetTemplateRules200ResponseRules>();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    loadingContainer: {
      flex: 1,
      justifyContent: "center",
    },
    noFailures: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingVertical: theme.spacing.l,
    },
  });

  useEffect(() => {
    const getRules = async () => {
      try {
        setIsLoading(true);
        const response = await api.getTemplateRules({ orgID: selectedOrgId });
        setRules(response.rules);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    getRules();
  }, [api, deleteAlert, errorHandler, selectedOrgId, setAlert]);

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Spin />
      </View>
    );
  }

  return (
    <View style={{ flex: 1, paddingHorizontal: 32 }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <VerticalSpacer size={theme.spacing.xxl} />
        <>
          <Heading
            level="4"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}CompletenessFailureText`,
            }}
          >
            Completeness failures
          </Heading>
          {Some(completenessFailures) && completenessFailures.length > 0 && Some(rules) ? (
            <FailuresList
              failureRules={completenessFailures}
              predicateRules={rules}
              templateFields={templateFields}
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}CompletenessFailure`,
              }}
            />
          ) : (
            <View style={styles.noFailures}>
              <Body
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}CompletenessFailureNoDataPlaceholder`,
                }}
              >
                There are no completeness failures
              </Body>
            </View>
          )}
          <VerticalSpacer />
        </>

        <>
          <Heading
            level="4"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}ActivenessFailureText`,
            }}
          >
            Activeness failures
          </Heading>
          {Some(activenessFailures) && activenessFailures.length > 0 && Some(rules) ? (
            <FailuresList
              failureRules={activenessFailures}
              predicateRules={rules}
              templateFields={templateFields}
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}ActivenessFailure`,
              }}
            />
          ) : (
            <View style={styles.noFailures}>
              <Body
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}ActivenessFailureNoDataPlaceholder`,
                }}
              >
                There are no activeness failures
              </Body>
            </View>
          )}
          <VerticalSpacer />
        </>

        <>
          <Heading
            level="4"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}FieldValidationFailureText`,
            }}
          >
            Field validation failures
          </Heading>
          {Some(fieldValidationErrors) && fieldValidationErrors.length > 0 && Some(rules) ? (
            <FailuresList
              closeDrawer={closeDrawer}
              failureRules={fieldValidationErrors}
              predicateRules={rules}
              rulesType="field"
              templateFields={templateFields}
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}FieldValidationFailure`,
              }}
            />
          ) : (
            <View style={styles.noFailures}>
              <Body
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}FieldValidationFailureNoDataPlaceholder`,
                }}
              >
                There are no field validation failures
              </Body>
            </View>
          )}
          <VerticalSpacer />
        </>
      </ScrollView>
    </View>
  );
};
