// Copyright 2023 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { useAlertStore } from "../../stores/alertStore";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import type { CreateDatasourceRequest, DefaultApi, UpdateDatasourceRequest } from "../../gen";

const dataSourceSchema = (
  method: "CREATE" | "UPDATE",
  type?: CreateDatasourceRequest["integrationType"]
) => {
  const name = yup.string().required().max(60).label("Datasource Name");
  const description = yup.string().max(300).label("Datasource Description");
  const schemaColumns = yup
    .array()
    .of(
      /* eslint-disable sort-keys-fix/sort-keys-fix */
      yup.object({
        id: yup.number(),
        columnName: yup.string().label("Column Name").required(),
        columnDataType: yup.string().label("Column Data Type").required(),
        columnDateFormat: yup
          .string()
          .label("Column Format")
          .when("columnDataType", {
            is: (value: string) => value.startsWith("Date"),
            then: schema => schema.required(),
          }),
        isIdentifier: yup
          .bool()
          .when("$identifierCount", {
            is: (value: number) => value === 0,
            // eslint-disable-next-line no-template-curly-in-string
            then: schema => schema.oneOf([true], "${label} must be checked"),
          })
          .label("Unique Identifier"),
      })
      /* eslint-enable sort-keys-fix/sort-keys-fix */
    )
    .label("Datasource schema");
  const schemaDelimiter = yup
    .string()
    .required()
    .matches(/(comma|tab|pipe)/u);

  if (method === "UPDATE") {
    return yup.object({
      description,
      name,
      schemaColumns,
    });
  }

  switch (type) {
    case "CSV":
      return yup.object({
        description,
        name,
        schemaColumns: schemaColumns.min(1),
        schemaDelimiter,
      });

    default:
      return yup.object({
        description,
        name,
      });
  }
};

export const useDatasourcesMutation = (api: DefaultApi, orgID: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [validationError, setValidationError] = useState<yup.ValidationError>();
  const { errorHandler } = useServerErrorHandler();
  const alert = useAlertStore();

  const create = async (payload: CreateDatasourceRequest, onSuccess: () => void) => {
    try {
      setLoading(true);
      setError(undefined);
      setValidationError(undefined);

      await dataSourceSchema("CREATE", payload.integrationType).validate(payload, {
        abortEarly: false,
        context: {
          identifierCount: payload.schemaColumns.filter(column => column.isIdentifier).length,
        },
      });

      await api.createDatasource({
        datasource: payload,
        orgID,
      });
      alert.setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          alert.deleteAlert(id);
        },
        size: "medium",
        text: "Datasource is successfully created",
        type: "success",
      });
      onSuccess();
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidationError(err);
        throw new Error(err.errors[0]);
      }
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const checkForErrors = async (payload: UpdateDatasourceRequest) => {
    try {
      setValidationError(undefined);
      await dataSourceSchema("UPDATE").validate(payload);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidationError(err);
        throw new Error(err.errors[0]);
      }
    }
  };

  const update = async (id: string, payload: UpdateDatasourceRequest, onSuccess: () => void) => {
    try {
      setLoading(true);
      setError(undefined);

      await api.updateDatasource({
        datasource: payload,
        datasourceID: id,
        orgID,
      });
      alert.setAlert({
        id: "datasource_success",
        onPressDelete: () => undefined,
        text: "Datasource is successfuly updated",
        title: "Success",
        type: "success",
      });
      onSuccess();
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const getValidationError = (path: string) =>
    validationError !== undefined &&
    validationError.inner.findIndex(item => item.path === path) >= 0;

  return {
    checkForErrors,
    create,
    error,
    getValidationError,
    loading,
    update,
  };
};
