// Copyright 2024 Merit International Inc. All Rights Reserved

import { useCallback, useEffect, useState } from "react";
import { useIsFocused } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  ListDatasources200ResponseDatasourcesInner,
  ListDatasourcesRequest,
  PaginationInfo,
} from "../../gen";

type Datasource = ListDatasources200ResponseDatasourcesInner;
export type DatasourceCache = readonly Datasource[];

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

const MAX_DATASOURCES = 2000;
const MAX_FETCHES = 20;

export const useDatasourcesListDataWithoutHidden = (api: DefaultApi, orgID: string) => {
  const [cache, setCache] = useState<DatasourceCache>([]);
  const [data, setData] = useState<readonly Datasource[]>();
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { errorHandler } = useServerErrorHandler();
  const [pagination, setPagination] = useState<PaginationMeta>({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [lastPagination, setLastPagination] = useState<PaginationInfo>();
  const [fetchCount, setFetchCount] = useState<number>(0);
  const isFocused = useIsFocused();

  const getPageFromCache = useCallback(
    (pageNumber: number) => {
      const firstIndexOnPage = pageNumber * 10 - 1;
      const lastIndexOnPage = firstIndexOnPage + 10;

      return cache.slice(firstIndexOnPage, lastIndexOnPage + 10);
    },
    [cache]
  );

  // Fetch datasources until cache has at least MAX_DATASOURCES or !hasMore
  useEffect(() => {
    const fetchDatasources = async (fetchParams: ListDatasourcesRequest) => {
      try {
        if (cache.length === 0) {
          // Only show spinner on first fetch to not block when fetching in background
          setLoading(true);
        }
        const res = await api.listDatasources(fetchParams);
        setFetchCount(fetchCount + 1);

        if (res.datasources !== undefined) {
          const datasources = res.datasources.filter(
            ds => ds.isHidden === false || ds.isHidden === undefined
          );
          setCache(prevCache => [...prevCache, ...datasources]);
        }

        setLastPagination(res.paginationInfo);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    const shouldFetchMore = cache.length < MAX_DATASOURCES && fetchCount < MAX_FETCHES;
    const canFetchMore = lastPagination === undefined || lastPagination.hasMore === true;

    if (shouldFetchMore && canFetchMore && isFocused) {
      fetchDatasources({
        limit: 100,
        orgID,
        start: lastPagination?.nextStartAfter,
      });
    }
  }, [api, cache.length, errorHandler, fetchCount, isFocused, lastPagination, orgID]);

  // Set data for correct page
  useEffect(() => {
    const firstIndexOnPage = page * 10;
    const lastIndexOnPage = firstIndexOnPage + 10;
    const newData = cache.slice(firstIndexOnPage, lastIndexOnPage);

    setData(newData);
    setPagination({
      hasNextPage: lastIndexOnPage < cache.length,
      hasPrevPage: page > 0,
    });
  }, [cache, page]);

  const resetData = () => {
    setData(undefined);
    setCache([]);
    setPagination({
      hasNextPage: false,
      hasPrevPage: false,
    });
    setPage(0);
    setFetchCount(0);
    setLastPagination(undefined);
  };

  const refresh = () => {
    if (loading) {
      return;
    }

    resetData();
  };

  const nextPage = () => {
    setPage(prev => {
      const newPage = prev + 1;
      const movingToCachedPage = cache.length > newPage;

      setPagination({
        hasNextPage: movingToCachedPage ? lastPagination?.hasMore ?? false : true,
        hasPrevPage: true,
      });

      return newPage;
    });
  };

  const prevPage = () => {
    setPage(prev => {
      const newPage = prev - 1;

      setData(getPageFromCache(newPage));
      setPagination({
        hasNextPage: true,
        hasPrevPage: newPage > 0,
      });

      return newPage;
    });
  };

  return {
    data,
    loading,
    nextPage,
    pagination,
    prevPage,
    refresh,
  };
};
