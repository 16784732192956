// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { SCREEN_NAME } from "./LinkedAppDetails";
import { View } from "react-native";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import React from "react";
import type { GetAgents200ResponseAgentsInner } from "../../../gen";

const { None, Some } = Helpers;

type Props = {
  readonly appDetails: GetAgents200ResponseAgentsInner;
};

export const Overview = ({ appDetails: { description, id, org, orgName } }: Props) => {
  const { theme } = useTheme();
  const { configuration } = useLoadedConfigurationState();

  const getOrgName = () => {
    if (None(orgName)) {
      return "--";
    }
    if (configuration.solUUID === org) {
      return configuration.solName;
    }

    return orgName;
  };

  return (
    <View style={{ marginHorizontal: 32 }}>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Description</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabDescription",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(description) && description === "" ? "--" : description}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Owner</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <View style={{ flexDirection: "row" }}>
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabViewOwnerName",
              screenName: SCREEN_NAME,
            }}
          >
            {getOrgName()}
          </Body>
          <HorizontalSpacer size={10} />
          <Body color={theme.colors.border.subdued}>|</Body>
          <HorizontalSpacer size={10} />
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabOwnerID",
              screenName: SCREEN_NAME,
            }}
          >
            {Some(org) && org === "" ? "--" : org}
          </Body>
        </View>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />
    </View>
  );
};
