// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button } from "@merit/frontend-components";
import { Datagrid, DatagridCollapsableSection, Pagination } from "../../components";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLinkedOrgs } from "../../api/useLinkedOrgs";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { GetFieldKind200Response, GetFieldKinds200Response } from "../../gen";

type Props = {
  readonly loading: boolean;
  readonly onPressView: (fieldKind: GetFieldKind200Response) => void;
};

const { None, Some } = Helpers;
const SCREEN_NAME = "Fields";

export const FieldsShared = ({ loading, onPressView }: Props) => {
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { sharedFieldsLimit } = useAppConstantsStore();
  const { configuration } = useLoadedConfigurationState();
  const { linkedOrgsMap } = useLinkedOrgs(api, selectedOrgId);
  const [sharedFields, setSharedFields] = useState<readonly GetFieldKinds200Response[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orgIDToExpand, setOrgIDToExpand] = useState<string>();

  const getFieldKinds = useCallback(
    (linkedOrgID: string, linkedOrgName?: string, start?: string, end?: string) =>
      api
        .getFieldKinds({
          end,
          limit: sharedFieldsLimit,
          orgID: selectedOrgId,
          ownerID: linkedOrgID,
          start,
        })
        .then(fieldsResponse => ({
          fieldKinds: fieldsResponse.fieldKinds,
          orgID: fieldsResponse.orgID,
          ownerOrgID:
            linkedOrgID === configuration.solUUID
              ? configuration.solName
              : linkedOrgName ?? linkedOrgID,
          paginationInfo: fieldsResponse.paginationInfo,
        })),
    [api, configuration.solName, configuration.solUUID, selectedOrgId, sharedFieldsLimit]
  );

  const getSharedFields = useCallback(() => {
    if (!(selectedOrgId in linkedOrgsMap)) {
      return;
    }

    setIsLoading(true);

    const initialFieldKinds = linkedOrgsMap[selectedOrgId].map(linkedOrg => ({
      fieldKinds: [],
      orgID: linkedOrg.orgID,
      ownerOrgID:
        linkedOrg.orgID === configuration.solUUID
          ? configuration.solName
          : linkedOrg.orgInfo.name ?? linkedOrg.orgID,
    })) as readonly GetFieldKinds200Response[];

    setIsLoading(false);
    setSharedFields(initialFieldKinds);
  }, [configuration.solName, configuration.solUUID, linkedOrgsMap, selectedOrgId]);

  useEffect(() => {
    getSharedFields();
  }, [getSharedFields]);

  const columns: readonly DatagridColumn<GetFieldKind200Response>[] = [
    {
      key: "organizationName",
      label: "Organization name",
      size: 200,
    },
    {
      key: "name",
      label: "Field name",
      size: "flex",
    },
    {
      key: "dataType",
      label: "Data type",
      size: 180,
    },
    {
      key: "fieldKindID",
      label: "Field ID",
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      size: "flex",
    },
    {
      key: "extendable",
      label: "Extendable",
      renderer: (item, testProps) => (
        <Body testProps={testProps}>{Some(item.extendable) && item.extendable ? "Yes" : "No"}</Body>
      ),
      size: 150,
    },
    {
      key: "actions",
      label: "Actions",
      renderer: (item, testProps) => (
        <View style={{ width: 80 }}>
          <Button
            onPress={() => {
              onPressView(item);
            }}
            size="small"
            testProps={
              Some(testProps)
                ? { ...testProps, elementName: `${testProps.elementName}ViewButton` }
                : testProps
            }
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 128,
    },
  ];

  const paginate = async (org: GetFieldKinds200Response | undefined, type: "next" | "prev") => {
    if (None(org)) {
      return;
    }
    setIsLoading(true);
    const orgFieldKinds = await getFieldKinds(
      org.ownerOrgID === configuration.solName ? configuration.solUUID : org.ownerOrgID,
      undefined,
      type === "next" ? org.paginationInfo?.nextStartAfter : undefined,
      type === "prev" ? org.paginationInfo?.previousEndBefore : undefined
    );
    setIsLoading(false);

    setSharedFields(prevState =>
      prevState.map(field => {
        if (org.ownerOrgID === field.ownerOrgID) {
          return {
            ...field,
            fieldKinds: orgFieldKinds.fieldKinds,
            paginationInfo: orgFieldKinds.paginationInfo,
          };
        }

        return field;
      })
    );
  };

  const loadFieldKindsData = useCallback(
    async (orgName: string) => {
      const org = sharedFields.find(sf => sf.ownerOrgID === orgName);
      if (org !== undefined && org.fieldKinds.length < 1) {
        setIsLoading(true);
        const orgFieldKinds = await getFieldKinds(org.orgID, orgName);
        const repFieldKinds = sharedFields.map(fk => {
          if (fk.ownerOrgID === orgName) {
            return orgFieldKinds;
          }

          return fk;
        });

        setIsLoading(false);
        setSharedFields(repFieldKinds);
      }
    },
    [getFieldKinds, sharedFields]
  );

  const expandedOrgData = useMemo(() => {
    if (Some(orgIDToExpand)) {
      loadFieldKindsData(orgIDToExpand);

      return sharedFields.find(({ ownerOrgID }) => ownerOrgID === orgIDToExpand);
    }

    return undefined;
  }, [loadFieldKindsData, orgIDToExpand, sharedFields]);

  return (
    <>
      <ScrollView style={{ flex: 1 }}>
        <Datagrid
          columns={columns}
          loading={isLoading || loading}
          testProps={{
            elementName: "sharedFieldsListView",
            screenName: SCREEN_NAME,
          }}
        >
          {sharedFields.map(
            sf =>
              Some(sf) && (
                <DatagridCollapsableSection
                  columns={columns}
                  data={sf.fieldKinds}
                  isExpanded={orgIDToExpand === sf.ownerOrgID}
                  key={sf.ownerOrgID}
                  noDataMessage="No fields have been shared from this organization"
                  onToggle={() => {
                    setOrgIDToExpand(prevState =>
                      prevState === sf.ownerOrgID ? undefined : sf.ownerOrgID
                    );
                  }}
                  testProps={{
                    elementId: "fieldKindID",
                    elementName: "sharedFieldsListView",
                    screenName: SCREEN_NAME,
                  }}
                  testPropsElementIdKey="fieldKindID"
                  testPropsRowId={sf.orgID}
                  title={sf.ownerOrgID}
                />
              )
          )}
        </Datagrid>
      </ScrollView>

      <Pagination
        disableNext={
          Some(expandedOrgData) ? None(expandedOrgData.paginationInfo?.nextStartAfter) : true
        }
        disablePrev={
          Some(expandedOrgData) ? None(expandedOrgData.paginationInfo?.previousEndBefore) : true
        }
        onNext={() => {
          paginate(expandedOrgData, "next");
        }}
        onPrev={() => {
          paginate(expandedOrgData, "prev");
        }}
        testProps={{
          elementName: "sharedFieldsListView",
          screenName: SCREEN_NAME,
        }}
      />
    </>
  );
};
