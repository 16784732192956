// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Checkbox, Icon, Select, TextInput, useTheme } from "@merit/frontend-components";
import { Field } from "./Field";
import { FieldArray } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum as Predicate } from "../../../gen";
import { Pressable, StyleSheet, View } from "react-native";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { getFieldType } from "../../../utils/getFieldType";
import { useAlertStore } from "../../../stores";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import type { FormikProps } from "formik";
import type {
  GetTemplateRules200ResponseRules,
  GetTemplateRules200ResponseRulesBoolFieldHasValue,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRule as InheritedRule,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as TemplateField,
} from "../../../gen";
import type { OPTestProps } from "../../../types/TestProps";

const { None, Some } = Helpers;

type Rule = {
  readonly id: string;
  readonly target: string | undefined;
  readonly predicate: Predicate | undefined;
  readonly errorMessage: string;
  readonly value?: string;
  readonly required: boolean;
};

const PredicatesWithoutValue: readonly string[] = [
  Predicate.FieldHasValue,
  Predicate.IsFalse,
  Predicate.IsTrue,
];
export type FormValues = {
  readonly rules: readonly Rule[];
};

type Props = {
  readonly inheritedRules: readonly InheritedRule[] | undefined;
  readonly templateFields: readonly TemplateField[] | undefined;
  readonly orgRules: GetTemplateRules200ResponseRules;
  readonly form: FormikProps<FormValues>;
};

export const OrgCreatedRules = ({ form, inheritedRules, orgRules, templateFields }: Props) => {
  const { theme } = useTheme();
  const { deleteAlert, setAlert } = useAlertStore();

  const styles = StyleSheet.create({
    fieldWrapper: {
      width: 208,
    },
    listHeader: {
      backgroundColor: theme.colors.surface.disabled,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: 10,
    },
    listItem: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    noFields: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      paddingHorizontal: 32,
      paddingVertical: 22,
    },
  });

  const [keyToRerenderPredicate, setKeyToRerenderPredicate] = useState(0);
  const { getFieldMeta, setFieldTouched, setFieldValue } = form;

  const templateFieldOptions = Some(templateFields)
    ? templateFields.map(field => ({
        label: field.name ?? "",
        value: field.fieldID ?? -1,
      }))
    : [];

  const getPredicates = (fieldId: string) => {
    const fieldType = getFieldType(fieldId, templateFields ?? []);
    const fieldTypeInCamelCase = fieldType.charAt(0).toLowerCase() + fieldType.slice(1);

    // Hack: We do not have a specific type for field types at the moment
    const predicates = orgRules[fieldTypeInCamelCase as keyof GetTemplateRules200ResponseRules] as
      | Readonly<Record<string, GetTemplateRules200ResponseRulesBoolFieldHasValue>>
      | undefined;

    if (None(predicates)) {
      throw new Error(`Couldn't fetch the predicates with the field type: ${fieldTypeInCamelCase}`);
    }

    return Object.entries(predicates)
      .map(([key, value]) => ({
        label: value.readable ?? "",
        value: key.charAt(0).toUpperCase() + key.slice(1),
      }))
      .filter(_ => _.value !== Predicate.FieldHasValue);
  };

  const errorText = (fieldName: string, testProps: Omit<OPTestProps, "screenName">) => {
    if (getFieldMeta(fieldName).touched && Some(getFieldMeta(fieldName).error)) {
      return (
        <>
          <VerticalSpacer size={theme.spacing.s} />
          <Body
            style={{
              color: theme.colors.text.alert.critical,
              fontSize: theme.fontSizes.s.fontSize,
            }}
            testProps={{
              ...testProps,
              screenName: SCREEN_NAME,
            }}
          >
            {getFieldMeta(fieldName).error}
          </Body>
        </>
      );
    }

    return <></>;
  };

  const duplicateRuleAlert = () => {
    setAlert({
      closable: true,
      id: uuidv4(),
      onPressDelete: id => {
        deleteAlert(id);
      },
      text: "The rule has already been added",
      type: "error",
    });
    setKeyToRerenderPredicate(prevKey => prevKey + 1);
  };

  const allInheritedRules = Some(inheritedRules)
    ? inheritedRules.flatMap(rule => rule.ruleConditions)
    : [];

  return (
    <>
      <FieldArray
        name="rules"
        render={arrayHelpers => (
          <>
            <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
              <Pressable
                onPress={() => {
                  arrayHelpers.push({
                    errorMessage: "",
                    id: uuidv4(),
                    predicate: undefined,
                    required: false,
                    target: undefined,
                    value: "",
                  });
                }}
              >
                <Body
                  style={{ color: theme.colors.text.link }}
                  testProps={{
                    elementName: "rulesTabAddActivityRuleButton",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Add rule
                </Body>
              </Pressable>
            </View>

            <VerticalSpacer size={theme.spacing.xxl} />

            <>
              {form.values.rules.length > 0 ? (
                <>
                  <View style={styles.listHeader}>
                    <View style={styles.fieldWrapper}>
                      <Body
                        style={{ fontWeight: theme.fontWeights.semiBold }}
                        testProps={{
                          elementName: "rulesTabActivityRulesOrgCreatedListItemHeaderFieldName",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Field name
                      </Body>
                    </View>
                    <HorizontalSpacer size={theme.spacing.xxl} />
                    <View style={styles.fieldWrapper}>
                      <Body
                        style={{ fontWeight: theme.fontWeights.semiBold }}
                        testProps={{
                          elementName: "rulesTabActivityRulesOrgCreatedListItemHeaderCriteria",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Criteria
                      </Body>
                    </View>
                    <HorizontalSpacer size={theme.spacing.xxl} />
                    <View style={styles.fieldWrapper}>
                      <Body
                        style={{ fontWeight: theme.fontWeights.semiBold }}
                        testProps={{
                          elementName: "rulesTabActivityRulesOrgCreatedListItemHeaderArgument",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Value
                      </Body>
                    </View>
                    <HorizontalSpacer size={theme.spacing.xxl} />
                    <View style={styles.fieldWrapper}>
                      <Body
                        style={{ fontWeight: theme.fontWeights.semiBold }}
                        testProps={{
                          elementName: "rulesTabActivityRulesInheritedListItemHeaderRequired",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Required
                      </Body>
                    </View>
                  </View>
                  <View>
                    {form.values.rules.map((rule, index) => (
                      <View
                        key={rule.id}
                        style={[
                          styles.listItem,
                          {
                            flexDirection: "column",
                            paddingVertical: theme.spacing.xxl,
                          },
                        ]}
                      >
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={styles.fieldWrapper}>
                              <Select
                                defaultValue={templateFieldOptions.find(
                                  _ => _.value === rule.target
                                )}
                                getOptionValue={option => option.label}
                                label=""
                                onSelectOption={option => {
                                  const existingRule = [
                                    ...form.values.rules,
                                    ...allInheritedRules,
                                  ].find(
                                    ({ predicate, target }) =>
                                      target === option.value && predicate === rule.predicate
                                  );
                                  if (Some(existingRule)) {
                                    duplicateRuleAlert();

                                    arrayHelpers.replace(index, {
                                      id: rule.id,
                                      predicate: undefined,
                                      target: option.value,
                                    });

                                    return;
                                  }
                                  arrayHelpers.replace(index, {
                                    id: rule.id,
                                    predicate: undefined,
                                    target: option.value,
                                  });
                                  setFieldTouched(`rules[${index}].target`, true);
                                  setKeyToRerenderPredicate(prevKey => prevKey + 1);
                                }}
                                options={templateFieldOptions}
                                placeholder={{
                                  label: "Field name",
                                  value: "",
                                }}
                                showLabel={false}
                                size="small"
                                testProps={{
                                  elementId: rule.id,
                                  elementName: "rulesTabActivityRulesOrgCreatedListItemFieldName",
                                  screenName: SCREEN_NAME,
                                }}
                                usePortal
                              />

                              {errorText(`rules[${index}].target`, {
                                elementId: rule.id,
                                elementName:
                                  "rulesTabActivityRulesOrgCreatedListItemFieldNameError",
                              })}
                            </View>

                            <HorizontalSpacer size={theme.spacing.xxl} />

                            <View style={styles.fieldWrapper}>
                              {Some(rule.target) && rule.predicate !== Predicate.FieldHasValue && (
                                <>
                                  <Select
                                    defaultValue={getPredicates(rule.target).find(
                                      _ => _.value === rule.predicate
                                    )}
                                    getOptionValue={option => option.label}
                                    key={keyToRerenderPredicate}
                                    label=""
                                    onSelectOption={option => {
                                      const existingRule = [
                                        ...form.values.rules,
                                        ...allInheritedRules,
                                      ].find(
                                        ({ predicate, target }) =>
                                          rule.target === target && predicate === option.value
                                      );

                                      if (Some(existingRule)) {
                                        duplicateRuleAlert();

                                        return;
                                      }
                                      setFieldValue(`rules[${index}].predicate`, option.value);
                                      setFieldValue(`rules[${index}].value`, "");
                                      setFieldTouched(`rules[${index}].target`, true);
                                      setKeyToRerenderPredicate(prevKey => prevKey + 1);
                                    }}
                                    options={getPredicates(rule.target)}
                                    placeholder={{
                                      label: "Criteria",
                                      value: "",
                                    }}
                                    showLabel={false}
                                    size="small"
                                    testProps={{
                                      elementId: rule.id,
                                      elementName:
                                        "rulesTabActivityRulesOrgCreatedListItemCriteria",
                                      screenName: SCREEN_NAME,
                                    }}
                                    usePortal
                                  />
                                  {errorText(`rules[${index}].predicate`, {
                                    elementId: rule.id,
                                    elementName:
                                      "rulesTabActivityRulesOrgCreatedListItemCriteriaError",
                                  })}
                                </>
                              )}
                            </View>

                            <HorizontalSpacer size={theme.spacing.xxl} />

                            <View style={{ minWidth: 208 }}>
                              {Some(rule.predicate) &&
                                // @ts-expect-error Not sure what's wrong with formik,
                                //  had to set "" to display the error msg when unselecting the checkbox
                                rule.predicate !== "" &&
                                !PredicatesWithoutValue.includes(rule.predicate) &&
                                rule.target !== undefined && (
                                  <>
                                    <Field
                                      defaultValue={rule.value}
                                      key={keyToRerenderPredicate}
                                      onChange={value => {
                                        setFieldValue(`rules[${index}].value`, value);
                                      }}
                                      predicate={rule.predicate}
                                      testProps={{
                                        elementId: rule.id,
                                        elementName:
                                          "rulesTabActivityRulesOrgCreatedListItemArgument",
                                        screenName: SCREEN_NAME,
                                      }}
                                      type={getFieldType(rule.target, templateFields ?? [])}
                                    />
                                    {errorText(`rules[${index}].value`, {
                                      elementId: rule.id,
                                      elementName:
                                        "rulesTabActivityRulesOrgCreatedListItemArgumentError",
                                    })}
                                  </>
                                )}
                            </View>

                            <HorizontalSpacer size={theme.spacing.xxl} />

                            <View style={{ paddingVertical: 6 }}>
                              <View style={{ width: 24 }}>
                                <Checkbox
                                  defaultChecked={rule.required}
                                  onChange={isChecked => {
                                    setFieldValue(`rules[${index}].required`, isChecked);
                                    if (!isChecked && rule.predicate === Predicate.FieldHasValue) {
                                      setFieldValue(`rules[${index}].predicate`, "");
                                    }
                                  }}
                                  size="medium"
                                  testProps={{
                                    elementId: rule.id,
                                    elementName: "rulesTabActivityRulesOrgCreatedListItemRequired",
                                    screenName: SCREEN_NAME,
                                  }}
                                />
                              </View>
                            </View>
                          </View>
                          <Pressable
                            onPress={() => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            <Icon
                              name="closeSmallDefault"
                              testProps={{
                                elementId: rule.id,
                                elementName:
                                  "rulesTabActivityRulesOrgCreatedListItemRemoveRuleButton",
                                screenName: SCREEN_NAME,
                              }}
                            />
                          </Pressable>
                        </View>

                        <VerticalSpacer size={theme.spacing.xxl} />

                        <View style={{ maxWidth: 824 }}>
                          <TextInput
                            label="Error message"
                            onChangeText={message => {
                              setFieldValue(`rules[${index}].errorMessage`, message);
                              setFieldTouched(`rules[${index}].errorMessage`, true);
                            }}
                            placeholder="Message"
                            size="large"
                            testProps={{
                              elementId: rule.id,
                              elementName:
                                "rulesTabActivityRulesOrgCreatedListItemErrorMessageTextInput",
                              screenName: SCREEN_NAME,
                            }}
                            value={rule.errorMessage}
                          />
                          {errorText(`rules[${index}].errorMessage`, {
                            elementId: rule.id,
                            elementName: "rulesTabActivityRulesOrgCreatedListItemErrorMessageError",
                          })}
                          <VerticalSpacer size={theme.spacing.s} />
                          <Body>120 maximum character limit</Body>
                        </View>
                      </View>
                    ))}
                  </View>
                </>
              ) : (
                <View style={styles.noFields}>
                  <Body
                    testProps={{
                      elementName: "rulesTabActivityRulesOrgCreatedNoDataPlaceholder",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    There are no org created rules for this template
                  </Body>
                </View>
              )}
            </>
          </>
        )}
      />
    </>
  );
};
