// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Checkbox } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../components";
import { EllipsisText } from "../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import { getDateTimeString } from "../../utils/time";
import { useGetContainerValueByBaseFieldName } from "../../utils/getContainerFieldValue";
import React, { useState } from "react";
import type { ContainerWithTemplateType } from "./Approvals";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { OrgsGet200ResponseContainersInner } from "../../gen";

type Props = {
  readonly folios: readonly OrgsGet200ResponseContainersInner[];
  readonly onRemoveContainer: (containerID: string) => void;
  readonly onApproveContainer: (containerID: string) => Promise<void>;
  readonly onViewContainer: (container: ContainerWithTemplateType) => void;
};

const { Some } = Helpers;
const SCREEN_NAME = "Approvals";

export const Folios = ({
  folios,
  onApproveContainer,
  onRemoveContainer,
  onViewContainer,
}: Props) => {
  const { getContainerValueByBaseFieldName } = useGetContainerValueByBaseFieldName();
  const [selectedFolioIDs, setSelectedFolioIDs] = useState<readonly string[]>([]);

  const columns: readonly DatagridColumn<OrgsGet200ResponseContainersInner>[] = [
    {
      key: "checkbox",
      label: (
        <Checkbox
          defaultChecked={selectedFolioIDs.length === folios.length}
          onChange={isChecked => {
            if (isChecked) {
              setSelectedFolioIDs(folios.map(folio => folio.id));

              return;
            }
            setSelectedFolioIDs([]);
          }}
          testProps={{
            elementName: "foliosListViewGridHeader",
            screenName: SCREEN_NAME,
          }}
        />
      ),
      // eslint-disable-next-line react/no-multi-comp
      renderer: (folio, testProps) => (
        <Checkbox
          defaultChecked={selectedFolioIDs.includes(folio.id)}
          onChange={isChecked => {
            if (isChecked && Some(folio.id) && !selectedFolioIDs.includes(folio.id)) {
              setSelectedFolioIDs(prevState => [...prevState, folio.id]);

              return;
            }
            setSelectedFolioIDs(prevState => prevState.filter(id => id !== folio.id));
          }}
          testProps={testProps}
        />
      ),
      size: 60,
    },
    {
      key: "orgName",
      label: "Organization Name",
      renderer: (container, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "organizationName",
            templateType: "folio",
          })}
        />
      ),
      size: "flex",
    },
    {
      key: "orgId",
      label: "Organization ID",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {container.recipient?.id ?? "--"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "folio",
      label: "Folio template",
      renderer: (container, testProps) => (
        <EllipsisText testProps={testProps} text={container.name ?? "--"} />
      ),
      size: "flex",
    },
    {
      key: "type",
      label: "Type",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          Folio
        </Body>
      ),
      size: 128,
    },
    {
      key: "createdAt",
      label: "Created at",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(container.createdAt) ? getDateTimeString(container.createdAt) : "--"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: (item, testProps) => (
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 80 }}>
            <Button
              onPress={() => {
                onViewContainer({ ...item, templateType: "Folio" });
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}ViewButton`,
                    }
                  : testProps
              }
              text="View"
              type="secondary"
            />
          </View>
          <HorizontalSpacer />
          <Button
            iconLeft="closeSmallDefault"
            onPress={() => {
              onRemoveContainer(item.id);
            }}
            shape="square"
            size="small"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}RejectButton`,
                  }
                : testProps
            }
            type="destructive"
          />
          <HorizontalSpacer />
          <View style={{ width: 130 }}>
            <Button
              disabled={item.completed === false}
              iconLeft={
                item.completed === false ? "checkmarkSmallDisabled" : "checkmarkSmallAction"
              }
              onPress={() => {
                onApproveContainer(item.id);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: Some(testProps) ? `${testProps.elementName}ApproveButton` : "",
                    }
                  : testProps
              }
              text="Approve"
            />
          </View>
        </View>
      ),
      size: 300,
    },
  ];

  return (
    <Datagrid
      columns={columns}
      testProps={{
        elementName: "foliosListView",
        screenName: SCREEN_NAME,
      }}
    >
      <DatagridBody
        columns={columns}
        data={folios}
        testProps={{
          elementName: "foliosListView",
          screenName: SCREEN_NAME,
        }}
        testPropsElementIdKey="id"
      />
    </Datagrid>
  );
};
