// Copyright 2023 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { ConfirmationModal } from "../../../components/Modals";
import { Errors, Helpers } from "@merit/frontend-utils";
import { Footer } from "../Footer";
import { Form, Formik } from "formik";
import { InheritedRules } from "./InheritedRules";
import { OrgCreatedRules } from "./OrgCreatedRules";
import { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum as Predicate } from "../../../gen";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin } from "../../../components";
import { useAlertStore } from "../../../stores/alertStore";
import { useApi } from "../../../api/api";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useMemo, useState } from "react";
import validator from "validator";
import type {
  EditTemplateRequestRules,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum as FieldType,
  GetDatasource200ResponseMappedTemplatesInner,
  GetTemplateRules200ResponseRules,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner as RuleCondition,
} from "../../../gen";
import type { FormValues } from "./OrgCreatedRules";
import type { FormikProps } from "formik";

const { None, Some } = Helpers;
const { UnreachableCaseError } = Errors;

type Props = {
  readonly template: GetDatasource200ResponseMappedTemplatesInner;
  readonly unSaveConfirmation: () => void;
  readonly onSave: (rules: EditTemplateRequestRules) => void;
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
};

export const Rules = ({ formRef, onSave, template, unSaveConfirmation }: Props) => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    divider: {
      backgroundColor: theme.colors.border.disabled,
      height: 1,
      marginVertical: theme.spacing.xxl,
    },
  });

  const [orgCreatedRules, setOrgCreatedRules] = useState<FormValues>();
  const [rules, setRules] = useState<GetTemplateRules200ResponseRules>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (
    type: FieldType,
    value: string,
    predicate: Predicate,
    createError: (params?: yup.CreateErrorOptions | undefined) => yup.ValidationError
  ) => {
    switch (type) {
      case "Text":
      case "Markdown":
      case "PhoneNumber":
      case "Bool":
      case "Blob":
      case "JSON":
        return true;
      case "Number": {
        if (!validator.isNumeric(value)) {
          return createError({ message: "Accepts numbers only" });
        }

        return true;
      }
      case "Email": {
        if (!validator.isFQDN(value)) {
          return createError({ message: "Please enter a valid domain" });
        }

        return true;
      }
      case "DateTime":
      case "Date": {
        if (predicate === "BeforeTodayMinusXDays") {
          if (!validator.isNumeric(value)) {
            return createError({ message: "Accepts numbers only" });
          }
        }

        return true;
      }

      default:
        throw new UnreachableCaseError(type);
    }
  };

  const rulesValidationSchema = yup.object({
    rules: yup.array().of(
      yup.object().shape({
        errorMessage: yup.string().optional().trim().max(120, "Should not exceed 120 character"),
        predicate: yup.string().when("required", {
          is: (value: boolean) => !value,
          then: schema => schema.required("Please select criteria"),
        }),
        required: yup.bool(),
        target: yup.string().required("Please select field name"),
        value: yup
          .string()
          .trim()
          .test("validate", "Please check the value", (value, context) => {
            if (Some(value)) {
              const { predicate, target } = context.parent;
              const field = template.templateFields?.find(_ => _.fieldID === target);
              if (Some(field) && Some(field.type)) {
                return validateField(field.type, value, predicate, context.createError);
              }
            }

            return true;
          }),
      })
    ),
  });

  useEffect(() => {
    const getRules = async () => {
      try {
        setIsLoading(true);
        const response = await api.getTemplateRules({ orgID: selectedOrgId });
        setRules(response.rules);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    getRules();
  }, [api, deleteAlert, errorHandler, selectedOrgId, setAlert]);

  const getFieldName = (fieldID: string | undefined) => {
    const templateField = template.templateFields?.find(_ => _.fieldID === fieldID);

    return Some(templateField) ? templateField.name ?? "Field" : "Field";
  };

  const onConfirmation = () => {
    const activenessRules = orgCreatedRules?.rules
      .filter(_ => _.predicate !== undefined)
      .filter(_ => _.predicate !== Predicate.FieldHasValue)
      .map(rule => {
        const baseProperties = {
          errorMessage: Some(rule.errorMessage) ? rule.errorMessage.trim() : "",
          predicate: Some(rule.predicate) ? rule.predicate : undefined,
          target: rule.target ?? null,
        };

        if (None(rule.value) || rule.value === "") {
          return {
            ...baseProperties,
            arguments: [],
          };
        }

        if (rule.predicate === "BeforeThisTimeOfDay" || rule.predicate === "AfterThisTimeOfDay") {
          return {
            ...baseProperties,
            arguments: rule.value.split(":"),
          };
        }

        return {
          ...baseProperties,
          arguments: rule.value.split(","),
        };
      });

    const completenessRules = orgCreatedRules?.rules.filter(_ => _.required) ?? [];

    const uniqueCompletenessRules = completenessRules
      .filter((rule, index) => index === completenessRules.findIndex(_ => rule.target === _.target))
      .map(rule => ({
        arguments: [],
        errorMessage: `${getFieldName(rule.target)} is required`,
        predicate: Predicate.FieldHasValue,
        target: rule.target ?? null,
      }));

    const inheritedCompletenessRules =
      template.inheritedCompletenessRules?.map(_ => _.ruleConditions).flat() ?? [];

    const duplicateRules = uniqueCompletenessRules.filter(rule =>
      inheritedCompletenessRules.find(
        _ => _.target === rule.target && _.predicate === rule.predicate
      )
    );

    if (duplicateRules.length > 0) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "The rule has already been added",
        type: "error",
      });
      setIsConfirmationModalOpen(false);

      return;
    }

    onSave({
      activeness: activenessRules,
      completeness: uniqueCompletenessRules,
    });

    setIsConfirmationModalOpen(false);
  };

  const getArgumentsValue = (ruleCondition: RuleCondition) => {
    const { arguments: argValues, predicate } = ruleCondition;

    if (None(argValues) || argValues.length <= 0) {
      return "";
    }

    if (predicate === "AfterThisTimeOfDay" || predicate === "BeforeThisTimeOfDay") {
      return argValues.join(":");
    }

    return argValues[0];
  };

  const existingOwnActivityRules = useMemo(() => {
    const activenessRules = template.ownActivenessRule?.ruleConditions ?? [];
    const completenessRules = template.ownCompletenessRule?.ruleConditions ?? [];

    return activenessRules.map(rule => ({
      errorMessage: rule.errorMessage,
      id: uuidv4(),
      predicate: rule.predicate,
      required: completenessRules.some(
        _ => _.target === rule.target && _.predicate === Predicate.FieldHasValue
      ),
      target: rule.target,
      value: getArgumentsValue(rule),
    }));
  }, [template.ownActivenessRule?.ruleConditions, template.ownCompletenessRule?.ruleConditions]);

  const existingOwnCompletenessRules = useMemo(() => {
    const activenessRules = template.ownActivenessRule?.ruleConditions ?? [];
    const completenessRules = template.ownCompletenessRule?.ruleConditions ?? [];

    return completenessRules
      .filter(rule => !activenessRules.some(activityRule => activityRule.target === rule.target))
      .map(rule => ({
        errorMessage: rule.errorMessage,
        id: uuidv4(),
        predicate: rule.predicate,
        required: rule.predicate === Predicate.FieldHasValue,
        target: rule.target,
      }));
  }, [template.ownActivenessRule?.ruleConditions, template.ownCompletenessRule?.ruleConditions]);

  const validateFormAndSubmit = async () => {
    if (None(formRef.current)) {
      return;
    }

    await formRef.current.validateForm();
    formRef.current.submitForm();
    if (formRef.current.isValid) {
      setIsConfirmationModalOpen(true);
    }
  };

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Spin />
      </View>
    );
  }

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ alignItems: "center", flex: 1 }}>
          <View style={{ paddingVertical: theme.spacing.xxl, width: 960 }}>
            {Some(template) && (
              <Formik
                enableReinitialize
                initialValues={{
                  rules: [...existingOwnActivityRules, ...existingOwnCompletenessRules],
                }}
                innerRef={formRef}
                onSubmit={formData => {
                  setOrgCreatedRules(formData);
                }}
                validationSchema={rulesValidationSchema}
              >
                {props => (
                  <Form>
                    {Some(rules) && (
                      <>
                        <InheritedRules
                          inheritedActivenessRules={template.inheritedActivenessRules ?? []}
                          inheritedCompletenessRules={template.inheritedCompletenessRules ?? []}
                          orgRules={rules}
                          templateFields={template.templateFields}
                        />

                        <View style={styles.divider} />

                        <OrgCreatedRules
                          form={props}
                          inheritedRules={template.inheritedActivenessRules}
                          orgRules={rules}
                          templateFields={template.templateFields}
                        />
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </View>
        </View>
      </ScrollView>
      <Footer
        onCancel={unSaveConfirmation}
        onSave={() => {
          validateFormAndSubmit();
        }}
        testProps={{ elementName: "configureTemplateRulesTab", screenName: SCREEN_NAME }}
      />
      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="save"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={onConfirmation}
          testProps={{
            elementName: "configureTemplateRulesTabSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save template"
        />
      )}
    </>
  );
};
