// Copyright 2023 Merit International Inc. All Rights Reserved

import { Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../Spacer";
import type { OPTestProps } from "../../types/TestProps";
import type { ReactNode } from "react";

type Props = {
  readonly rightElements?: ReactNode;
  readonly title: string;
  readonly testProps?: OPTestProps;
};

const { Some } = Helpers;

export const LoggedInHeader = ({ rightElements, testProps, title }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      justifyContent: "space-between",
      minHeight: 80,
      paddingHorizontal: 32,
      paddingTop: theme.spacing.l,
    },
  });

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Heading
          bold
          level="2"
          testProps={
            Some(testProps)
              ? {
                  ...testProps,
                  elementName: `${testProps.elementName}HeaderText`,
                }
              : testProps
          }
        >
          {title}
        </Heading>
        {rightElements}
      </View>
      <VerticalSpacer size={theme.spacing.s} />
    </View>
  );
};
