import { Body, Button, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText } from "../../components";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { Search200ResponsePoliciesInner } from "../../gen";

const { Some } = Helpers;
const SCREEN_NAME = "Policies";

type Props = {
  readonly polices: readonly Search200ResponsePoliciesInner[];
  readonly onPressView: (policyID: string) => void;
};

export const PoliciesTable = ({ onPressView, polices }: Props) => {
  const { theme } = useTheme();

  const columns: readonly DatagridColumn<Search200ResponsePoliciesInner>[] = [
    { key: "name", label: "Name", size: "flex" },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(description) && description.length > 0 ? description : "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "state",
      label: "State",
      renderer: (item, testProps) => (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <View
            style={{
              backgroundColor:
                item.state === "live"
                  ? theme.colors.icon.alert.success
                  : theme.colors.icon.disabled,
              borderRadius: theme.borderRadii.m,
              height: 8,
              marginRight: theme.spacing.m,
              width: 8,
            }}
          />
          <Body capitalize testProps={testProps}>
            {item.state}
          </Body>
        </View>
      ),
      size: 128,
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: ({ id }, testProps) => (
        <View style={{ width: 80 }}>
          <Button
            onPress={() => {
              onPressView(id);
            }}
            size="small"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}ViewButton`,
                  }
                : testProps
            }
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 128,
    },
  ];

  return (
    <ScrollView>
      <Datagrid
        columns={columns}
        testProps={{
          elementName: "ourPoliciesListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={polices}
          testProps={{
            elementName: "ourPoliciesListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="id"
        />
      </Datagrid>
    </ScrollView>
  );
};
