import { Body, Button, Icon } from "@merit/frontend-components";
import { Datagrid, DatagridBody, Pagination, Spin } from "@src/components";
import { Helpers } from "@merit/frontend-utils";
import { NoDatasourcesScreen } from "@src/screens/Datasources/NoDatasources";
import { SCREEN_NAME } from "@src/screens/Templates/Templates";
import { ScrollView, View } from "react-native";
import { getDateTimeString } from "@src/utils/time";
import { useApi } from "@src/api/api";
import { useDataRefreshEffect } from "@src/hooks";
import { useDatasourcesListData } from "@src/screens/Datasources/useDatasourcesListData";
import { useDatasourcesListDataWithoutHidden } from "@src/screens/Datasources/useDatasourcesListDataWithoutHidden";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsFocused } from "@react-navigation/native";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import React, { useCallback, useEffect } from "react";
import type { DatagridColumn } from "@src/components/Datagrid/types";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { ListDatasources200ResponseDatasourcesInner } from "@src/gen";

type Props = {
  readonly showHidden: boolean;
  readonly setDatasourceId: (id: string) => void;
  readonly createNewDatasource: () => void;
  readonly refresh: boolean;
  readonly setRefresh: (value: boolean) => void;
};

export const DatasourcesDatagridList = ({
  createNewDatasource,
  refresh,
  setDatasourceId,
  setRefresh,
  showHidden,
}: Props) => {
  const { Some } = Helpers;
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const isFocused = useIsFocused();
  const { showHideDatasourceFeature } = useFlags<LDFeatureFlags>();

  const datasourcesWithoutHidden = useDatasourcesListDataWithoutHidden(api, selectedOrgId);
  const datasourcesWithHidden = useDatasourcesListData(api, selectedOrgId, isFocused);

  const datasources =
    showHidden && showHideDatasourceFeature ? datasourcesWithHidden : datasourcesWithoutHidden;

  const hiddenColumn: readonly DatagridColumn<ListDatasources200ResponseDatasourcesInner>[] = [
    {
      key: "isHidden",
      label: "Hidden",
      renderer: (item, testProps) =>
        item.isHidden === true && <Icon name="checkmarkSmallAction" testProps={testProps} />,
      size: 100,
    },
  ];

  const columns: readonly DatagridColumn<ListDatasources200ResponseDatasourcesInner>[] = [
    { key: "name", label: "Name", size: "flex" },
    { key: "id", label: "Data source ID", size: 360 },
    {
      key: "lastIngestionTime",
      label: "Last ingestion",
      renderer: (item, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(item.lastIngestion) && item.lastIngestion !== ""
            ? getDateTimeString(item.lastIngestion)
            : "--"}
        </Body>
      ),
      size: 200,
    },
    ...(showHidden && showHideDatasourceFeature ? hiddenColumn : []),
    {
      key: "action",
      label: "Actions",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      renderer: (datasource, testProps) => (
        <View style={{ maxWidth: 80 }}>
          <Button
            onPress={() => {
              setDatasourceId(datasource.id);
            }}
            size="small"
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps) ? `${testProps.elementName}ViewButton` : "",
              screenName: SCREEN_NAME,
            }}
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 120,
    },
  ];

  useEffect(() => {
    if (refresh) {
      datasources.refresh();
      setRefresh(false);
    }
  }, [datasources, refresh, setRefresh]);

  useDataRefreshEffect(
    useCallback(() => {
      datasources.refresh();
    }, [datasources])
  );

  if (datasources.loading || datasources.data === undefined) {
    return (
      <View style={{ flex: 1, justifyContent: "flex-start" }}>
        <Spin />
      </View>
    );
  }

  if (Some(datasources.data) && datasources.data.length === 0) {
    return (
      <NoDatasourcesScreen
        createDatasource={createNewDatasource}
        testProps={{
          elementName: "noDatasources",
          screenName: SCREEN_NAME,
        }}
      />
    );
  }

  return (
    <>
      <View style={{ flex: 1 }}>
        <ScrollView>
          <Datagrid
            columns={columns}
            loading={datasources.loading}
            testProps={{ elementName: "datasourcesListView", screenName: SCREEN_NAME }}
          >
            <DatagridBody
              columns={columns}
              data={datasources.data}
              testProps={{
                elementId: "id",
                elementName: "datasourcesListView",
                screenName: SCREEN_NAME,
              }}
              testPropsElementIdKey="id"
            />
          </Datagrid>
        </ScrollView>
      </View>
      <Pagination
        disableNext={!datasources.pagination.hasNextPage}
        disablePrev={!datasources.pagination.hasPrevPage}
        onNext={() => {
          datasources.nextPage();
        }}
        onPrev={() => {
          datasources.prevPage();
        }}
        testProps={{
          elementName: "datasourcesListPagination",
          screenName: SCREEN_NAME,
        }}
      />
    </>
  );
};
