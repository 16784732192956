/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';
import {
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped,
    OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from './OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom';

/**
 * Partial Policy used to update policies.
 * @export
 * @interface EditPolicyRequest
 */
export interface EditPolicyRequest {
    /**
     * The given name of this Policy.
     * @type {string}
     * @memberof EditPolicyRequest
     */
    name?: string | null;
    /**
     * A description of this Policy.
     * @type {string}
     * @memberof EditPolicyRequest
     */
    description?: string | null;
    /**
     * The message response when a Policy run returns false.
     * @type {string}
     * @memberof EditPolicyRequest
     */
    falseMessage?: string | null;
    /**
     * The message response when a Policy run returns true.
     * @type {string}
     * @memberof EditPolicyRequest
     */
    trueMessage?: string | null;
    /**
     * The state of this policy, whether it's live or paused.
     * @type {string}
     * @memberof EditPolicyRequest
     */
    state?: EditPolicyRequestStateEnum | null;
    /**
     * Permissions of the policy
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof EditPolicyRequest
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner> | null;
    /**
     * Rule for the policy
     * @type {Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom>}
     * @memberof EditPolicyRequest
     */
    rule?: Array<OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom> | null;
}


/**
 * @export
 */
export const EditPolicyRequestStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type EditPolicyRequestStateEnum = typeof EditPolicyRequestStateEnum[keyof typeof EditPolicyRequestStateEnum];


/**
 * Check if a given object implements the EditPolicyRequest interface.
 */
export function instanceOfEditPolicyRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditPolicyRequestFromJSON(json: any): EditPolicyRequest {
    return EditPolicyRequestFromJSONTyped(json, false);
}

export function EditPolicyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditPolicyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'falseMessage': !exists(json, 'falseMessage') ? undefined : json['falseMessage'],
        'trueMessage': !exists(json, 'trueMessage') ? undefined : json['trueMessage'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'rule': !exists(json, 'rule') ? undefined : (json['rule'] === null ? null : (json['rule'] as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON)),
    };
}

export function EditPolicyRequestToJSON(value?: EditPolicyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'state': value.state,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'rule': value.rule === undefined ? undefined : (value.rule === null ? null : (value.rule as Array<any>).map(OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON)),
    };
}

