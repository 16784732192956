// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../components";
import { EllipsisText } from "../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { VerticalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import { fieldHasCompletenessFailureById } from "../../utils/fieldHasCompletenessError";
import React from "react";
import type { ContainerWithTemplateType } from "./Approvals";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { OrgsGet200ResponseContainersInnerFieldsInner } from "../../gen";

const { Some } = Helpers;
const SCREEN_NAME = "ApprovalDetails";

type Props = {
  readonly container: ContainerWithTemplateType;
};

type FieldsWithError = OrgsGet200ResponseContainersInnerFieldsInner & {
  readonly hasError: boolean;
};

export const Fields = ({ container }: Props) => {
  const { theme } = useTheme();

  const fieldData =
    container.fields === undefined
      ? []
      : container.fields.map(field => ({
          ...field,
          hasError: fieldHasCompletenessFailureById(container, field.templateFieldID),
        }));

  const columns: readonly DatagridColumn<FieldsWithError>[] = [
    {
      key: "value",
      label: "Value",
      renderer: (field, testProps) =>
        field.hasError ? (
          <View
            style={{
              alignItems: "center",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Body
              color={theme.colors.text.alert.critical}
              testProps={
                Some(testProps)
                  ? { ...testProps, elementName: `${testProps.elementName}ErrorText` }
                  : testProps
              }
            >
              Requires a value
            </Body>
            <Icon
              name="warningMediumCritical"
              testProps={
                Some(testProps)
                  ? { ...testProps, elementName: `${testProps.elementName}ErrorIcon` }
                  : testProps
              }
            />
          </View>
        ) : (
          <EllipsisText testProps={testProps} text={field.value ?? "--"} />
        ),
      size: "flex",
    },
    {
      key: "name",
      label: "Field Name",
      renderer: (field, testProps) => (
        <EllipsisText testProps={testProps} text={field.name ?? "--"} />
      ),
      size: "flex",
    },
    {
      key: "description",
      label: "Field Description",
      renderer: ({ description }, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(description) && description.length > 0 ? description : "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "datatype",
      label: "Data Type",
      renderer: (field, testProps) => (
        <EllipsisText testProps={testProps} text={field.fieldKind?.fieldType ?? "--"} />
      ),
      size: 130,
    },
  ];

  return (
    <>
      <VerticalSpacer size={theme.spacing.xxl} />
      <Datagrid
        columns={columns}
        testProps={{
          elementName: "detailsViewFieldsTabFieldsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={fieldData}
          testProps={{
            elementName: "detailsViewFieldsTabFieldsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="templateFieldID"
        />
      </Datagrid>
    </>
  );
};
