// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { RuleList } from "./RuleList";
import { Spin } from "../../components/Spin";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { GetFieldKind200Response, GetTemplateRules200ResponseRules } from "../../gen";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly fieldKindData: {
    readonly inheritedValidationRules: GetFieldKind200Response["inheritedValidationRules"];
    readonly ownValidationRules?: GetFieldKind200Response["ownValidationRules"];
    readonly fieldType?: string;
    readonly isOwnField?: boolean;
  };
  readonly testProps: OPTestProps;
};

export const Rules = ({
  fieldKindData: { fieldType, inheritedValidationRules, isOwnField, ownValidationRules },
  testProps,
}: Props) => {
  const { theme } = useTheme();
  const [rules, setRules] = useState<GetTemplateRules200ResponseRules>();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    loadingContainer: {
      alignItems: "center",
      height: 100,
      justifyContent: "center",
    },
    noFields: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingVertical: 22,
    },
  });

  useEffect(() => {
    const getRules = async () => {
      try {
        setIsLoading(true);
        const response = await api.getTemplateRules({ orgID: selectedOrgId });
        setRules(response.rules);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    getRules();
  }, [api, deleteAlert, errorHandler, selectedOrgId, setAlert]);

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Spin />
      </View>
    );
  }

  return (
    <View style={{ paddingHorizontal: 32 }}>
      <>
        <Heading level="4">Inherited</Heading>
        <VerticalSpacer />
        {Some(inheritedValidationRules) &&
        inheritedValidationRules.length > 0 &&
        Some(rules) &&
        Some(fieldType) ? (
          inheritedValidationRules.map(inheritedRule => (
            <RuleList
              fieldType={fieldType}
              ruleConditions={inheritedRule.ruleConditions}
              rules={rules}
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}InheritedRules`,
              }}
            />
          ))
        ) : (
          <View style={styles.noFields}>
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}NoInheritedRulesPlaceholder`,
              }}
            >
              There are no inherited rules
            </Body>
          </View>
        )}
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="4">
          {Some(isOwnField) && isOwnField ? "Org created" : "Owning org created"}
        </Heading>
        <VerticalSpacer />
        {Some(ownValidationRules) &&
        Some(ownValidationRules.ruleConditions) &&
        Some(rules) &&
        Some(fieldType) ? (
          <RuleList
            fieldType={fieldType}
            ruleConditions={ownValidationRules.ruleConditions}
            rules={rules}
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}OrgCreatedRules`,
            }}
          />
        ) : (
          <View style={styles.noFields}>
            <Body
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}NoOrgCreatedRulesPlaceholder`,
              }}
            >
              {Some(isOwnField) && isOwnField
                ? "There are no org created rules"
                : "There are no owning org created rules"}
            </Body>
          </View>
        )}
      </>
    </View>
  );
};
