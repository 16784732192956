/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import type { GetPolicies200ResponsePoliciesInnerRules } from './GetPolicies200ResponsePoliciesInnerRules';
import {
    GetPolicies200ResponsePoliciesInnerRulesFromJSON,
    GetPolicies200ResponsePoliciesInnerRulesFromJSONTyped,
    GetPolicies200ResponsePoliciesInnerRulesToJSON,
} from './GetPolicies200ResponsePoliciesInnerRules';

/**
 * Representation of a named Policy belonging to an entity.
 * @export
 * @interface GetPolicies200ResponsePoliciesInner
 */
export interface GetPolicies200ResponsePoliciesInner {
    /**
     * The UUID of this object
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    id: string;
    /**
     * The given name of this Policy.
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    name: string;
    /**
     * A description of this Policy.
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    description?: string;
    /**
     * The message response when a Policy run returns false.
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    falseMessage: string;
    /**
     * The message response when a Policy run returns true.
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    trueMessage: string;
    /**
     * The id of the Policy this was extended from.
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    createdAt?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    shareablePermissions?: Array<string>;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * The state of this policy, whether it's live or paused.
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    state: GetPolicies200ResponsePoliciesInnerStateEnum;
    /**
     * 
     * @type {GetPolicies200ResponsePoliciesInnerRules}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    rules?: GetPolicies200ResponsePoliciesInnerRules | null;
    /**
     * 
     * @type {string}
     * @memberof GetPolicies200ResponsePoliciesInner
     */
    ownerID?: string;
}


/**
 * @export
 */
export const GetPolicies200ResponsePoliciesInnerStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type GetPolicies200ResponsePoliciesInnerStateEnum = typeof GetPolicies200ResponsePoliciesInnerStateEnum[keyof typeof GetPolicies200ResponsePoliciesInnerStateEnum];


/**
 * Check if a given object implements the GetPolicies200ResponsePoliciesInner interface.
 */
export function instanceOfGetPolicies200ResponsePoliciesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "falseMessage" in value;
    isInstance = isInstance && "trueMessage" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function GetPolicies200ResponsePoliciesInnerFromJSON(json: any): GetPolicies200ResponsePoliciesInner {
    return GetPolicies200ResponsePoliciesInnerFromJSONTyped(json, false);
}

export function GetPolicies200ResponsePoliciesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPolicies200ResponsePoliciesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'falseMessage': json['falseMessage'],
        'trueMessage': json['trueMessage'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'shareablePermissions': !exists(json, 'shareablePermissions') ? undefined : json['shareablePermissions'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'state': json['state'],
        'rules': !exists(json, 'rules') ? undefined : GetPolicies200ResponsePoliciesInnerRulesFromJSON(json['rules']),
        'ownerID': !exists(json, 'ownerID') ? undefined : json['ownerID'],
    };
}

export function GetPolicies200ResponsePoliciesInnerToJSON(value?: GetPolicies200ResponsePoliciesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'parent': value.parent,
        'createdBy': value.createdBy,
        'createdAt': value.createdAt,
        'shareablePermissions': value.shareablePermissions,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'state': value.state,
        'rules': GetPolicies200ResponsePoliciesInnerRulesToJSON(value.rules),
        'ownerID': value.ownerID,
    };
}

