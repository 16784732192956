// Copyright 2024 Merit International Inc. All Rights Reserved

import * as yup from "yup";
import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { ColumnsHeader } from "./ColumnsHeader";
import { ConfirmationModal } from "./../../components/Modals";
import { EllipsisText, Spin, Tooltip } from "../../components";
import { Form, Formik } from "formik";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { MappableField } from "./MappableField";
import { ScrollView, StyleSheet, View } from "react-native";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { FormikProps } from "formik";
import type {
  GetDatasource200Response,
  OrgsGet200ResponseContainersInner,
  GetDatasource200ResponseMappedTemplatesInner as Template,
} from "../../gen";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

const { None, Some } = Helpers;

export const SCREEN_NAME = "MapDataSource";

export type FieldToMap = {
  readonly base: boolean;
  readonly templateFieldID: string | undefined;
  readonly fieldName: string | undefined;
  readonly columnID?: string;
  readonly containerFieldID?: string;
  readonly containerID?: string;
  readonly mappedTo: "column" | "containerField";
};

type ConfirmationPopup = "save" | "unSave";

export type FormValues = {
  readonly additional: readonly FieldToMap[];
  readonly inherited: readonly FieldToMap[];
};

export const MapDataSource = () => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { selectedOrgId, selectedOrgName } = useLoggedInAuthState();
  const { deleteAlert, setAlert } = useAlertStore();
  const {
    params: { datasourceID, templateID },
  } = useRoute<RouteProp<RouteParams, "MapDataSource">>();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "MapDataSource">>();
  const { errorHandler } = useServerErrorHandler();
  const { configuration } = useLoadedConfigurationState();
  const { fieldMappingFolioLimit, folioFieldNames } = useAppConstantsStore();

  const [template, setTemplate] = useState<Template>();
  const [datasource, setDatasource] = useState<GetDatasource200Response["datasource"]>();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationPopupToShow, setConfirmationPopupToShow] = useState<ConfirmationPopup>();
  const [fieldsToMap, setFieldsToMap] = useState<readonly FieldToMap[]>([]);
  const [accountFolio, setAccountFolio] = useState<OrgsGet200ResponseContainersInner>();
  const [folioContainers, setFolioContainers] =
    useState<readonly OrgsGet200ResponseContainersInner[]>();

  const formRef = useRef<FormikProps<FormValues>>(null);
  const isFocused = useIsFocused();

  const styles = StyleSheet.create({
    content: {
      alignItems: "center",
      flex: 1,
    },
    fakeField: {
      backgroundColor: theme.colors.action.disabled,
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 40,
      justifyContent: "center",
      paddingHorizontal: theme.spacing.m,
      paddingVertical: 10,
      width: 208,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: theme.spacing.l,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    listItem: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xl,
    },
    noFields: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: 32,
      paddingVertical: 22,
    },
  });

  const columnsSchema = yup.array().of(
    yup.object().shape({
      columnID: yup
        .string()
        .trim()
        .when("mappedTo", {
          is: (value: string) => value === "column",
          then: schema => schema.required("Please select column name"),
        }),
      containerFieldID: yup.string().when("containerID", {
        is: (value: string) => Some(value),
        then: schema => schema.required("Please select field"),
      }),
      containerID: yup
        .string()
        .trim()
        .when("mappedTo", {
          is: (value: string) => value === "containerField",
          then: schema => schema.required("Please select folio"),
        }),
      mappedTo: yup.string().required("Please select column"),
    })
  );

  const validationSchema = yup.object({
    additional: columnsSchema,
    inherited: columnsSchema,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const datasourceResponse = await api.getDatasource({
          datasourceID,
          orgID: selectedOrgId,
        });
        setDatasource(datasourceResponse.datasource);

        const templateResponse = await api.getTemplate({
          orgID: selectedOrgId,
          templateID,
        });
        setTemplate(templateResponse);

        const accountFolioResponse = await api.getContainers({
          orgID: selectedOrgId,
          recipientID: selectedOrgId,
          templateID: configuration.accountFolioTemplateUUID,
          templateType: "Folio",
        });

        if (Some(accountFolioResponse.containers) && accountFolioResponse.containers.length > 0) {
          setAccountFolio(accountFolioResponse.containers[0]);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isFocused) {
      fetchData();
    }
  }, [
    api,
    configuration.accountFolioTemplateUUID,
    datasourceID,
    errorHandler,
    isFocused,
    selectedOrgId,
    templateID,
  ]);

  useEffect(() => {
    const fetchFolioContainers = async () => {
      try {
        const folioContainersResponse = await api.getContainers({
          limit: fieldMappingFolioLimit,
          orgID: selectedOrgId,
          recipientID: selectedOrgId,
          state: "accepted",
          templateType: "Folio",
        });

        setFolioContainers(folioContainersResponse.containers);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchFolioContainers();
  }, [api, errorHandler, fieldMappingFolioLimit, selectedOrgId]);

  const getFieldsToMap = useCallback(() => {
    if (Some(template) && Some(template.templateFields)) {
      const initialFields = template.templateFields.map(field => ({
        base: Some(field.lineage) && field.lineage.length > 0,
        columnID: field.mappedColumn?.id,
        containerFieldID: field.mappedContainerField?.containerFieldId,
        containerID: field.mappedContainerField?.containerId,
        fieldName: field.name,
        mappedTo: Some(field.mappedContainerField)
          ? ("containerField" as const)
          : ("column" as const),
        templateFieldID: field.fieldID ?? "",
      }));
      setFieldsToMap(initialFields);
    }
  }, [template]);

  useEffect(() => {
    getFieldsToMap();
  }, [datasource?.columns, getFieldsToMap, template]);

  const getAccountFolioField = (fieldName: string) => {
    if (None(accountFolio)) {
      throw new Error("Attempted to update mapping with no account folio");
    }

    return accountFolio.fields?.find(f => f.name === fieldName);
  };

  const getIssuingOrgMappingColumn = () => {
    // HACK: add additional mapping for "Issuing Org Name" field on first mapping
    // this will be fixed on the platform at some point to happen automatically
    const issuingOrgNameField = fieldsToMap.find(
      f => f.fieldName === folioFieldNames.issuingOrgName
    );
    if (None(issuingOrgNameField) || None(issuingOrgNameField.templateFieldID)) {
      throw new Error("No issuing org name field on template");
    }

    if (None(accountFolio)) {
      throw new Error("Attempted to update mapping with no account folio");
    }

    const accountFolioField = getAccountFolioField(folioFieldNames.organizationName);

    if (None(accountFolioField)) {
      throw new Error("Could not find field on account folio");
    }

    return {
      containerFieldID: accountFolioField.templateFieldID,
      containerID: accountFolio.id,
      templateFieldID: issuingOrgNameField.templateFieldID,
    };
  };

  const saveTemplateMap = async () => {
    if (None(datasource) || None(templateID)) {
      throw new Error("Somehow data source or template not found");
    }

    if (None(formRef.current)) {
      throw new Error("Somehow form is not found");
    }

    const { additional, inherited } = formRef.current.values;

    const inheritedMappingColumns = inherited.filter(field => {
      if (field.fieldName === folioFieldNames.issuingOrgName && field.base) {
        return undefined;
      }

      return field;
    });

    const mappingColumns = [...additional, ...inheritedMappingColumns].map(field => ({
      columnID: field.columnID === "" ? undefined : field.columnID,
      containerFieldID: field.containerFieldID === "" ? undefined : field.containerFieldID,
      containerID: field.containerID === "" ? undefined : field.containerID,
      templateFieldID: field.templateFieldID ?? "",
    }));

    const updatedMappings = [...mappingColumns, getIssuingOrgMappingColumn()];

    try {
      setIsLoading(true);
      if (Some(template) && Some(template.dataSourceID) && template.dataSourceID !== "") {
        await api.editTemplateMap({
          datasourceID: template.dataSourceID,
          orgID: selectedOrgId,
          properties: {
            columnToTemplateField: updatedMappings,
          },
          templateID,
        });
      } else {
        await api.mapTemplate({
          datasourceID: datasource.id,
          orgID: selectedOrgId,
          templateID,
          templateMappingRequest: {
            templateFieldMap: updatedMappings,
          },
        });
      }
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "Data source has been mapped.",
        type: "success",
      });
      navigation.navigate("Templates", { initialTemplateId: templateID });
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataSourceColumns = Some(datasource)
    ? datasource.columns.map(colmun => ({
        label: colmun.name,
        value: colmun.id,
      }))
    : [];

  const folioTemplateOptions =
    folioContainers?.map(folio => ({
      label: Some(folio.name) ? `${folio.name} UUID:${folio.id}` : "",
      value: folio.id,
    })) ?? [];

  const closeConfirmationModal = () => {
    setConfirmationPopupToShow(undefined);
  };

  const closeScreen = () => {
    setFieldsToMap([]);
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Templates", {});
  };

  const onPressSave = async () => {
    if (None(formRef.current)) {
      return;
    }
    await formRef.current.submitForm();
  };

  const baseFields = fieldsToMap
    .filter(_ => _.base)
    .map(field => {
      if (field.fieldName === folioFieldNames.issuingOrgName) {
        return {
          ...field,
          // Hack: Using fake uuid to ignore validation for issuing org name
          containerFieldID: uuidv4(),
          containerID: accountFolio?.id,
          mappedTo: "containerField" as const,
        };
      }

      return field;
    });
  const additionalFields = fieldsToMap.filter(_ => !_.base);

  return (
    <FullScreenModalLayout
      onClose={() => {
        if (Some(formRef.current) && formRef.current.dirty) {
          setConfirmationPopupToShow("unSave");

          return;
        }
        closeScreen();
      }}
      testProps={{ elementName: "configureDataSource", screenName: SCREEN_NAME }}
      title={`Configure ${Some(template) && Some(template.name) ? template.name : "template"}`}
    >
      <ScrollView>
        <View style={styles.content}>
          {isLoading ? (
            <View style={{ justifyContent: "center", minHeight: 200 }}>
              <Spin />
            </View>
          ) : (
            <View style={{ minWidth: 960 }}>
              <VerticalSpacer size={theme.spacing.xxl} />
              <View style={styles.header}>
                <View style={{ flex: 3 }}>
                  <Heading
                    bold
                    level="3"
                    testProps={{ elementName: "mapDataSourceName", screenName: SCREEN_NAME }}
                  >
                    {datasource?.name}
                  </Heading>
                  <VerticalSpacer size={theme.spacing.s} />
                  <Body testProps={{ elementName: "mapDataSourceId", screenName: SCREEN_NAME }}>
                    Data source ID: {datasource?.id}
                  </Body>
                  <VerticalSpacer size={theme.spacing.s} />
                  <Body>Description:</Body>
                  <Body
                    testProps={{ elementName: "mapDataSourceDescription", screenName: SCREEN_NAME }}
                  >
                    {datasource?.description ?? "--"}
                  </Body>
                </View>
                <View style={{ flex: 1 }} />
              </View>

              <VerticalSpacer size={theme.spacing.xxl} />

              <Formik
                initialValues={{
                  additional: additionalFields,
                  inherited: baseFields,
                }}
                innerRef={formRef}
                onSubmit={() => {
                  setConfirmationPopupToShow("save");
                }}
                validationSchema={validationSchema}
              >
                {({ getFieldMeta, setFieldValue, values }) => (
                  <Form>
                    <>
                      <Heading
                        level="4"
                        testProps={{
                          elementName: "mapDataSourceInheritedFieldsText",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Inherited fields *
                      </Heading>
                      <VerticalSpacer size={theme.spacing.xxl} />
                      <ColumnsHeader
                        testProps={{
                          elementName: "mapDataSourceInheritedFieldsRowItem",
                          screenName: SCREEN_NAME,
                        }}
                      />
                      <>
                        {values.inherited.length > 0 ? (
                          <View style={{ backgroundColor: theme.colors.background.white }}>
                            {values.inherited.map((field, index) => (
                              <View key={field.fieldName} style={styles.listItem}>
                                <View style={{ justifyContent: "center", width: 244, zIndex: 2 }}>
                                  {field.fieldName === folioFieldNames.issuingOrgName ? (
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Body numberOfLines={1}>{field.fieldName}</Body>
                                      <View style={{ flexDirection: "row" }}>
                                        <Tooltip
                                          testProps={{
                                            elementId: field.templateFieldID,
                                            elementName: `mapDataSourceInheritedFieldsRowItem${field.fieldName}Tooltip`,
                                            screenName: SCREEN_NAME,
                                          }}
                                          text="The 'Mapped field' must be mapped to the 'Account' folio from the issuing org."
                                        />
                                        <HorizontalSpacer size={theme.spacing.xxl} />
                                      </View>
                                    </View>
                                  ) : (
                                    <Body
                                      testProps={{
                                        elementId: field.templateFieldID,
                                        elementName: `mapDataSourceInheritedFieldsRowItem${
                                          field.fieldName ?? ""
                                        }`,
                                        screenName: SCREEN_NAME,
                                      }}
                                    >
                                      {field.fieldName}
                                    </Body>
                                  )}
                                </View>
                                <HorizontalSpacer />
                                <>
                                  {field.fieldName === folioFieldNames.issuingOrgName ? (
                                    <View style={{ alignItems: "flex-end", flexDirection: "row" }}>
                                      <View style={styles.fakeField}>
                                        <EllipsisText
                                          testProps={{
                                            elementId: field.templateFieldID,
                                            elementName: `mapDataSourceInheritedFieldsRowItemMappedTo`,
                                            screenName: SCREEN_NAME,
                                          }}
                                          text={selectedOrgName}
                                        />
                                      </View>
                                      <HorizontalSpacer size={48} />
                                      <View style={styles.fakeField}>
                                        <EllipsisText
                                          testProps={{
                                            elementId: field.templateFieldID,
                                            elementName: `mapDataSourceInheritedFieldsRowItemSource`,
                                            screenName: SCREEN_NAME,
                                          }}
                                          text={accountFolio?.name ?? "--"}
                                        />
                                      </View>
                                      <HorizontalSpacer />
                                      <View style={styles.fakeField}>
                                        <EllipsisText
                                          testProps={{
                                            elementId: field.templateFieldID,
                                            elementName: `mapDataSourceInheritedFieldsRowItemField`,
                                            screenName: SCREEN_NAME,
                                          }}
                                          text={
                                            getAccountFolioField(folioFieldNames.organizationName)
                                              ?.name ?? "--"
                                          }
                                        />
                                      </View>
                                    </View>
                                  ) : (
                                    <MappableField
                                      dataSourceColumns={dataSourceColumns}
                                      field={field}
                                      fieldDataType={
                                        template?.templateFields?.find(
                                          tmplField => tmplField.fieldID === field.templateFieldID
                                        )?.type
                                      }
                                      fieldType="inherited"
                                      folioContainers={folioContainers}
                                      folioTemplateOptions={folioTemplateOptions}
                                      formProps={{ getFieldMeta, setFieldValue }}
                                      rowIndex={index}
                                      testProps={{
                                        elementId: field.templateFieldID,
                                        elementName: `mapDataSourceInheritedFieldsRowItem`,
                                        screenName: SCREEN_NAME,
                                      }}
                                    />
                                  )}
                                </>
                              </View>
                            ))}
                          </View>
                        ) : (
                          <View style={styles.noFields}>
                            <Body
                              testProps={{
                                elementName: `mapDataSourceInheritedFieldsNoDataPlaceholder`,
                                screenName: SCREEN_NAME,
                              }}
                            >
                              There are no inherited fields for this template
                            </Body>
                          </View>
                        )}
                      </>
                    </>

                    <VerticalSpacer size={theme.spacing.xxl} />
                    <>
                      <Heading
                        level="4"
                        testProps={{
                          elementName: `mapDataSourceAdditionalFieldsText`,
                          screenName: SCREEN_NAME,
                        }}
                      >
                        Additional fields
                      </Heading>
                      <VerticalSpacer size={theme.spacing.xxl} />
                      <ColumnsHeader
                        testProps={{
                          elementName: "mapDataSourceAdditionalFieldsRowItem",
                          screenName: SCREEN_NAME,
                        }}
                      />
                      <>
                        {values.additional.length > 0 ? (
                          <View style={{ backgroundColor: theme.colors.background.white }}>
                            {values.additional.map((field, index) => (
                              <View key={field.fieldName} style={styles.listItem}>
                                <View style={{ justifyContent: "center", width: 244 }}>
                                  <Body
                                    testProps={{
                                      elementId: field.templateFieldID,
                                      elementName: `mapDataSourceAdditionalFieldsRowItemFieldName`,
                                      screenName: SCREEN_NAME,
                                    }}
                                  >
                                    {field.fieldName}
                                  </Body>
                                </View>
                                <HorizontalSpacer />
                                <MappableField
                                  dataSourceColumns={dataSourceColumns}
                                  field={field}
                                  fieldDataType={
                                    template?.templateFields?.find(
                                      tmplField => tmplField.fieldID === field.templateFieldID
                                    )?.type
                                  }
                                  fieldType="additional"
                                  folioContainers={folioContainers}
                                  folioTemplateOptions={folioTemplateOptions}
                                  formProps={{ getFieldMeta, setFieldValue }}
                                  rowIndex={index}
                                  testProps={{
                                    elementId: field.templateFieldID,
                                    elementName: `mapDataSourceAdditionalFieldsRowItem`,
                                    screenName: SCREEN_NAME,
                                  }}
                                />
                              </View>
                            ))}
                          </View>
                        ) : (
                          <View style={styles.noFields}>
                            <Body
                              testProps={{
                                elementName: `mapDataSourceAdditionalFieldsNoDataPlaceholder`,
                                screenName: SCREEN_NAME,
                              }}
                            >
                              There are no additional fields for this template
                            </Body>
                          </View>
                        )}
                      </>
                    </>
                    <VerticalSpacer size={theme.spacing.xxl} />
                  </Form>
                )}
              </Formik>
            </View>
          )}
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <View style={{ width: 92 }}>
          <Button
            onPress={() => {
              if (Some(formRef.current) && formRef.current.dirty) {
                setConfirmationPopupToShow("unSave");

                return;
              }
              closeScreen();
            }}
            testProps={{
              elementName: `mapDataSourceBackButton`,
              screenName: SCREEN_NAME,
            }}
            text="Back"
            type="secondary"
          />
        </View>
        <HorizontalSpacer />
        <View style={{ width: 79 }}>
          <Button
            onPress={onPressSave}
            testProps={{
              elementName: `mapDataSourceSaveButton`,
              screenName: SCREEN_NAME,
            }}
            text="Save"
          />
        </View>
      </View>
      {confirmationPopupToShow === "save" && (
        <ConfirmationModal
          onClose={closeConfirmationModal}
          onOk={() => {
            saveTemplateMap();
            closeConfirmationModal();
          }}
          testProps={{
            elementName: `mapDataSourceSave`,
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to save? Saving this change will impact any org that has extended this template from you."
          title="Save configuration"
        />
      )}
      {confirmationPopupToShow === "unSave" && (
        <ConfirmationModal
          onClose={closeConfirmationModal}
          onOk={() => {
            closeScreen();
            closeConfirmationModal();
          }}
          testProps={{
            elementName: `mapDataSourceUnSave`,
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </FullScreenModalLayout>
  );
};
