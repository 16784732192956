import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText, Spin, VerticalSpacer } from "@src/components";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "@src/screens/Templates/Templates";
import { ScrollView, View } from "react-native";
import { TemplatesFilter } from "@src/screens/Templates/TemplatesFilter";
import { useApi } from "@src/api/api";
import { useEffect } from "react";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useTemplateDataSearch } from "./useTemplateDataSearch";
import lowerCase from "lodash.lowercase";
import type { DatagridColumn } from "@src/components/Datagrid/types";
import type { ReactNode } from "react";
import type { Search200ResponseTemplatesInner } from "@src/gen";

type Props = {
  readonly setTemplateID: (id: string) => void;
  readonly routeParams?: string | undefined;
  readonly searchQuery: string | undefined;
  readonly refresh: boolean;
  readonly setRefresh: (value: boolean) => void;
  readonly setResultsCount: (count: number | undefined) => void;
};

export const TemplatesDatagridSearch = ({
  refresh,
  routeParams,
  searchQuery,
  setRefresh,
  setResultsCount,
  setTemplateID,
}: Props) => {
  const { Some } = Helpers;
  const { theme } = useTheme();
  const auth = useLoggedInAuthState();
  const { api } = useApi();
  const { configuration } = useLoadedConfigurationState();

  const templateList = useTemplateDataSearch(
    api,
    auth.selectedOrgId,
    routeParams === "shared" ? configuration.solUUID : undefined
  );

  useEffect(() => {
    if (Some(searchQuery)) {
      templateList.filterData({ searchQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (refresh) {
      templateList.refresh();
      setRefresh(false);
    }
  }, [refresh, setRefresh, templateList]);

  useEffect(() => {
    if (templateList.data.length > 0) {
      setResultsCount(templateList.data.length);
    } else {
      setResultsCount(undefined);
    }
  }, [templateList, setResultsCount]);

  const columns: readonly DatagridColumn<Search200ResponseTemplatesInner>[] = [
    { key: "name", label: "Name", size: "flex" },
    { key: "type", label: "Type", size: 128 },
    { key: "id", label: "Template ID", size: 360 },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }) => (
        <EllipsisText text={Some(description) && description.length > 0 ? description : "--"} />
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "Status",
      renderer: item => (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <View
            style={{
              backgroundColor:
                lowerCase(item.status) === "live"
                  ? theme.colors.icon.alert.success
                  : theme.colors.icon.disabled,
              borderRadius: theme.borderRadii.m,
              height: 8,
              marginRight: theme.spacing.m,
              width: 8,
            }}
          />
          <Body capitalize>{item.status as ReactNode}</Body>
        </View>
      ),
      size: 128,
    },
    { key: "approval", label: "Approval", size: 128 },
    {
      key: "actions",
      label: "Actions",
      renderer: item => (
        <Button
          onPress={() => {
            setTemplateID(item.id);
          }}
          size="small"
          testProps={{
            elementId: item.id,
            elementName: "templatesListViewRowItemViewButton",
            screenName: SCREEN_NAME,
          }}
          text="View"
          type="secondary"
        />
      ),

      size: 128,
    },
  ];

  return (
    <>
      <TemplatesFilter
        onSelect={values => {
          templateList.filterData({ ...values, searchQuery });
        }}
        showStatus={false}
        typePlaceholder="All"
      />
      <VerticalSpacer size={theme.spacing.xxl} />
      {templateList.loading ? (
        <View style={{ flex: 1 }}>
          <Spin />
        </View>
      ) : (
        <ScrollView>
          <View style={{ paddingLeft: 32 }}>
            <Heading level="4">{`Results (${templateList.data.length})`}</Heading>
          </View>
          <Datagrid
            columns={columns}
            loading={templateList.loading}
            testProps={{ elementName: "ownTemplatesListView", screenName: SCREEN_NAME }}
          >
            <DatagridBody
              columns={columns}
              data={templateList.data}
              testProps={{
                elementName: "ownTemplatesListView",
                screenName: SCREEN_NAME,
              }}
              testPropsElementIdKey="id"
            />
          </Datagrid>
        </ScrollView>
      )}
    </>
  );
};
