// Copyright 2023 Merit International Inc. All Rights Reserved

import { Configuration, DefaultConfig } from "../gen";
import { DefaultApi } from "../gen/apis/DefaultApi";
import { useAuthStore } from "../stores/authStore";
import { useMemo } from "react";
import Constants from "expo-constants";
import type { ConfigurationParameters, ResponseContext } from "../gen";
import type { Configuration as EnvConfig } from "@src/configuration";

const envConfig = Constants.manifest?.extra as EnvConfig;

const BASE_API_CONFIG = {
  basePath: envConfig.api.orgPortal.baseUrl,
  headers: {},
};

export const useApi = () => {
  const { accessToken, clear: clearAuthStore, session, setSession } = useAuthStore();

  return useMemo(() => {
    const checkForUnauthorized = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>((resolve, reject) => {
        if (context.response.status === 401) {
          clearAuthStore();
          reject(Error("401 Unauthorized"));
        } else {
          resolve(context.response);
        }
      });

      return p;
    };

    // Disabled temporarily
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const useFreshToken = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>(resolve => {
        if (context.response.headers.has("X-Session-Store")) {
          const newSession = context.response.headers.get("X-Session-Store");
          setSession(newSession);
        }

        resolve(context.response);
      });

      return p;
    };

    const notAuthorized = accessToken === null || session === null;

    const config: ConfigurationParameters = {
      ...DefaultConfig,
      basePath: BASE_API_CONFIG.basePath,
      headers:
        accessToken === null || session === null
          ? BASE_API_CONFIG.headers
          : {
              ...BASE_API_CONFIG.headers,
              Authorization: `Bearer ${accessToken}`,
              "X-Session-Store": session,
            },
      middleware: [notAuthorized ? {} : { post: checkForUnauthorized }],
    };

    const api = new DefaultApi(new Configuration(config));

    return { api, config };
  }, [clearAuthStore, setSession, accessToken, session]);
};
