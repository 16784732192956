// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText, HorizontalSpacer } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { View } from "react-native";
import { getDateTimeString } from "../../../utils/time";
import { useGetContainerValueByBaseFieldName } from "../../../utils/getContainerFieldValue";
import type { OrgsGet200ResponseContainersInner as Container } from "../../../gen";
import type { DatagridColumn } from "../../../components/Datagrid/types";

const { Some } = Helpers;
const SCREEN_NAME = "ConnectedOrganizations";

type Props = {
  readonly containers?: readonly Container[];
  readonly onView: (container: Container) => void;
  readonly onAccept: (folioId: string, folioTemplateID?: string) => Promise<void>;
  readonly onReject: (folioId?: string) => void;
};

export const ConnectedOrganizationsTable = ({ containers, onAccept, onReject, onView }: Props) => {
  const { getContainerValueByBaseFieldName } = useGetContainerValueByBaseFieldName();

  const columns: readonly DatagridColumn<Container>[] = [
    {
      key: "issueOrgName",
      label: "Organization",
      renderer: (container, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "issuingOrgName",
            templateType: "folio",
          })}
        />
      ),
      size: "flex",
    },
    {
      key: "name",
      label: "Connection",
      size: "flex",
    },
    {
      key: "authorizedAt",
      label: "Date",
      renderer: ({ authorizedAt }, testProps) =>
        Some(authorizedAt) ? (
          <Body testProps={testProps}>{getDateTimeString(authorizedAt.toString())}</Body>
        ) : (
          "--"
        ),
      size: 200,
    },
    {
      key: "active",
      label: "Status",
      renderer: ({ state }, testProps) => (
        <Body capitalize testProps={testProps}>
          {state?.name ?? "--"}
        </Body>
      ),
      size: 160,
    },
    {
      key: "actions",
      label: "Actions",
      renderer: (container, testProps) => (
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 80 }}>
            <Button
              onPress={() => {
                onView(container);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}ViewButton`,
                    }
                  : testProps
              }
              text="View"
              type="secondary"
            />
          </View>

          <HorizontalSpacer />
          <View style={{ width: 32 }}>
            <Button
              iconRight="closeSmallDefault"
              onPress={() => {
                onReject(container.id);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}RejectButton`,
                    }
                  : testProps
              }
              type="destructive"
            />
          </View>
          {container.state?.name === "pending" && (
            <>
              <HorizontalSpacer />
              <View style={{ width: 120 }}>
                <Button
                  iconLeft="checkmarkSuccess"
                  onPress={() => onAccept(container.id, container.templateId)}
                  size="small"
                  testProps={
                    Some(testProps)
                      ? {
                          ...testProps,
                          elementName: `${testProps.elementName}AcceptButton`,
                        }
                      : testProps
                  }
                  text="Accept"
                />
              </View>
            </>
          )}
        </View>
      ),
      size: "flex",
    },
  ];

  return (
    <Datagrid
      columns={columns}
      testProps={{
        elementName: "connectedOrganizationsListView",
        screenName: SCREEN_NAME,
      }}
    >
      <DatagridBody
        columns={columns}
        data={containers ?? []}
        testProps={{
          elementName: "connectedOrganizationsListView",
          screenName: SCREEN_NAME,
        }}
        testPropsElementIdKey="id"
      />
    </Datagrid>
  );
};
