// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Select } from "@merit/frontend-components";
import { Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { SCREEN_NAME } from "./Records";
import { StyleSheet, View } from "react-native";
import React from "react";
import type { GetContainersStateEnum, GetContainersTemplateTypeEnum } from "../../gen";

export type FormValues = {
  readonly templateType?: GetContainersTemplateTypeEnum;
  readonly state?: GetContainersStateEnum;
};

const { None } = Helpers;

const TEMPLATE_TYPE_OPTIONS = [
  { label: "Merit", value: "Merit" },
  { label: "Folio", value: "Folio" },
];

const CONTAINER_STATUS_OPTIONS = [
  { label: "Unissued", value: "unissued" },
  { label: "Pending", value: "pending" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" },
  { label: "Revoked", value: "revoked" },
];

type Props = {
  readonly onSubmit: (values?: FormValues) => void;
};

export const Filters = ({ onSubmit }: Props) => {
  const styles = StyleSheet.create({
    fieldItem: {
      width: 160,
    },
    horizontalFields: {
      alignItems: "flex-end",
      flexDirection: "row",
      paddingHorizontal: 32,
    },
  });

  return (
    <Formik
      initialValues={{
        status: undefined,
        templateType: undefined,
      }}
      onSubmit={(formValues: FormValues) => {
        onSubmit(formValues);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <View style={styles.horizontalFields}>
          <View style={styles.fieldItem}>
            <Select
              label="Template type"
              onSelectOption={option => {
                setFieldValue("templateType", option.value);
              }}
              options={TEMPLATE_TYPE_OPTIONS}
              placeholder={{ label: "Template type", value: "" }}
              size="small"
              testProps={{
                elementName: "recordsListViewFiltersTemplateType",
                screenName: SCREEN_NAME,
              }}
              usePortal
            />
          </View>

          <HorizontalSpacer />
          <View style={{ width: 168 }}>
            <Select
              label="Container state"
              onSelectOption={option => {
                setFieldValue("state", option.value);
              }}
              options={CONTAINER_STATUS_OPTIONS}
              placeholder={{ label: "Container state", value: "" }}
              size="small"
              testProps={{
                elementName: "recordsListViewFiltersContainerState",
                screenName: SCREEN_NAME,
              }}
              usePortal
            />
          </View>
          <HorizontalSpacer />
          <Button
            disabled={None(values.templateType)}
            onPress={handleSubmit}
            size="small"
            testProps={{
              elementName: "recordsListViewFiltersSearchButton",
              screenName: SCREEN_NAME,
            }}
            text="Search"
            type="primary"
          />
        </View>
      )}
    </Formik>
  );
};
