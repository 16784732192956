/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EditTemplateRequestRules } from './EditTemplateRequestRules';
import {
    EditTemplateRequestRulesFromJSON,
    EditTemplateRequestRulesFromJSONTyped,
    EditTemplateRequestRulesToJSON,
} from './EditTemplateRequestRules';
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner } from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';
import {
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSONTyped,
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
} from './GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner';

/**
 * 
 * @export
 * @interface EditTemplateRequest
 */
export interface EditTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof EditTemplateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditTemplateRequest
     */
    description?: string;
    /**
     * New state for this template [paused, live]
     * @type {string}
     * @memberof EditTemplateRequest
     */
    state?: string;
    /**
     * 
     * @type {EditTemplateRequestRules}
     * @memberof EditTemplateRequest
     */
    rules?: EditTemplateRequestRules;
    /**
     * 
     * @type {Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>}
     * @memberof EditTemplateRequest
     */
    permissions?: Array<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof EditTemplateRequest
     */
    autoApprove?: boolean | null;
    /**
     * Whether or not containers are automatically revoked when recipient email is changed.
     * @type {boolean}
     * @memberof EditTemplateRequest
     */
    autoRevoke?: boolean | null;
}

/**
 * Check if a given object implements the EditTemplateRequest interface.
 */
export function instanceOfEditTemplateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditTemplateRequestFromJSON(json: any): EditTemplateRequest {
    return EditTemplateRequestFromJSONTyped(json, false);
}

export function EditTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'rules': !exists(json, 'rules') ? undefined : EditTemplateRequestRulesFromJSON(json['rules']),
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON)),
        'autoApprove': !exists(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRevoke': !exists(json, 'autoRevoke') ? undefined : json['autoRevoke'],
    };
}

export function EditTemplateRequestToJSON(value?: EditTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'state': value.state,
        'rules': EditTemplateRequestRulesToJSON(value.rules),
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON)),
        'autoApprove': value.autoApprove,
        'autoRevoke': value.autoRevoke,
    };
}

