// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../../components/Modals";
import { ConnectedOrganizationsDetails } from "../../ConnectedOrganizationDetails/ConnectedOrganizationDetails";
import { ConnectedOrganizationsTable } from "./ConnectedOrganizationsTable";
import { Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import { Spin } from "../../../components";
import { useAcceptedFolioStore, useAlertStore, useAppConstantsStore } from "../../../stores";
import { useApi } from "../../../api/api";
import { useCallback, useEffect, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type {
  OrgsGet200ResponseContainersInner as Container,
  GetContainersRequest,
} from "../../../gen";

const SCREEN_NAME = "connectedOrganizations";
const { Some } = Helpers;

type Props = {
  readonly searchQuery?: string | undefined;
};

export const ConnectedOrganizations = ({ searchQuery }: Props) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const appConstants = useAppConstantsStore();
  const { deleteAlert, setAlert } = useAlertStore();
  const { setUserAcceptedAccountFolio } = useAcceptedFolioStore();
  const appConfig = useLoadedConfigurationState();
  const { errorHandler } = useServerErrorHandler();
  const { theme } = useTheme();

  const [folios, setFolios] = useState<readonly Container[]>([]);
  const [foliosSearchResults, setFoliosSearchResults] = useState<readonly Container[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolio, setSelectedFolio] = useState<Container>();
  const [selectedFolioId, setSelectedFolioId] = useState<string>();
  const [baseTemplateFields, setBaseTemplateFields] = useState<{
    readonly issuingOrgNameField: string;
    readonly organizationNameField: string;
  }>();

  const getFolios = useCallback(async () => {
    setIsLoading(true);
    const params: GetContainersRequest = {
      limit: appConstants.folioInboxLimit,
      orgID: selectedOrgId,
      recipientID: selectedOrgId,
      sortBy: "createdAt",
      templateType: "Folio",
    };
    try {
      const acceptedFoliosResponse = await api.getContainers({ ...params, state: "accepted" });
      const pendingFoliosResponse = await api.getContainers({ ...params, state: "pending" });
      const acceptedFolios = acceptedFoliosResponse.containers ?? [];
      const pendingFolios = pendingFoliosResponse.containers ?? [];
      setFoliosSearchResults(undefined);
      setFolios([...pendingFolios, ...acceptedFolios]);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, appConstants.folioInboxLimit, errorHandler, selectedOrgId]);

  const getFoliosTemplates = useCallback(async () => {
    const response = await api.listTemplates({ orgID: selectedOrgId, type: "Folio" });
    if (response.templates.length > 0) {
      const folioFields = response.templates[0].templateFields?.reduce(
        (prev, current) => {
          if (current.name === "Issuing Org Name" && Some(current.lineage)) {
            return {
              ...prev,
              issuingOrgNameField: current.lineage[0],
            };
          }

          if (current.name === "Organization Name" && Some(current.lineage)) {
            return {
              ...prev,
              organizationNameField: current.lineage[0],
            };
          }

          return prev;
        },
        { issuingOrgNameField: "", organizationNameField: "" }
      );
      setBaseTemplateFields(folioFields);
    }
  }, [api, selectedOrgId]);

  useEffect(() => {
    getFoliosTemplates();
  }, [getFoliosTemplates]);

  useFocusEffect(
    useCallback(() => {
      const fetchFoliosBySearchQuery = async () => {
        try {
          setIsLoading(true);
          const { containers, hasMore } = await api.search({
            orgID: selectedOrgId,
            query: {
              containerFields: [
                {
                  baseTemplateFieldID: baseTemplateFields?.organizationNameField,
                  query: searchQuery,
                },
              ],
            },
          });
          if (Some(hasMore) && hasMore) {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              text: `Your search returned more than 500 results. Please refine you search`,
              type: "warning",
            });
          }
          setFoliosSearchResults(containers ?? []);
        } catch (error) {
          errorHandler(error);
        } finally {
          setIsLoading(false);
        }
      };

      if (Some(searchQuery)) {
        fetchFoliosBySearchQuery();
      } else {
        getFolios();
      }
    }, [
      api,
      baseTemplateFields,
      deleteAlert,
      errorHandler,
      getFolios,
      searchQuery,
      selectedOrgId,
      setAlert,
    ])
  );

  const acceptFolio = useCallback(
    async (folioId: string, folioTemplateId: string | undefined) => {
      if (Some(selectedOrgId)) {
        try {
          setIsLoading(true);
          await api.acceptContainer({
            containerID: folioId,
            orgID: selectedOrgId,
          });
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            testProps: {
              elementName: "acceptFolioSuccess",
              screenName: SCREEN_NAME,
            },
            text: "A folio has been accepted.",
            type: "success",
          });
          if (
            folioTemplateId !== undefined &&
            folioTemplateId === appConfig.configuration.accountFolioTemplateUUID
          ) {
            setUserAcceptedAccountFolio(true);
          }
          setSelectedFolio(undefined);
          await getFolios();
        } catch (error) {
          const testProps = {
            elementName: "acceptFolioError",
            screenName: SCREEN_NAME,
          };
          errorHandler(error, testProps);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      api,
      appConfig.configuration.accountFolioTemplateUUID,
      deleteAlert,
      errorHandler,
      getFolios,
      selectedOrgId,
      setAlert,
      setUserAcceptedAccountFolio,
    ]
  );

  const rejectFolio = useCallback(
    async (folioId: string | undefined) => {
      if (Some(folioId)) {
        try {
          setIsLoading(true);
          await api.rejectContainer({
            containerID: folioId.toString(),
            orgID: selectedOrgId,
          });
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            testProps: {
              elementName: "rejectFolioSuccess",
              screenName: SCREEN_NAME,
            },
            text: "A folio has been rejected.",
            type: "success",
          });
          setSelectedFolio(undefined);
          await getFolios();
        } catch (error) {
          const testProps = {
            elementName: "rejectFolioError",
            screenName: SCREEN_NAME,
          };
          errorHandler(error, testProps);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [api, deleteAlert, errorHandler, getFolios, selectedOrgId, setAlert]
  );

  const showConfirmationModal = () => (
    <ConfirmationModal
      buttonText="reject"
      onClose={() => {
        setSelectedFolioId(undefined);
      }}
      onOk={() => {
        rejectFolio(selectedFolioId);
        setSelectedFolioId(undefined);
      }}
      testProps={{
        elementName: "folioRejectConfirmationModal",
        screenName: SCREEN_NAME,
      }}
      text="Are you sure you want to reject this folio as an admin? You cannot view this folio in your list anymore if you remove."
      title="Reject this folio"
    />
  );

  return (
    <>
      <View style={{ height: theme.spacing.xxl }} />
      <View style={{ flex: 1 }}>
        <Spin spinning={isLoading}>
          <ScrollView>
            {Some(foliosSearchResults) && (
              <View style={{ paddingLeft: 32 }}>
                <Heading level="4">{`Results(${foliosSearchResults.length})`}</Heading>
              </View>
            )}
            <ConnectedOrganizationsTable
              containers={Some(foliosSearchResults) ? foliosSearchResults : folios}
              onAccept={acceptFolio}
              onReject={setSelectedFolioId}
              onView={setSelectedFolio}
            />
          </ScrollView>

          <ConnectedOrganizationsDetails
            acceptFolio={acceptFolio}
            container={selectedFolio}
            isOpen={Some(selectedFolio)}
            onDrawerClose={() => {
              setSelectedFolio(undefined);
            }}
            onReject={setSelectedFolioId}
          />
          {Some(selectedFolioId) && showConfirmationModal()}
        </Spin>
      </View>
    </>
  );
};
