// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Icon, Select, TextInput, useTheme } from "@merit/frontend-components";
import { DateTimePicker } from "../../../components/DateTimePicker/DateTimePicker";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../../components/Spacer";
import { View } from "react-native";
import dayjs from "dayjs";
import type {
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerTypeEnum as FieldType,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInnerPredicateEnum as Predicate,
} from "../../../gen";
import type { OPTestProps } from "../../../types/TestProps";

const { Some } = Helpers;

type Props = {
  readonly type: FieldType;
  readonly onChange: (value: boolean | number | string) => void;
  readonly predicate: Predicate;
  readonly defaultValue?: string;
  readonly testProps: OPTestProps;
};

export const Field = ({ defaultValue, onChange, predicate, testProps, type }: Props) => {
  const { theme } = useTheme();

  const phoneNumberFormates = [
    { label: "XXX-XXX-XXXX", value: "XXX-XXX-XXXX" },
    { label: "(XXX)-XXX-XXXX", value: "(XXX)-XXX-XXXX" },
    { label: "XXX.XXX.XXXX", value: "XXX.XXX.XXXX" },
    { label: "XXXXXXXXXX", value: "XXXXXXXXXX" },
  ];

  const blobOptions = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  switch (type) {
    case "Text":
    case "Bool":
    case "Markdown":
      return (
        <View style={{ maxWidth: 248 }}>
          <TextInput
            onChangeText={value => {
              onChange(value);
            }}
            placeholder="Input"
            size="small"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}TextInput`,
            }}
            value={defaultValue ?? ""}
          />
        </View>
      );
    case "Email":
      return (
        <View style={{ alignItems: "center", flexDirection: "row", maxWidth: 189 }}>
          <Body>@</Body>
          <HorizontalSpacer size={theme.spacing.s} />
          <TextInput
            onChangeText={value => {
              onChange(value);
            }}
            placeholder="domain name"
            size="small"
            value={defaultValue ?? ""}
          />
        </View>
      );
    case "Number":
      return (
        <View style={{ maxWidth: 160 }}>
          <TextInput
            keyboardType="numeric"
            onChangeText={value => {
              onChange(value);
            }}
            placeholder="Input"
            size="small"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}TextInput`,
            }}
            value={defaultValue ?? ""}
            width={160}
          />
        </View>
      );

    case "PhoneNumber":
      return (
        <View style={{ minWidth: 230 }}>
          <Select
            defaultValue={phoneNumberFormates.find(_ => _.value === defaultValue)}
            label=""
            onSelectOption={option => {
              onChange(option.value);
            }}
            options={phoneNumberFormates}
            placeholder={{
              label: "Format",
              value: "",
            }}
            showLabel={false}
            size="small"
            testProps={testProps}
            usePortal
          />
        </View>
      );

    case "DateTime": {
      if (predicate === "BeforeTodayMinusXDays") {
        return (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <TextInput
              keyboardType="numeric"
              onChangeText={value => {
                onChange(value);
              }}
              placeholder="number"
              size="small"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}TextInput`,
              }}
              value={defaultValue ?? ""}
              width={80}
            />
            <HorizontalSpacer size={theme.spacing.xxl} />
            <Body>(of days)</Body>
          </View>
        );
      }

      if (predicate === "BeforeNow") {
        return (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <View style={{ maxWidth: 180 }}>
              <View style={{ alignItems: "center", flexDirection: "row", height: 32 }}>
                <Icon name="calendarMediumSubdued" />
                <HorizontalSpacer size={theme.spacing.m} />
                <Body
                  testProps={{
                    ...testProps,
                    elementName: `${testProps.elementName}Text`,
                  }}
                >
                  {dayjs().format("DD/MM/YYYY hh:mm a")}
                </Body>
              </View>
            </View>
          </View>
        );
      }

      if (predicate === "BeforeThisTimeOfDay" || predicate === "AfterThisTimeOfDay") {
        return (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            {/* TODO: Currently, we do not have TimePicker in component library, once added replace this with TimePicker  */}
            <View style={{ maxWidth: 100 }}>
              <DateTimePicker
                defaultValue={
                  Some(defaultValue) ? defaultValue.split(":").slice(0, 2).join(":") : undefined
                }
                onChange={timeValue => {
                  onChange(`${timeValue}:00`);
                }}
                size="small"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}DateTimePicker`,
                }}
                type="time"
              />
            </View>
          </View>
        );
      }

      if (predicate === "BeforeNowMinusXDuration") {
        return (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            {/* TODO: Currently, TextInput doesn't support width < 180, set it to 156 once it's fixed */}
            <TextInput
              onChangeText={value => {
                onChange(value);
              }}
              placeholder="Input"
              size="small"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}TextInput`,
              }}
              value={defaultValue ?? ""}
            />
          </View>
        );
      }

      // TODO: The DatePicker does not match the design at the moment, modify once the issue is resolved
      return (
        <View style={{ minWidth: 240 }}>
          <DateTimePicker
            defaultValue={
              Some(defaultValue) ? dayjs(defaultValue).format("YYYY-MM-DDTHH:mm") : undefined
            }
            onChange={dateTime => {
              onChange(dayjs(dateTime).toISOString());
            }}
            size="small"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}DateTimePicker`,
            }}
            type="datetime-local"
            width={208}
          />
        </View>
      );
    }

    case "Date": {
      if (predicate === "BeforeTodayMinusXDays") {
        return (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <TextInput
              keyboardType="numeric"
              onChangeText={value => {
                onChange(value);
              }}
              placeholder="number"
              size="small"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}TextInput`,
              }}
              value={defaultValue ?? ""}
              width={80}
            />
            <HorizontalSpacer size={theme.spacing.xxl} />
            <Body>(of days)</Body>
          </View>
        );
      }

      if (predicate === "AfterToday" || predicate === "BeforeToday") {
        return (
          <View style={{ maxWidth: 162 }}>
            <View style={{ alignItems: "center", flexDirection: "row", height: 32 }}>
              <Icon name="calendarMediumSubdued" />
              <HorizontalSpacer size={theme.spacing.m} />
              <Body
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}Text`,
                }}
              >
                {dayjs().format("DD/MM/YYYY")}
              </Body>
            </View>
          </View>
        );
      }

      if (predicate === "AfterThisDate" || predicate === "BeforeThisDate") {
        return (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            {/* TODO: Currently, we do not have DatePicker in component library, once added replace this with DatePicker  */}
            <View style={{ maxWidth: 162 }}>
              <DateTimePicker
                defaultValue={Some(defaultValue) ? defaultValue : undefined}
                onChange={date => {
                  onChange(date);
                }}
                size="small"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}DateTimePicker`,
                }}
                type="date"
              />
            </View>
          </View>
        );
      }

      // TODO: The DatePicker does not match the design at the moment, modify once the issue is resolved
      return (
        <View style={{ maxWidth: 200 }}>
          <DateTimePicker
            defaultValue={
              Some(defaultValue) ? dayjs(defaultValue).format("YYYY-MM-DDTHH:mm") : undefined
            }
            onChange={dateTime => {
              onChange(dateTime);
            }}
            size="small"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}TextInput`,
            }}
            type="datetime-local"
          />
        </View>
      );
    }

    case "Blob":
      return (
        <View style={{ minWidth: 104 }}>
          <Select
            defaultValue={blobOptions.find(_ => _.value === defaultValue)}
            label=""
            onSelectOption={option => {
              onChange(option.value === "yes");
            }}
            options={blobOptions}
            showLabel={false}
            size="small"
            testProps={testProps}
            usePortal
          />
        </View>
      );

    default: {
      throw new Error("Doesn't match field");
    }
  }
};
