// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Hoverable } from "react-native-web-hooks";
import { ListTemplatesStatusEnum, ListTemplatesTypeEnum } from "@src/gen";
import { Pagination, Spin } from "../../components";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { useAlertStore } from "@src/stores";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import { useState } from "react";
import { useTemplateDataList } from "../Templates/useTemplateDataList";
import type { OPTestProps } from "../../types/TestProps";
import type { Search200ResponseTemplatesInner } from "@src/gen";

const { None, Some } = Helpers;

export type Template = {
  readonly name: string;
  readonly id: string;
};

type Props = {
  readonly onSelect: (template: Template) => void;
  readonly closeDrawer: () => void;
  readonly testProps: OPTestProps;
};

export const MeritTemplatesList = ({ closeDrawer, onSelect, testProps }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    body: {
      flex: 1,
      paddingVertical: theme.spacing.m,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    header: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xxl,
    },
    listHeader: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      flexDirection: "row",
      padding: 16,
    },
    listItem: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
  });

  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler } = useServerErrorHandler();
  const [templatesSearchResult, setTemplatesSearchResult] =
    useState<readonly Search200ResponseTemplatesInner[]>();
  const [searchValue, setSearchValue] = useState<string>();

  const { data, loading, nextPage, pagination, prevPage } = useTemplateDataList(
    api,
    selectedOrgId,
    undefined,
    undefined,
    ListTemplatesStatusEnum.Live,
    ListTemplatesTypeEnum.Merit
  );

  const fetchTemplatesBySearchQuery = async (searchQuery: string) => {
    try {
      setIsLoading(true);
      const { hasMore, templates } = await api.search({
        orgID: selectedOrgId,
        query: {
          objectName: searchQuery,
          objectType: "template",
          templateType: ListTemplatesTypeEnum.Merit,
        },
      });

      if (Some(hasMore) && hasMore) {
        setAlert({
          closable: true,
          id: "max-limit",
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: `Your search returned more than 500 results. If you couldn't find 'live' templates, please refine your search.`,
          type: "warning",
        });
      }
      const liveTemplates = templates?.filter(
        _ => _.status?.toLowerCase() === ListTemplatesStatusEnum.Live
      );
      setTemplatesSearchResult(liveTemplates);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchValue(undefined);
    setTemplatesSearchResult(undefined);
  };

  const filteredTemplates = templatesSearchResult ?? data;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Heading
            level="4"
            testProps={{
              ...testProps,
              elementName: `${testProps.elementName}AddTemplateListViewHeaderText`,
            }}
          >
            Add Template
          </Heading>
          <Pressable
            onPress={() => {
              clearSearch();
              closeDrawer();
            }}
          >
            <Icon
              name="closeExtraSmallDefault"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}AddTemplateListViewCloseButton`,
              }}
            />
          </Pressable>
        </View>
        <VerticalSpacer size={theme.spacing.xxl} />
        <SearchForm
          initialValue={searchValue}
          onClear={() => {
            setTemplatesSearchResult(undefined);
          }}
          onSearch={searchQuery => {
            setSearchValue(searchQuery);
            fetchTemplatesBySearchQuery(searchQuery);
          }}
          size="medium"
          width={540}
        />
      </View>

      {isLoading || loading ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Spin />
        </View>
      ) : (
        <View style={styles.body}>
          {Some(templatesSearchResult) && (
            <>
              <View style={{ paddingLeft: 32 }}>
                <Heading level="4">{`Results(${templatesSearchResult.length})`}</Heading>
              </View>
              <VerticalSpacer size={theme.spacing.xs} />
            </>
          )}
          <>
            {filteredTemplates.length <= 0 && (
              <View style={{ alignItems: "center", padding: theme.spacing.l }}>
                <Body>No live templates found</Body>
              </View>
            )}
            {filteredTemplates.length > 0 && (
              <>
                <View style={styles.listHeader}>
                  <HorizontalSpacer />
                  <Heading level="5">Template name</Heading>
                </View>
                <ScrollView>
                  {filteredTemplates.map(template => (
                    <View
                      key={template.id}
                      style={styles.listItem}
                      {...Helpers.generateTestIdProps({
                        ...testProps,
                        elementId: template.id,
                        elementName: `${testProps.elementName}AddTemplateListViewItem`,
                      })}
                    >
                      <Hoverable>
                        {isHovered => (
                          <Pressable
                            onPress={() => {
                              onSelect({ id: template.id, name: template.name ?? "" });
                              clearSearch();
                              closeDrawer();
                            }}
                            style={[
                              { padding: theme.spacing.l },
                              isHovered && { backgroundColor: theme.colors.action.pressed },
                            ]}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <HorizontalSpacer />
                              <Body
                                testProps={{
                                  ...testProps,
                                  elementName: `${testProps.elementName}AddTemplateListViewTemplateName`,
                                }}
                              >
                                {template.name}
                              </Body>
                            </View>
                          </Pressable>
                        )}
                      </Hoverable>
                    </View>
                  ))}
                </ScrollView>
              </>
            )}
          </>
        </View>
      )}

      {None(templatesSearchResult) && (
        <Pagination
          disableNext={!pagination.hasNextPage}
          disablePrev={!pagination.hasPrevPage}
          hasBottomMargin={false}
          onNext={nextPage}
          onPrev={prevPage}
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}AddTemplateListView`,
          }}
        />
      )}
    </View>
  );
};
