// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, useTheme } from "@merit/frontend-components";
import {
  Datagrid,
  DatagridCollapsableSection,
  EllipsisText,
  Pagination,
  VerticalSpacer,
} from "../../components";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "./Templates";
import { ScrollView } from "react-native";
import { TemplatesFilter } from "./TemplatesFilter";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLinkedOrgs } from "../../api/useLinkedOrgs";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type {
  ListTemplates200Response,
  ListTemplates200ResponseTemplatesInner,
  ListTemplatesTypeEnum,
} from "../../gen";

const { None, Some } = Helpers;

type GetTemplateParams = {
  readonly linkedOrgID: string;
  readonly linkedOrgName: string;
  readonly start?: string;
  readonly end?: string;
  readonly type?: ListTemplatesTypeEnum;
};

type Props = {
  readonly onPressViewTemplate: (templateID: string) => void;
};

type AnnotatedListTemplatesResponse = ListTemplates200Response & { readonly ownerOrgName: string };

export const SharedTemplates = ({ onPressViewTemplate }: Props) => {
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { sharedTemplatesLimit } = useAppConstantsStore();
  const { configuration } = useLoadedConfigurationState();
  const { linkedOrgsMap } = useLinkedOrgs(api, selectedOrgId);
  const [sharedTemplates, setSharedTemplates] = useState<readonly AnnotatedListTemplatesResponse[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [orgIDToExpand, setOrgIDToExpand] = useState<string>();
  const [templateType, setTemplateType] = useState<ListTemplatesTypeEnum>();

  const { theme } = useTheme();

  const getTemplates = useCallback(
    ({ end, linkedOrgID, linkedOrgName, start, type }: GetTemplateParams) =>
      api
        .listTemplates({
          end,
          limit: sharedTemplatesLimit,
          orgID: selectedOrgId,
          ownerID: linkedOrgID,
          start,
          type,
        })
        .then(
          templatesResponse =>
            ({
              orgID: templatesResponse.orgID,
              ownerOrgID:
                linkedOrgID === configuration.solUUID ? configuration.solUUID : linkedOrgID,
              ownerOrgName:
                linkedOrgID === configuration.solUUID ? configuration.solName : linkedOrgName,
              paginationInfo: templatesResponse.paginationInfo,
              templates: templatesResponse.templates,
            } as AnnotatedListTemplatesResponse)
        ),
    [api, configuration.solName, configuration.solUUID, selectedOrgId, sharedTemplatesLimit]
  );

  const initSharedTemplateGroups = useCallback(() => {
    if (!(selectedOrgId in linkedOrgsMap)) {
      return;
    }

    setIsLoading(true);

    const initialTemplates = linkedOrgsMap[selectedOrgId].map(linkedOrg => ({
      orgID: selectedOrgId,
      ownerOrgID:
        linkedOrg.orgID === configuration.solUUID ? configuration.solUUID : linkedOrg.orgID,
      ownerOrgName:
        linkedOrg.orgID === configuration.solUUID ? configuration.solName : linkedOrg.orgInfo.name,
      templates: [],
    })) as readonly AnnotatedListTemplatesResponse[];

    setIsLoading(false);
    setSharedTemplates(initialTemplates);
  }, [configuration.solName, configuration.solUUID, linkedOrgsMap, selectedOrgId]);

  useEffect(() => {
    initSharedTemplateGroups();
  }, [initSharedTemplateGroups]);

  const sharedColumns: readonly DatagridColumn<ListTemplates200ResponseTemplatesInner>[] = [
    {
      key: "organizationName",
      label: "Organization name",
      size: 200,
    },
    {
      key: "name",
      label: "Template name",
      renderer: item => <EllipsisText text={item.name ?? "--"} />,
      size: "flex",
    },
    { key: "type", label: "Type", size: 128 },
    {
      key: "id",
      label: "Template ID",
      renderer: item => <EllipsisText text={item.id} />,
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }) => (
        <EllipsisText text={Some(description) && description.length > 0 ? description : "--"} />
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      renderer: item => (
        <Button
          onPress={() => {
            onPressViewTemplate(item.id);
          }}
          size="small"
          text="View"
          type="secondary"
        />
      ),

      size: 128,
    },
  ];

  const loadTemplateData = useCallback(
    async (orgID: string, type?: ListTemplatesTypeEnum) => {
      const templatesResp = sharedTemplates.find(st => st.ownerOrgID === orgID);
      if (Some(templatesResp)) {
        setIsLoading(true);
        const orgTemplates = await getTemplates({
          linkedOrgID: templatesResp.ownerOrgID,
          linkedOrgName: templatesResp.ownerOrgName,
          type: type ?? templateType,
        });
        const repTemplates = sharedTemplates.map(st => {
          if (st.ownerOrgID === orgID) {
            return orgTemplates;
          }

          return st;
        });

        setIsLoading(false);
        setSharedTemplates(repTemplates);
      }
    },
    [getTemplates, sharedTemplates, templateType]
  );

  const expandedOrgData = useMemo(() => {
    if (Some(orgIDToExpand)) {
      return sharedTemplates.find(({ ownerOrgID }) => ownerOrgID === orgIDToExpand);
    }

    return undefined;
  }, [orgIDToExpand, sharedTemplates]);

  const paginate = async (
    templatesResp: AnnotatedListTemplatesResponse | undefined,
    type: "next" | "prev"
  ) => {
    if (None(templatesResp)) {
      return;
    }
    setIsLoading(true);
    const orgTemplates = await getTemplates({
      end: type === "prev" ? templatesResp.paginationInfo?.previousEndBefore : undefined,
      linkedOrgID:
        templatesResp.ownerOrgID === configuration.solUUID
          ? configuration.solUUID
          : templatesResp.ownerOrgID,
      linkedOrgName: templatesResp.ownerOrgName,
      start: type === "next" ? templatesResp.paginationInfo?.nextStartAfter : undefined,
      type: templateType,
    });
    setIsLoading(false);

    setSharedTemplates(prevState =>
      prevState.map(field => {
        if (templatesResp.ownerOrgID === field.ownerOrgID) {
          return {
            ...field,
            paginationInfo: orgTemplates.paginationInfo,
            templates: orgTemplates.templates,
          };
        }

        return field;
      })
    );
  };

  const toggleExpand = (ownerOrgID: string) => {
    if (orgIDToExpand === ownerOrgID) {
      setOrgIDToExpand(undefined);
    } else {
      loadTemplateData(ownerOrgID);
      setOrgIDToExpand(ownerOrgID);
    }
  };

  return (
    <>
      <TemplatesFilter
        onSelect={values => {
          setTemplateType(values?.type);
          if (Some(orgIDToExpand)) {
            loadTemplateData(orgIDToExpand, values?.type);
          }
        }}
        showStatus={false}
        typePlaceholder="All"
      />
      <VerticalSpacer size={theme.spacing.xxl} />
      <ScrollView>
        <Datagrid columns={sharedColumns} loading={isLoading}>
          {sharedTemplates.map(
            st =>
              Some(st.templates) && (
                <DatagridCollapsableSection
                  columns={sharedColumns}
                  data={st.templates}
                  isExpanded={orgIDToExpand === st.ownerOrgID}
                  key={st.ownerOrgID}
                  noDataMessage="No templates have been shared from this organization"
                  onToggle={() => {
                    toggleExpand(st.ownerOrgID);
                  }}
                  title={st.ownerOrgName}
                />
              )
          )}
        </Datagrid>
      </ScrollView>
      <Pagination
        disableNext={
          Some(expandedOrgData) ? None(expandedOrgData.paginationInfo?.nextStartAfter) : true
        }
        disablePrev={
          Some(expandedOrgData) ? None(expandedOrgData.paginationInfo?.previousEndBefore) : true
        }
        onNext={() => {
          paginate(expandedOrgData, "next");
        }}
        onPrev={() => {
          paginate(expandedOrgData, "prev");
        }}
        testProps={{
          elementName: "sharedTemplatesListView",
          screenName: SCREEN_NAME,
        }}
      />
    </>
  );
};
