// Copyright 2023 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { AppConstants } from "./appConstantsStore";
import type { DefaultApi } from "../gen/apis/DefaultApi";
import type { GetConfiguration200Response } from "../gen";

export type BaseMeritFieldIds = Record<keyof AppConstants["meritFieldNames"], string | undefined>;
export type BaseFolioFieldIds = Record<keyof AppConstants["folioFieldNames"], string | undefined>;

export type BaseFieldIds = {
  readonly fieldIds?: {
    readonly merit: BaseMeritFieldIds;
    readonly folio: BaseFolioFieldIds;
  };
  readonly updateFieldIds: (
    api: DefaultApi,
    configuration: GetConfiguration200Response,
    constants: AppConstants,
    selectedOrgId: string
  ) => Promise<Omit<BaseFieldIds, "updateFieldIds">>;
};

export const useBaseFieldIdStore = create<BaseFieldIds>()(
  persist(
    set => ({
      fieldIds: undefined,
      updateFieldIds: async (
        api: DefaultApi,
        configuration: GetConfiguration200Response,
        constants: AppConstants,
        selectedOrgId: string
      ) => {
        const baseMeritTemplate = await api.getTemplate({
          orgID: selectedOrgId,
          templateID: configuration.baseMeritTemplateUUID,
        });
        const baseFolioTemplate = await api.getTemplate({
          orgID: selectedOrgId,
          templateID: configuration.baseFolioTemplateUUID,
        });

        const email = baseMeritTemplate.templateFields?.find(
          tf => tf.name === constants.meritFieldNames.email
        )?.fieldID;
        const firstName = baseMeritTemplate.templateFields?.find(
          tf => tf.name === constants.meritFieldNames.firstName
        )?.fieldID;
        const lastName = baseMeritTemplate.templateFields?.find(
          tf => tf.name === constants.meritFieldNames.lastName
        )?.fieldID;
        const meritIssuingOrgName = baseMeritTemplate.templateFields?.find(
          tf => tf.name === constants.meritFieldNames.issuingOrgName
        )?.fieldID;
        const organizationName = baseFolioTemplate.templateFields?.find(
          tf => tf.name === constants.folioFieldNames.organizationName
        )?.fieldID;
        const folioIssuingOrgName = baseFolioTemplate.templateFields?.find(
          tf => tf.name === constants.folioFieldNames.issuingOrgName
        )?.fieldID;

        const updatedValues = {
          fieldIds: {
            folio: {
              issuingOrgName: folioIssuingOrgName,
              organizationName,
            },
            merit: {
              email,
              firstName,
              issuingOrgName: meritIssuingOrgName,
              lastName,
            },
          },
        };

        set(() => updatedValues);

        return updatedValues;
      },
    }),
    {
      name: "orgportal-basefieldid-storage",
    }
  )
);
