// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "./Spacer";
import { View } from "react-native";
import { useCallback } from "react";
import type { OPTestProps } from "../types/TestProps";
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner as Permissions } from "../gen";

const { None, Some } = Helpers;

type Props = {
  readonly permissions: readonly Permissions[] | undefined;
  readonly testProps: OPTestProps;
};

export const ViewPermissions = ({ permissions, testProps }: Props) => {
  const { theme } = useTheme();

  const getPermission = useCallback(() => {
    if (None(permissions)) {
      return "None";
    }

    const allPermissions = permissions
      .map(permission => {
        if (permission.permitted.grantedToName === "All") {
          return permission.action;
        }

        return undefined;
      })
      .filter(_ => Some(_));

    if (allPermissions.length <= 0) {
      return "None";
    }

    return allPermissions.join(" and ");
  }, [permissions]);

  return (
    <>
      <Heading
        level="5"
        testProps={{ ...testProps, elementName: `${testProps.elementName}PermissionsLabel` }}
      >
        Permissions
      </Heading>
      <VerticalSpacer size={theme.spacing.xs} />
      <View style={{ flexDirection: "row" }}>
        <Body>Can others view this?</Body>
        <HorizontalSpacer size={10} />
        <Body color={theme.colors.border.subdued}>|</Body>
        <HorizontalSpacer size={10} />
        <Body testProps={{ ...testProps, elementName: `${testProps.elementName}ReadPermission` }}>
          {getPermission().includes("read") ? "Yes" : "No"}
        </Body>
      </View>

      <VerticalSpacer size={theme.spacing.xs} />
      <View style={{ flexDirection: "row" }}>
        <Body>Can others use this?</Body>
        <HorizontalSpacer size={10} />
        <Body color={theme.colors.border.subdued}>|</Body>
        <HorizontalSpacer size={10} />
        <Body testProps={{ ...testProps, elementName: `${testProps.elementName}ExtendPermission` }}>
          {getPermission().includes("extend") ? "Yes" : "No"}
        </Body>
      </View>
    </>
  );
};
