// Copyright 2023 Merit International Inc. All Rights Reserved

import type { OrgsGet200ResponseContainersInner } from "../gen";

export const fieldHasCompletenessFailureByName = (
  container: OrgsGet200ResponseContainersInner,
  fieldName?: string
): boolean => {
  const field = container.fields?.find(f => f.name === fieldName);
  if (field === undefined) {
    return false;
  }

  return (
    container.completenessFailures?.find(cf => cf.templateFieldID === field.templateFieldID) !==
    undefined
  );
};

export const fieldHasCompletenessFailureById = (
  container: OrgsGet200ResponseContainersInner,
  templateFieldId?: string
): boolean =>
  container.completenessFailures?.find(cf => cf.templateFieldID === templateFieldId) !== undefined;
