// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Heading, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody, Pagination, Spin, Tabs } from "../../components";
import { Drawer } from "../../components/Drawer";
import { EllipsisText } from "../../components/EllipsisText";
import { FieldDetailsScreen } from "./FieldDetails";
import { FieldsShared } from "./FieldsShared";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, StyleSheet, View } from "react-native";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore } from "@src/stores";
import { useApi } from "../../api/api";
import { useFieldsData } from "./useFieldsData";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";
import type { Search200ResponseFieldKindsInner } from "../../gen";

const { None, Some } = Helpers;
const screenName = "Fields";
type Navigation = NativeStackNavigationProp<RouteParams, "Templates">;

export type TabKey = "own" | "shared";

export const FieldsScreen = () => {
  const [selectedTab, setSelectedTab] = useState<TabKey>("own");
  const { theme } = useTheme();
  const navigation = useNavigation<Navigation>();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { configuration } = useLoadedConfigurationState();
  const isFocused = useIsFocused();
  const fieldKindsData = useFieldsData(
    api,
    selectedOrgId,
    isFocused,
    selectedTab === "shared" ? configuration.solUUID : undefined
  );
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "Fields">>();
  const fieldKindID = None(routeParams) ? undefined : routeParams.fieldId;
  const { DefaultLayout } = useFlaggedLayout();
  const [fieldKindIDToView, setFieldKindIDToView] = useState<string>();
  const [fieldKindSearchResults, setFieldKindSearchResults] =
    useState<readonly Search200ResponseFieldKindsInner[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler } = useServerErrorHandler();

  useEffect(() => {
    if (Some(fieldKindID)) {
      setFieldKindIDToView(fieldKindID);
    }
  }, [fieldKindID]);

  const fetchFieldKindsBySearchQuery = async (searchQuery: string) => {
    try {
      setIsLoading(true);
      const { fieldKinds, hasMore } = await api.search({
        orgID: selectedOrgId,
        query: {
          objectName: searchQuery,
          objectType: "fieldkind",
        },
      });

      if (Some(hasMore) && hasMore) {
        setAlert({
          closable: true,
          id: "max-limit",
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: `Your search returned more than 500 results. Please refine you search`,
          type: "warning",
        });
      }
      setFieldKindSearchResults(fieldKinds);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fieldTabs: readonly { readonly key: TabKey; readonly label: string }[] =
    // check for Sol org and remove the Fields shared with us tab
    selectedOrgId === configuration.solUUID
      ? [{ key: "own", label: "Our fields" }]
      : [
          { key: "own", label: "Our fields" },
          { key: "shared", label: "Fields shared with us" },
        ];
  const styles = StyleSheet.create({
    wrapper: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  const fieldKinds: readonly Search200ResponseFieldKindsInner[] =
    fieldKindSearchResults ?? fieldKindsData.data;

  const columns: readonly DatagridColumn<Search200ResponseFieldKindsInner>[] = [
    {
      key: "name",
      label: "Name",
      size: "flex",
    },
    {
      key: "dataType",
      label: "Type",
      size: "flex",
    },
    {
      key: "fieldKindID",
      label: "Field ID",
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={Some(description) && description !== "" ? description : "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: (item, testProps) => (
        <View style={{ width: 80 }} {...Helpers.generateTestIdProps(testProps)}>
          <Button
            onPress={() => {
              const fieldKind = fieldKinds.find(
                fieldKindItem => fieldKindItem.fieldKindID === item.fieldKindID
              );
              setFieldKindIDToView(fieldKind?.fieldKindID);
            }}
            size="small"
            testProps={
              Some(testProps)
                ? { ...testProps, elementName: `${testProps.elementName}ViewButton` }
                : testProps
            }
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 128,
    },
  ];

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Create & Configure" }, { name: "Fields" }]}
        headerRightElements={
          <>
            <SearchForm
              disabled={isLoading}
              onClear={() => {
                setFieldKindSearchResults(undefined);
              }}
              onSearch={value => {
                fetchFieldKindsBySearchQuery(value);
              }}
            />

            <Button
              onPress={() => {
                navigation.navigate("CreateField");
              }}
              size="small"
              testProps={{
                elementName: "createFieldButton",
                screenName,
              }}
              text="Create field"
            />
          </>
        }
        tabs={
          <Tabs
            items={fieldTabs}
            onChange={(event: TabKey) => {
              setSelectedTab(event);
            }}
            selected={selectedTab}
            testProps={{
              elementName: "fields",
              screenName,
            }}
          />
        }
        testProps={{
          elementName: "fields",
          screenName,
        }}
        title="Fields"
      >
        <View style={styles.wrapper}>
          <VerticalSpacer size={theme.spacing.xxl} />
          <View style={{ flex: 1 }}>
            {isLoading && (
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Spin />
              </View>
            )}
            {!isLoading && (
              <>
                {Some(fieldKindSearchResults) ? (
                  <>
                    <View style={{ paddingLeft: 32 }}>
                      <Heading level="4">{`Results (${fieldKindSearchResults.length})`}</Heading>
                    </View>
                    <ScrollView style={{ flex: 1 }}>
                      <Datagrid
                        columns={columns}
                        testProps={{ elementName: "ownFieldsListView", screenName }}
                      >
                        <DatagridBody
                          columns={columns}
                          data={fieldKinds}
                          testProps={{
                            elementName: "ownFieldsListView",
                            screenName,
                          }}
                          testPropsElementIdKey="fieldKindID"
                        />
                      </Datagrid>
                    </ScrollView>
                  </>
                ) : (
                  <>
                    {selectedTab === "own" && (
                      <>
                        <ScrollView style={{ flex: 1 }}>
                          <Datagrid
                            columns={columns}
                            loading={fieldKindsData.loading}
                            testProps={{ elementName: "ownFieldsListView", screenName }}
                          >
                            <DatagridBody
                              columns={columns}
                              data={fieldKinds}
                              testProps={{
                                elementName: "ownFieldsListView",
                                screenName,
                              }}
                              testPropsElementIdKey="fieldKindID"
                            />
                          </Datagrid>
                        </ScrollView>
                        <Pagination
                          disableNext={!fieldKindsData.pagination.hasNextPage}
                          disablePrev={!fieldKindsData.pagination.hasPrevPage}
                          onNext={() => {
                            fieldKindsData.nextPage();
                          }}
                          onPrev={() => {
                            fieldKindsData.prevPage();
                          }}
                          testProps={{
                            elementName: "ownFieldsListView",
                            screenName,
                          }}
                        />
                      </>
                    )}
                    {selectedTab === "shared" && (
                      <FieldsShared
                        loading={fieldKindsData.loading}
                        onPressView={fieldKind => {
                          setFieldKindIDToView(fieldKind.fieldKindID);
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </View>
        </View>
      </DefaultLayout>
      <Drawer isOpen={Some(fieldKindIDToView)}>
        <FieldDetailsScreen
          fieldKindID={fieldKindIDToView}
          onClone={() => {
            setFieldKindIDToView(undefined);
          }}
          onClose={() => {
            setFieldKindIDToView(undefined);
          }}
          onPressEdit={() => {
            setFieldKindIDToView(undefined);
          }}
        />
      </Drawer>
    </>
  );
};
