import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner } from "@src/gen";

// TODO@tyler: It'd be nice to have a NestedPick utility type
//   type FieldSubset = NestedPick<Field, 'permissions[].action' | 'permissions[].permitted.grantedToName'>

type Permission = ArrayElement<
  NonNullable<GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner["permissions"]>
>;

type PermissionSubset = {
  readonly action: Permission["action"];
  readonly permitted: {
    readonly grantedToName: Permission["permitted"]["grantedToName"];
  };
};

type Field = {
  readonly permissions?: readonly PermissionSubset[];
};

type IsFieldReadable = (field: Field) => boolean;

export const isFieldReadable: IsFieldReadable = field =>
  (field.permissions ?? []).some(
    perm => perm.action === "read_data" && perm.permitted.grantedToName !== "None"
  );
