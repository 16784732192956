/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom
 */
export interface OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom {
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom
     */
    target?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom
     */
    predicate?: OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom
     */
    arguments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum = {
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToX: 'EqualToX',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates'
} as const;
export type OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum = typeof OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum[keyof typeof OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum];


/**
 * Check if a given object implements the OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom interface.
 */
export function instanceOfOrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSON(json: any): OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom {
    return OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped(json, false);
}

export function OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': !exists(json, 'target') ? undefined : json['target'],
        'predicate': !exists(json, 'predicate') ? undefined : json['predicate'],
        'arguments': !exists(json, 'arguments') ? undefined : json['arguments'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON(value?: OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'predicate': value.predicate,
        'arguments': value.arguments,
        'errorMessage': value.errorMessage,
    };
}

