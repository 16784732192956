/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgents200ResponseAgentsInnerAuth0Options } from './GetAgents200ResponseAgentsInnerAuth0Options';
import {
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON,
    GetAgents200ResponseAgentsInnerAuth0OptionsFromJSONTyped,
    GetAgents200ResponseAgentsInnerAuth0OptionsToJSON,
} from './GetAgents200ResponseAgentsInnerAuth0Options';
import type { GetAgents200ResponseAgentsInnerCapabilitiesInner } from './GetAgents200ResponseAgentsInnerCapabilitiesInner';
import {
    GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSONTyped,
    GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON,
} from './GetAgents200ResponseAgentsInnerCapabilitiesInner';

/**
 * An existing or newly created agent
 * @export
 * @interface GetAgents200ResponseAgentsInner
 */
export interface GetAgents200ResponseAgentsInner {
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    accessType: GetAgents200ResponseAgentsInnerAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    state?: GetAgents200ResponseAgentsInnerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    status?: GetAgents200ResponseAgentsInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    description?: string;
    /**
     * The org that owns this agent.
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    org?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    orgName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    privacyTos: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    usageTos: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    dataTos: string;
    /**
     * The capabilities that this agent requires
     * @type {Array<GetAgents200ResponseAgentsInnerCapabilitiesInner>}
     * @memberof GetAgents200ResponseAgentsInner
     */
    capabilities: Array<GetAgents200ResponseAgentsInnerCapabilitiesInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetAgents200ResponseAgentsInner
     */
    additionals?: { [key: string]: string; };
    /**
     * The Auth0 client ID for an agent
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    auth0ClientId?: string;
    /**
     * The Auth0 client secret for an agent
     * @type {string}
     * @memberof GetAgents200ResponseAgentsInner
     */
    auth0ClientSecret?: string;
    /**
     * 
     * @type {GetAgents200ResponseAgentsInnerAuth0Options}
     * @memberof GetAgents200ResponseAgentsInner
     */
    auth0Options?: GetAgents200ResponseAgentsInnerAuth0Options | null;
}


/**
 * @export
 */
export const GetAgents200ResponseAgentsInnerAccessTypeEnum = {
    Login: 'login',
    Link: 'link',
    Webapp: 'webapp',
    Native: 'native'
} as const;
export type GetAgents200ResponseAgentsInnerAccessTypeEnum = typeof GetAgents200ResponseAgentsInnerAccessTypeEnum[keyof typeof GetAgents200ResponseAgentsInnerAccessTypeEnum];

/**
 * @export
 */
export const GetAgents200ResponseAgentsInnerStateEnum = {
    Live: 'live',
    Removed: 'removed'
} as const;
export type GetAgents200ResponseAgentsInnerStateEnum = typeof GetAgents200ResponseAgentsInnerStateEnum[keyof typeof GetAgents200ResponseAgentsInnerStateEnum];

/**
 * @export
 */
export const GetAgents200ResponseAgentsInnerStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type GetAgents200ResponseAgentsInnerStatusEnum = typeof GetAgents200ResponseAgentsInnerStatusEnum[keyof typeof GetAgents200ResponseAgentsInnerStatusEnum];


/**
 * Check if a given object implements the GetAgents200ResponseAgentsInner interface.
 */
export function instanceOfGetAgents200ResponseAgentsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "accessType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "privacyTos" in value;
    isInstance = isInstance && "usageTos" in value;
    isInstance = isInstance && "dataTos" in value;
    isInstance = isInstance && "capabilities" in value;

    return isInstance;
}

export function GetAgents200ResponseAgentsInnerFromJSON(json: any): GetAgents200ResponseAgentsInner {
    return GetAgents200ResponseAgentsInnerFromJSONTyped(json, false);
}

export function GetAgents200ResponseAgentsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgents200ResponseAgentsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accessType': json['accessType'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'org': !exists(json, 'org') ? undefined : json['org'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
        'privacyTos': json['privacyTos'],
        'usageTos': json['usageTos'],
        'dataTos': json['dataTos'],
        'capabilities': ((json['capabilities'] as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerFromJSON)),
        'additionals': !exists(json, 'additionals') ? undefined : json['additionals'],
        'auth0ClientId': !exists(json, 'auth0ClientId') ? undefined : json['auth0ClientId'],
        'auth0ClientSecret': !exists(json, 'auth0ClientSecret') ? undefined : json['auth0ClientSecret'],
        'auth0Options': !exists(json, 'auth0Options') ? undefined : GetAgents200ResponseAgentsInnerAuth0OptionsFromJSON(json['auth0Options']),
    };
}

export function GetAgents200ResponseAgentsInnerToJSON(value?: GetAgents200ResponseAgentsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accessType': value.accessType,
        'state': value.state,
        'status': value.status,
        'name': value.name,
        'description': value.description,
        'org': value.org,
        'orgName': value.orgName,
        'privacyTos': value.privacyTos,
        'usageTos': value.usageTos,
        'dataTos': value.dataTos,
        'capabilities': ((value.capabilities as Array<any>).map(GetAgents200ResponseAgentsInnerCapabilitiesInnerToJSON)),
        'additionals': value.additionals,
        'auth0ClientId': value.auth0ClientId,
        'auth0ClientSecret': value.auth0ClientSecret,
        'auth0Options': GetAgents200ResponseAgentsInnerAuth0OptionsToJSON(value.auth0Options),
    };
}

