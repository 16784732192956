// Copyright 2023 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as TemplateField } from "../gen/models/GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner";

const { None } = Helpers;

export const getFieldType = (fieldId: string, templateFields: readonly TemplateField[]) => {
  const field = templateFields.find(_ => _.fieldID === fieldId);

  if (None(field)) {
    throw new Error(`Somehow field is not found with the id ${fieldId}`);
  }

  if (None(field.type)) {
    throw new Error(`Somehow field type is not found with the id ${fieldId}`);
  }

  return field.type;
};
