// Copyright 2023 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { DefaultApi, GetAdmins200Response, GetAdminsRequest } from "../../gen";

export type PaginationMeta = {
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextStartAfter?: string;
  readonly prevEndBefore?: string;
};

export const useAdminListData = (api: DefaultApi, body: GetAdminsRequest, isFocused: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<GetAdmins200Response>();
  const { errorHandler } = useServerErrorHandler();
  const [params, setParams] = useState<GetAdminsRequest>(body);

  const admins = response?.admins ?? [];

  const pagination: PaginationMeta = {
    hasNextPage: response?.paginationInfo?.nextStartAfter !== undefined,
    hasPrevPage: response?.paginationInfo?.previousEndBefore !== undefined,
    nextStartAfter: response?.paginationInfo?.nextStartAfter,
    prevEndBefore: response?.paginationInfo?.previousEndBefore,
  };

  const refresh = (nextParams: Partial<GetAdminsRequest> = {}) => {
    if (isLoading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const nextPage = () => {
    if (pagination.nextStartAfter === undefined) {
      return;
    }

    refresh({
      end: undefined,
      start: pagination.nextStartAfter,
    });
  };

  const prevPage = () => {
    if (pagination.prevEndBefore === undefined) {
      return;
    }

    refresh({
      end: pagination.prevEndBefore,
      start: undefined,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setIsLoading(true);

        const res = await api.getAdmins(params);

        setResponse(res);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (isFocused) {
      fetchData();
    }
  }, [api, errorHandler, isFocused, params]);

  return {
    admins,
    isLoading,
    nextPage,
    pagination,
    prevPage,
    refresh,
  };
};
