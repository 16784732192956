// Copyright 2024 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import {
  Body,
  Button,
  Checkbox,
  Heading,
  Icon,
  Select,
  TextInput,
  useTheme,
} from "@merit/frontend-components";
import { ConfirmationModal } from "@src/components/Modals";
import { ErrorMessage, Formik } from "formik";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum } from "../../gen";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, Spin } from "../../components";
import { Hoverable } from "react-native-web-hooks";
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Scopes } from "@src/constants/scopes";
import { VerticalSpacer } from "../../components/Spacer";
import { useAlertStore } from "@src/stores";
import { useApi } from "../../api/api";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useRef, useState } from "react";
import type {
  CreateAgentOperationRequest,
  CreateAgentRequestAccessTypeEnum,
  GetAgents200ResponseAgentsInnerCapabilitiesInner,
} from "../../gen";
import type { FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

export type Capabilities = {
  readonly Agents: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Container: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Datasource: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Entity: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Fields: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Ingestion: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Integration: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Persona: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Policy: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
  readonly Templates: readonly GetAgents200ResponseAgentsInnerCapabilitiesInner[];
};

export type FormValues = {
  readonly name: string;
  readonly description: string;
  readonly accessType: CreateAgentRequestAccessTypeEnum | undefined;
  readonly privacyTos: string;
  readonly dataTos: string;
  readonly iconUri: string;
  readonly usageTos: string;
  readonly terms: string;
  readonly loginUrl: string;
  readonly capabilities: readonly string[];
  readonly allowedLogoutUrls: string;
  readonly allowedOrigins: string;
  readonly callbacks: string;
  readonly organizationUsage: GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum | "";
  readonly webOrigins: string;
};

const { None, Some } = Helpers;

const ACCESS_TYPE_OPTIONS = [
  { label: "Native app", value: "native" },
  { label: "M2M (link)", value: "link" },
  { label: "SPA (login)", value: "login" },
  { label: "Traditional web app", value: "webapp" },
];

const ORG_USE_OPTIONS = [
  { label: "Individuals", value: "" },
  {
    label: "Org admins",
    value: GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum.Require,
  },
  { label: "Both", value: GetAgents200ResponseAgentsInnerAuth0OptionsOrganizationUsageEnum.Allow },
];

export const CreateAppScreen = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { configuration } = useLoadedConfigurationState();
  const isSol = selectedOrgId === configuration.solUUID;
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const queryClient = useQueryClient();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const routes = useRoute<RouteProp<RouteParams, "CreateTemplate" | "ExtendTemplate">>();
  const { name } = routes;
  const { errorHandler } = useServerErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const ref = useRef<FormikProps<FormValues>>(null);
  const [app, setApp] = useState<FormValues>();

  const solOnlyScopes = ["approve:agent_version", "delete:agent"];

  const styles = StyleSheet.create({
    container: {
      alignSelf: "center",
      flex: 1,
      minWidth: 960,
    },
    divider: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      padding: 10,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    listItem: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.disabled,
      borderBottomWidth: 1,
      justifyContent: "space-between",
      paddingHorizontal: 16,
      paddingVertical: theme.spacing.l,
    },
    scope: {
      maxWidth: 250,
      minWidth: 250,
    },
    scopeHeader: {
      paddingVertical: theme.spacing.l,
    },
    scopesContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxWidth: 960,
      paddingVertical: theme.spacing.l,
    },
    wrapper: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
  });

  const validationSchema = Yup.object().shape({
    accessType: Yup.string().required("Please select an agent type"),
    allowedLogoutUrls: Yup.string().trim().max(300, "300 maximum character limit"),
    allowedOrigins: Yup.string().trim().max(300, "300 maximum character limit"),
    callbacks: Yup.string().trim().max(300, "300 maximum character limit"),
    capabilities: Yup.array().of(Yup.string()).min(1, "At least one scope is required"),
    dataTos: Yup.string()
      .required("Data terms of service is required")
      .url("Must be a valid URL starting with http(s)://")
      .trim()
      .max(300, "300 maximum character limit"),
    description: Yup.string()
      .required("Description is required")
      .trim()
      .max(300, "300 maximum character limit"),
    iconUri: Yup.string()
      .url("Must be a valid URL starting with http(s)://")
      .trim()
      .max(300, "300 maximum character limit"),
    loginUrl: Yup.string()
      .required("Log in URL is required")
      .url("Must be a valid URL starting with https://")
      .trim()
      .matches(/^https/u, "Must be a valid URL starting with https://")
      .max(120, "120 maximum character limit"),
    name: Yup.string()
      .trim()
      .required("Agent name is required")
      .max(60, "60 maximum character limit"),
    privacyTos: Yup.string()
      .required("Privacy terms of service is required")
      .url("Must be a valid URL starting with http(s)://")
      .trim()
      .max(300, "300 maximum character limit"),
    usageTos: Yup.string()
      .required("Usage terms of service is required")
      .url("Must be a valid URL starting with http(s)://")
      .trim()
      .max(300, "300 maximum character limit"),
    webOrigins: Yup.string().trim().max(300, "300 maximum character limit"),
  });

  const errorText = (errorMsg: string, fieldName: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      <Body
        style={{ color: theme.colors.text.alert.critical, fontSize: theme.fontSizes.s.fontSize }}
        testProps={{
          elementName: `app${fieldName}FieldError`,
          screenName: name,
        }}
      >
        {errorMsg}
      </Body>
    </View>
  );

  const createAgent = useCallback(
    async (payLoad: CreateAgentOperationRequest) => {
      setIsLoading(true);
      try {
        await api.createAgent(payLoad);
        setAlert({
          closable: true,
          customContent: <Body>App has been saved.</Body>,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          type: "success",
        });
        setApp(undefined);
        navigation.navigate("OrgSettings", { initialTab: "linkedApps" });
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
      queryClient.invalidateQueries();
    },
    [api, deleteAlert, errorHandler, navigation, queryClient, setAlert]
  );

  if (isLoading) {
    return (
      <View
        style={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  const handleFormSubmit = () => {
    if (Some(ref) && Some(ref.current)) {
      ref.current.handleSubmit();
    }
  };

  const closeScreen = () => {
    if (Some(ref) && Some(ref.current)) {
      if (ref.current.dirty) {
        setShowWarningModal(true);

        return;
      }
    }

    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("OrgSettings", { initialTab: "linkedApps" });
  };

  return (
    <>
      <FullScreenModalLayout
        onClose={closeScreen}
        testProps={{
          elementName: "createLinkedApp",
          screenName: name,
        }}
        title="Create app"
      >
        <View style={styles.wrapper}>
          <ScrollView>
            <View style={styles.container}>
              <VerticalSpacer size={theme.spacing.xxl} />
              <Heading bold level="3">
                App details
              </Heading>
              <Formik
                initialValues={{
                  accessType: app?.accessType ?? undefined,
                  allowedLogoutUrls: app?.allowedLogoutUrls ?? "",
                  allowedOrigins: app?.allowedOrigins ?? "",
                  callbacks: app?.callbacks ?? "",
                  capabilities: app?.capabilities ?? [],
                  dataTos: app?.dataTos ?? "",
                  description: app?.description ?? "",
                  iconUri: app?.iconUri ?? "",
                  loginUrl: app?.loginUrl ?? "",
                  name: app?.name ?? "",
                  organizationUsage: app?.organizationUsage ?? "",
                  privacyTos: app?.privacyTos ?? "",
                  terms: app?.terms ?? "",
                  usageTos: app?.usageTos ?? "",
                  webOrigins: app?.webOrigins ?? "",
                }}
                innerRef={ref}
                onSubmit={(values: FormValues) => {
                  if (None(values.capabilities) || None(values.accessType)) {
                    throw new Error("Missing required fields");
                  }

                  const payLoad: CreateAgentOperationRequest = {
                    orgID: selectedOrgId,
                    properties: {
                      accessType: values.accessType,
                      auth0Options: {
                        allowedLogoutURLs: values.allowedLogoutUrls
                          .split(",")
                          .map(url => url.trim())
                          .filter(url => url.startsWith("http")),
                        allowedOrigins: values.allowedOrigins
                          .split(",")
                          .map(url => url.trim())
                          .filter(url => url.startsWith("http")),
                        callbacks: values.callbacks
                          .split(",")
                          .map(url => url.trim())
                          .filter(url => url.startsWith("http")),
                        logoURI: values.iconUri,
                        organizationUsage:
                          values.organizationUsage === "" ? undefined : values.organizationUsage,
                        webOrigins: values.webOrigins
                          .split(",")
                          .map(url => url.trim())
                          .filter(url => url.startsWith("http")),
                      },
                      capabilities: values.capabilities.map(capability => ({
                        capability,
                      })),
                      dataTos: values.dataTos,
                      description: values.description,
                      loginURI: values.loginUrl,
                      name: values.name,
                      privacyTos: values.privacyTos,
                      usageTos: values.usageTos,
                    },
                  };

                  setApp(values);
                  createAgent(payLoad);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
              >
                {({ handleBlur, handleChange, setFieldTouched, setFieldValue, values }) => (
                  <>
                    <VerticalSpacer size={theme.spacing.xxl} />
                    <View>
                      <View
                        style={{
                          maxWidth: 468,
                        }}
                      >
                        <TextInput
                          label="Name *"
                          onBlur={() => {
                            setFieldTouched("name", true);
                            handleBlur("name");
                          }}
                          onChangeText={handleChange("name")}
                          placeholder="Name"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppNameTextInput",
                            screenName: name,
                          }}
                          value={values.name}
                        />
                        <ErrorMessage name="name">{error => errorText(error, "Name")}</ErrorMessage>
                      </View>
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />
                    <View>
                      <View style={{ minWidth: 960 }}>
                        <TextInput
                          label="Description *"
                          numberOfLines={4}
                          onBlur={() => {
                            setFieldTouched("description", true);
                            handleBlur("description");
                          }}
                          onChangeText={handleChange("description")}
                          placeholder="Description"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppDescriptionTextInput",
                            screenName: name,
                          }}
                          value={values.description}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <Body color={theme.colors.text.subdued}>300 maximum character limit</Body>
                        <ErrorMessage name="description">
                          {error => errorText(error, "Description")}
                        </ErrorMessage>
                      </View>
                    </View>

                    <View style={{ maxWidth: 468, paddingTop: theme.spacing.xxl }}>
                      <Select
                        defaultValue={ACCESS_TYPE_OPTIONS.find(
                          ({ value }) => value === values.accessType
                        )}
                        label="Agent type *"
                        onSelectOption={option => {
                          setFieldTouched("accessType", true);
                          if (typeof option.value === "string") {
                            setFieldValue("accessType", option.value);
                          }
                        }}
                        options={ACCESS_TYPE_OPTIONS}
                        placeholder={{
                          label: "Agent type",
                          value: "",
                        }}
                        size="large"
                        testProps={{
                          elementName: "accessType",
                          screenName: name,
                        }}
                        usePortal
                      />
                      <ErrorMessage name="accessType">
                        {error => errorText(error, "Agent Type")}
                      </ErrorMessage>
                    </View>

                    <View style={styles.divider} />

                    <View style={{ flexDirection: "row", marginTop: 20 }}>
                      <View style={{ flex: 1, maxWidth: 468 }}>
                        <TextInput
                          label="Privacy terms of service (URI) *"
                          onBlur={() => {
                            setFieldTouched("privacyTos", true);
                            handleBlur("privacyTos");
                          }}
                          onChangeText={handleChange("privacyTos")}
                          placeholder="Privacy terms of service"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppPrivacyTosTextInput",
                            screenName: name,
                          }}
                          value={values.privacyTos}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <ErrorMessage name="privacyTos">
                          {error => errorText(error, "Privacy TOS")}
                        </ErrorMessage>
                      </View>

                      <HorizontalSpacer size={theme.spacing.xxl} />

                      <View style={{ flex: 1, maxWidth: 468 }}>
                        <TextInput
                          label="Usage terms of service (URI) *"
                          onBlur={() => {
                            setFieldTouched("usageTos", true);
                            handleBlur("usageTos");
                          }}
                          onChangeText={handleChange("usageTos")}
                          placeholder="Usage terms of service"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppUsageTosTextInput",
                            screenName: name,
                          }}
                          value={values.usageTos}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <ErrorMessage name="usageTos">
                          {error => errorText(error, "Usage TOS")}
                        </ErrorMessage>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row", marginTop: 20 }}>
                      <View style={{ flex: 1, maxWidth: 468 }}>
                        <TextInput
                          label="Data terms of service (URI) *"
                          onBlur={() => {
                            setFieldTouched("dataTos", true);
                            handleBlur("dataTos");
                          }}
                          onChangeText={handleChange("dataTos")}
                          placeholder="Data terms of service"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppDataTextInput",
                            screenName: name,
                          }}
                          value={values.dataTos}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <ErrorMessage name="dataTos">
                          {error => errorText(error, "Data TOS")}
                        </ErrorMessage>
                      </View>

                      <HorizontalSpacer size={theme.spacing.xxl} />

                      <View style={{ flex: 1, maxWidth: 468 }}>
                        <TextInput
                          label="Log in URL *"
                          onBlur={() => {
                            setFieldTouched("loginUrl", true);
                            handleBlur("loginUrl");
                          }}
                          onChangeText={handleChange("loginUrl")}
                          placeholder="Log in URL"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppLoginUrlTextInput",
                            screenName: name,
                          }}
                          value={values.loginUrl}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <ErrorMessage name="loginUrl">
                          {error => errorText(error, "Login URI")}
                        </ErrorMessage>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row", marginTop: 20 }}>
                      <View style={{ flex: 1, maxWidth: 468 }}>
                        <TextInput
                          label="App icon (URI)"
                          onBlur={() => {
                            setFieldTouched("iconUri", true);
                            handleBlur("iconUri");
                          }}
                          onChangeText={handleChange("iconUri")}
                          placeholder="App icon"
                          size="large"
                          testProps={{
                            elementName: "createLinkedAppIconTextInput",
                            screenName: name,
                          }}
                          value={values.iconUri}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <ErrorMessage name="iconUri">
                          {error => errorText(error, "Icon URI")}
                        </ErrorMessage>
                      </View>
                    </View>

                    <View style={{ marginTop: 20 }}>
                      <HorizontalSpacer size={theme.spacing.xxl} />
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />

                    <Hoverable>
                      {isHovered => (
                        <View
                          style={[
                            styles.listItem,
                            isHovered && {
                              backgroundColor: theme.colors.background.white,
                            },
                          ]}
                        >
                          <TouchableOpacity style={{ flex: 1, flexDirection: "row" }}>
                            <Pressable
                              onPress={() => {
                                setAccOpen(!accOpen);
                              }}
                            >
                              <Heading
                                bold
                                level="3"
                                testProps={{
                                  elementName: "OauthHeading",
                                  screenName: name,
                                }}
                              >
                                Advanced OAuth
                                <HorizontalSpacer size={750} />
                                <Icon
                                  name={
                                    accOpen
                                      ? "chevronDownMediumDefault"
                                      : "chevronRightMediumAction"
                                  }
                                />
                              </Heading>
                            </Pressable>
                          </TouchableOpacity>
                          {accOpen && (
                            <>
                              <VerticalSpacer size={theme.spacing.xxl} />
                              <View style={{ flexDirection: "row", marginTop: 20 }}>
                                <View style={{ flex: 1, maxWidth: 450 }}>
                                  <TextInput
                                    label="Allowed logout URLs"
                                    onBlur={() => {
                                      setFieldTouched("allowedLogoutUrls", true);
                                      handleBlur("allowedLogoutUrls");
                                    }}
                                    onChangeText={handleChange("allowedLogoutUrls")}
                                    placeholder="Allowed logout URLs"
                                    size="large"
                                    testProps={{
                                      elementName: "createAllowedLogoutUrlsTextInput",
                                      screenName: name,
                                    }}
                                    value={values.allowedLogoutUrls}
                                  />
                                  <Body color={theme.colors.text.subdued}>
                                    Comma separated list of URLs
                                  </Body>
                                  <ErrorMessage name="allowedLogoutUrls">
                                    {error => errorText(error, "Allowed Logout Urls")}
                                  </ErrorMessage>
                                </View>

                                <HorizontalSpacer size={theme.spacing.xxl} />

                                <View style={{ flex: 1, maxWidth: 450 }}>
                                  <TextInput
                                    label="Allowed origins"
                                    onBlur={() => {
                                      setFieldTouched("allowedOrigins", true);
                                      handleBlur("allowedOrigins");
                                    }}
                                    onChangeText={handleChange("allowedOrigins")}
                                    placeholder="Allowed origins"
                                    size="large"
                                    testProps={{
                                      elementName: "createAllowedOriginsTextInput",
                                      screenName: name,
                                    }}
                                    value={values.allowedOrigins}
                                  />
                                  <Body color={theme.colors.text.subdued}>
                                    Comma separated list of URLs
                                  </Body>
                                  <ErrorMessage name="allowedOrigins">
                                    {error => errorText(error, "Allowed Origins")}
                                  </ErrorMessage>
                                </View>
                              </View>

                              <VerticalSpacer size={theme.spacing.xxl} />
                              <View style={{ flexDirection: "row", marginTop: 20 }}>
                                <View style={{ flex: 1, maxWidth: 450 }}>
                                  <TextInput
                                    label="Callbacks"
                                    onBlur={() => {
                                      setFieldTouched("callbacks", true);
                                      handleBlur("callbacks");
                                    }}
                                    onChangeText={handleChange("callbacks")}
                                    placeholder="Callbacks"
                                    size="large"
                                    testProps={{
                                      elementName: "createCallbacksTextInput",
                                      screenName: name,
                                    }}
                                    value={values.callbacks}
                                  />
                                  <Body color={theme.colors.text.subdued}>
                                    Comma separated list of URLs
                                  </Body>
                                  <ErrorMessage name="callbacks">
                                    {error => errorText(error, "Callbacks")}
                                  </ErrorMessage>
                                </View>

                                <HorizontalSpacer size={theme.spacing.xxl} />

                                <View style={{ flex: 1, maxWidth: 450 }}>
                                  <TextInput
                                    label="Web origins"
                                    onBlur={() => {
                                      setFieldTouched("webOrigins", true);
                                      handleBlur("webOrigins");
                                    }}
                                    onChangeText={handleChange("webOrigins")}
                                    placeholder="Web origins"
                                    size="large"
                                    testProps={{
                                      elementName: "createWebOriginsTextInput",
                                      screenName: name,
                                    }}
                                    value={values.webOrigins}
                                  />
                                  <Body color={theme.colors.text.subdued}>
                                    Comma separated list of URLs
                                  </Body>
                                  <ErrorMessage name="webOrigins">
                                    {error => errorText(error, "Web origins")}
                                  </ErrorMessage>
                                </View>
                              </View>

                              <VerticalSpacer size={theme.spacing.xxl} />
                              <View style={{ flexDirection: "row", marginTop: 20 }}>
                                <View style={{ flex: 1, maxWidth: 450 }}>
                                  <Select
                                    defaultValue={ORG_USE_OPTIONS.find(
                                      ({ value }) => value === values.organizationUsage
                                    )}
                                    label="Organization usage"
                                    onSelectOption={option => {
                                      setFieldTouched("organizationUsage", true);
                                      if (typeof option.value === "string") {
                                        setFieldValue("organizationUsage", option.value);
                                      }
                                    }}
                                    options={ORG_USE_OPTIONS}
                                    placeholder={{
                                      label: "Organization usage",
                                      value: "",
                                    }}
                                    size="large"
                                    testProps={{
                                      elementName: "organizationUsage",
                                      screenName: name,
                                    }}
                                    usePortal
                                  />
                                  <VerticalSpacer size={theme.spacing.s} />
                                  <ErrorMessage name="organizationUsage">
                                    {error => errorText(error, "Organization usage")}
                                  </ErrorMessage>
                                </View>

                                <HorizontalSpacer size={theme.spacing.xxl} />
                                <HorizontalSpacer size={450} />
                              </View>
                            </>
                          )}
                        </View>
                      )}
                    </Hoverable>

                    <View style={styles.divider} />
                    <VerticalSpacer size={theme.spacing.xxl} />

                    <Heading
                      level="3"
                      testProps={{
                        elementName: "OauthHeading",
                        screenName: name,
                      }}
                    >
                      Scopes
                    </Heading>

                    <ErrorMessage name="capabilities">
                      {error => errorText(error, "Scopes")}
                    </ErrorMessage>

                    <View style={styles.scopesContainer}>
                      {Object.keys(Scopes).map(scope => (
                        <View key={String(scope)} style={styles.scope}>
                          <Heading
                            level="4"
                            style={styles.scopeHeader}
                            testProps={{
                              elementName: "OauthHeading",
                              screenName: name,
                            }}
                          >
                            {scope}
                          </Heading>
                          {Scopes[scope as keyof Capabilities]
                            .filter(sc => {
                              if (!isSol) {
                                return !solOnlyScopes.includes(sc.key);
                              }

                              return true;
                            })
                            .map((c: GetAgents200ResponseAgentsInnerCapabilitiesInner) => (
                              <Checkbox
                                defaultChecked={values.capabilities.includes(c.key)}
                                key={String(c.key)}
                                label={c.name}
                                onChange={() => {
                                  if (values.capabilities.includes(c.key)) {
                                    setFieldValue(
                                      `capabilities`,
                                      values.capabilities.filter(cap => cap !== c.key)
                                    );
                                  } else {
                                    setFieldValue(`capabilities`, [...values.capabilities, c.key]);
                                  }
                                }}
                                testProps={{
                                  elementName: `scopeCheckBox-${c.key}`,
                                  screenName: name,
                                }}
                              />
                            ))}
                        </View>
                      ))}
                    </View>
                  </>
                )}
              </Formik>
            </View>
          </ScrollView>
          <View style={styles.footer}>
            <Button
              onPress={handleFormSubmit}
              size="medium"
              testProps={{
                elementName: "createAgentButton",
                screenName: name,
              }}
              text="Create"
            />
          </View>
        </View>
      </FullScreenModalLayout>

      {showWarningModal && (
        <ConfirmationModal
          onClose={() => {
            setShowWarningModal(false);
          }}
          onOk={() => {
            setShowWarningModal(false);
            navigation.navigate("OrgSettings", { initialTab: "linkedApps" });
          }}
          testProps={{
            elementName: "unSave",
            screenName: name,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
