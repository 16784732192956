// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { Datagrid, DatagridBody, Pagination } from "../../components";
import { EllipsisText } from "../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, StyleSheet, View } from "react-native";
import { getContainerFieldValue } from "../../utils/getContainerFieldValue";
import { getDateTimeString } from "../../utils/time";
import { getFullName } from "../../utils/getFullName";
import { useAdminListData } from "./useAdminListData";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useCallback, useState } from "react";
import { useIsFocused } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type { OrgsGet200ResponseContainersInner as Container } from "../../gen";
import type { DatagridColumn } from "../../components/Datagrid/types";

const { Some } = Helpers;
const SCREEN_NAME = "OrganizationSettings";

export const AdminManagement = ({}) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const { theme } = useTheme();
  const { adminsLimit, meritFieldNames } = useAppConstantsStore();

  const [selectedAdmin, setSelectedAdmin] = useState<Container>();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useServerErrorHandler();
  const isFocused = useIsFocused();

  const {
    admins,
    isLoading: isFetching,
    nextPage,
    pagination,
    prevPage,
    refresh,
  } = useAdminListData(
    api,
    {
      limit: adminsLimit,
      orgID: selectedOrgId,
    },
    isFocused
  );

  const styles = StyleSheet.create({
    tabBody: {
      flex: 1,
    },
  });

  const removeAdmin = useCallback(async () => {
    if (Some(selectedAdmin)) {
      try {
        setIsLoading(true);
        await api.removeAdmin({ adminID: selectedAdmin.id, orgID: selectedOrgId });

        setSelectedAdmin(undefined);
        const firstName = getContainerFieldValue(meritFieldNames.firstName, selectedAdmin) ?? "";
        const lastName = getContainerFieldValue(meritFieldNames.lastName, selectedAdmin) ?? "";
        const fullName = `${firstName} ${lastName}`;

        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          size: "medium",
          testProps: {
            elementName: "adminRemoveSuccess",
            screenName: SCREEN_NAME,
          },
          text: `${fullName} was successfully removed as an admin.`,
          type: "success",
        });

        refresh();
      } catch (error) {
        errorHandler(error, {
          elementName: "adminRemoveError",
          screenName: SCREEN_NAME,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    api,
    deleteAlert,
    errorHandler,
    meritFieldNames.firstName,
    meritFieldNames.lastName,
    refresh,
    selectedAdmin,
    selectedOrgId,
    setAlert,
  ]);

  const columns: readonly DatagridColumn<Container>[] = [
    {
      key: "name",
      label: "Name",
      renderer: (container, testProps) => {
        const name = getFullName(
          getContainerFieldValue(meritFieldNames.firstName, container),
          getContainerFieldValue(meritFieldNames.lastName, container)
        );

        return <EllipsisText testProps={testProps} text={name} />;
      },
      size: "flex",
    },
    {
      key: "email",
      label: "Email",
      renderer: (container, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getContainerFieldValue(meritFieldNames.email, container) ?? "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "dateAdded",
      label: "Date added",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(container.createdAt) ? getDateTimeString(container.createdAt) : "--"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "Status",
      renderer: (container, testProps) => (
        <EllipsisText capitalize testProps={testProps} text={container.state?.name ?? "--"} />
      ),
      size: 120,
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: (container, testProps) => (
        <View style={{ maxWidth: 100 }}>
          <Button
            onPress={() => {
              setSelectedAdmin(container);
            }}
            size="small"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}RemoveButton`,
                  }
                : testProps
            }
            text="Remove"
            type="secondary"
          />
        </View>
      ),
      size: 147,
    },
  ];

  const showConfirmationModal = () => (
    <ConfirmationModal
      buttonText="remove"
      onClose={() => {
        setSelectedAdmin(undefined);
      }}
      onOk={() => {
        removeAdmin();
        setSelectedAdmin(undefined);
      }}
      testProps={{
        elementName: "adminRemoveConfirmation",
        screenName: SCREEN_NAME,
      }}
      text="Removing this admin will revoke their admin merit immediately and they will no longer be able to access your Org Portal."
      title="Are you sure you want to remove this admin?"
      titleIconName="warningMediumCritical"
    />
  );

  return (
    <>
      <View style={{ height: theme.spacing.xxl }} />
      <View style={styles.tabBody}>
        <ScrollView>
          <Datagrid
            columns={columns}
            loading={isFetching || isLoading}
            testProps={{
              elementName: "adminsListView",
              screenName: SCREEN_NAME,
            }}
          >
            <DatagridBody
              columns={columns}
              data={admins}
              testProps={{
                elementName: "adminsListView",
                screenName: SCREEN_NAME,
              }}
            />
          </Datagrid>
        </ScrollView>

        <Pagination
          disableNext={!pagination.hasNextPage}
          disablePrev={!pagination.hasPrevPage}
          onNext={() => {
            nextPage();
          }}
          onPrev={() => {
            prevPage();
          }}
          testProps={{
            elementName: "adminsListView",
            screenName: SCREEN_NAME,
          }}
        />

        {Some(selectedAdmin) && showConfirmationModal()}
      </View>
    </>
  );
};
