// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { getTemplateName } from "@src/utils/getTemplatename";
import type {
  ListTemplates200Response,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom,
} from "../../gen";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly rule: OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom;
  readonly ruleIndex: number;
  readonly testProps: OPTestProps;
  readonly templatesList: ListTemplates200Response["templates"];
};

export const ImmutablePolicyRule = ({ rule, ruleIndex, templatesList, testProps }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    divider: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: theme.spacing.xxl,
    },
    dividerElement: {
      backgroundColor: theme.colors.border.default,
      flex: 1,
      height: 1,
    },
    dividerText: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      marginHorizontal: theme.spacing.xl,
      paddingHorizontal: 2,
    },
  });

  const { arguments: templateIDs } = rule;

  return (
    <View key={JSON.stringify(rule)}>
      <>
        <VerticalSpacer size={theme.spacing.xs} />
        {ruleIndex !== 0 && (
          <>
            <View style={styles.divider}>
              <View style={styles.dividerElement} />
              <View style={styles.dividerText}>
                <Heading
                  level="6"
                  testProps={{
                    ...testProps,
                    elementId: ruleIndex.toString(),
                    elementName: `${testProps.elementName}AndText`,
                  }}
                >
                  AND
                </Heading>
              </View>
              <View style={styles.dividerElement} />
            </View>
            <VerticalSpacer size={30} />
          </>
        )}
        <View style={{ flexDirection: "row" }}>
          <Heading
            level="4"
            testProps={{
              ...testProps,
              elementId: ruleIndex.toString(),
              elementName: `${testProps.elementName}RuleNumber`,
            }}
          >
            Rule {ruleIndex + 1}
          </Heading>
        </View>
        <VerticalSpacer size={28} />
        <View>
          {templateIDs?.map((templateID, templatesArrayIndex) =>
            templatesArrayIndex === 0 ? null : (
              <View key={templateID}>
                <View>
                  <Body>Has an active merit from template</Body>
                </View>
                <VerticalSpacer size={theme.spacing.m} />
                <Heading
                  level="4"
                  testProps={{
                    ...testProps,
                    elementId: templatesArrayIndex.toString() + templateID,
                    elementName: `${testProps.elementName}TemplateId`,
                  }}
                >
                  {/** When we have more than 100 templates, the names are not displayed.
                   * Currently, we don't have the ability to retrieve template names by IDs.
                   * A solution needs to be provided by the platform."  */}
                  {getTemplateName(templateID, templatesList) ?? templateID}
                </Heading>
                <VerticalSpacer size={theme.spacing.xl} />
                <Heading
                  color={
                    templatesArrayIndex === 3 ? theme.colors.text.disabled : theme.colors.text.link
                  }
                  level="5"
                  testProps={{
                    ...testProps,
                    elementId: templatesArrayIndex.toString() + templateID,
                    elementName: `${testProps.elementName}OrText`,
                  }}
                >
                  + OR
                </Heading>
                <VerticalSpacer size={27} />
              </View>
            )
          )}
        </View>
      </>
    </View>
  );
};
