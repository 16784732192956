// Copyright 2023 Merit International Inc. All Rights Reserved

import { AdminForm } from "./CreateAdmin";
import { AdminManagement } from "./AdminManagement";
import { Button, Modal, useTheme } from "@merit/frontend-components";
import { ConnectedOrganizations } from "./ConnectedOrganizations/ConnectedOrganizations";
import { HorizontalSpacer, Tabs } from "../../components";
import { LinkedApps } from "./LinkedApps/LinkedApps";
import { OrganizationProfile } from "./OrganizationProfile";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { StyleSheet, View } from "react-native";
import { useAcceptedFolioStore } from "../../stores";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useMemo, useState } from "react";
import type { LDFeatureFlags } from "../../configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "@src/Router";
import type { RouteProp } from "@react-navigation/native";

export type OrgSettingsTabKey =
  | "adminManagement"
  | "connectedOrganizations"
  | "linkedApps"
  | "organizationProfile";

const SCREEN_NAME = "OrganizationSettings";

type Navigation = NativeStackNavigationProp<RouteParams, "OrgSettings">;

export const OrgSettings = () => {
  const { theme } = useTheme();
  const navigation = useNavigation<Navigation>();
  const { allowOrgPortalAgentsCreate, showLinkedAppsFeature } = useFlags<LDFeatureFlags>();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const { isFoliosLoaded, userAcceptedAccountFolio } = useAcceptedFolioStore();
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "OrgSettings">>();
  const [selectedTab, setSelectedTab] = useState<OrgSettingsTabKey>("organizationProfile");
  const [headerElements, setHeaderElements] = useState<React.JSX.Element>(<></>);
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const { DefaultLayout } = useFlaggedLayout();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
  });

  const tabs: readonly {
    readonly key: OrgSettingsTabKey;
    readonly label: string;
    readonly disabled?: boolean;
  }[] = useMemo(
    () => [
      {
        disabled: false,
        key: "organizationProfile",
        label: "Profile",
      },
      { disabled: false, key: "adminManagement", label: "Admins" },
      {
        key: "connectedOrganizations",
        label: "Connected organizations",
      },
    ],
    []
  );

  const updatedTabs = useMemo(() => {
    if (showLinkedAppsFeature) {
      return [...tabs, { disabled: false, key: "linkedApps", label: "Linked apps" } as const];
    }

    return tabs;
  }, [showLinkedAppsFeature, tabs]);

  // Load initial tab from route params
  useEffect(() => {
    if (routeParams !== undefined) {
      // Somehow routeParams is sometimes {} - this check is required to not crash in that case
      if ("initialTab" in routeParams) {
        setSelectedTab(routeParams.initialTab);
      }
    }
  }, [routeParams]);

  useEffect(() => {
    if (isFoliosLoaded && !userAcceptedAccountFolio) {
      setSelectedTab("connectedOrganizations");
    }
  }, [isFoliosLoaded, userAcceptedAccountFolio]);

  useEffect(() => {
    switch (selectedTab) {
      case "adminManagement":
        const adminManagementElements = (
          <View style={{ width: 116 }}>
            <Button
              onPress={() => {
                setShowAddAdminModal(true);
              }}
              size="small"
              testProps={{
                elementName: "addAdminButton",
                screenName: SCREEN_NAME,
              }}
              text="New admin"
            />
          </View>
        );
        setHeaderElements(adminManagementElements);
        break;

      case "connectedOrganizations":
        const connectedOrganizationsElements = (
          <>
            <SearchForm
              onClear={() => {
                setSearchQuery(undefined);
              }}
              onSearch={value => {
                setSearchQuery(value);
              }}
            />
            <HorizontalSpacer size={140} />
          </>
        );

        setHeaderElements(connectedOrganizationsElements);
        break;

      case "linkedApps":
        const linkedAppsElements = (
          <View style={{ width: 116 }}>
            {Boolean(allowOrgPortalAgentsCreate) && (
              <Button
                onPress={() => {
                  navigation.navigate("CreateApp");
                }}
                size="small"
                testProps={{ elementName: "createAppButton", screenName: SCREEN_NAME }}
                text="Create app"
              />
            )}
          </View>
        );
        setHeaderElements(linkedAppsElements);
        break;

      default:
        setHeaderElements(<></>);
        break;
    }
  }, [allowOrgPortalAgentsCreate, navigation, selectedTab]);

  return (
    <DefaultLayout
      breadcrumbs={[{ name: "Organization Settings" }]}
      headerRightElements={headerElements}
      tabs={
        <Tabs
          items={updatedTabs}
          onChange={tab => {
            setSelectedTab(tab);
          }}
          selected={selectedTab}
          testProps={{
            elementName: "orgSettings",
            screenName: SCREEN_NAME,
          }}
        />
      }
      testProps={{
        elementName: "orgSettings",
        screenName: SCREEN_NAME,
      }}
      title="Settings"
    >
      <View style={styles.container}>
        {selectedTab === "organizationProfile" && <OrganizationProfile />}

        {selectedTab === "adminManagement" && <AdminManagement />}

        {selectedTab === "linkedApps" && <LinkedApps />}

        {selectedTab === "connectedOrganizations" && (
          <ConnectedOrganizations searchQuery={searchQuery} />
        )}
      </View>

      {showAddAdminModal && (
        <Modal
          onClose={() => {
            setShowAddAdminModal(false);
          }}
          testProps={{
            elementName: "addAdmin",
            screenName: SCREEN_NAME,
          }}
          title="New admin"
        >
          <AdminForm
            onClose={() => {
              setShowAddAdminModal(false);
            }}
            testProps={{
              elementName: "addAdmin",
              screenName: SCREEN_NAME,
            }}
          />
        </Modal>
      )}
    </DefaultLayout>
  );
};
