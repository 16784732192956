import { Helpers } from "@merit/frontend-utils";
import type { GetTemplateRules200ResponseRules } from "../gen";

const { None, Some } = Helpers;

export const getPredicateReadableName = (
  fieldType: string,
  predicate: string,
  rules: GetTemplateRules200ResponseRules
) => {
  const fieldTypeInCamelCase = fieldType.charAt(0).toLowerCase() + fieldType.slice(1);

  const predicates = rules[fieldTypeInCamelCase as keyof GetTemplateRules200ResponseRules];

  if (None(predicates)) {
    // We may be viewing rules for a json field w/ the json feature flag disabled,
    // and the backend will not tell us about json field type predicates,
    // so we have to handle this here without an error.
    return "Unknown predicate";
  }
  const predicateInCamelCase = predicate.charAt(0).toLowerCase() + predicate.slice(1);

  // Hack: We do not have a specific type for predicates at the moment
  const predicateData = predicates[predicateInCamelCase as keyof typeof predicates];

  return Some(predicateData) ? predicateData.readable : "";
};
