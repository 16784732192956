// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, Spin, VerticalSpacer } from "../../components";
import { StyleSheet, View } from "react-native";
import { formatNumber } from "../../utils/formatNumbers";
import { useApi } from "../../api/api";
import { useEffect, useState } from "react";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type { GetTemplateStatistics200Response } from "../../gen/models";
import type { OPTestProps } from "../../types/TestProps";

type Props = {
  readonly templateID: string;
  readonly templateOwnerID: string | undefined;
  readonly testProps: OPTestProps;
};

const { Some } = Helpers;

export const TemplateMetrics = ({ templateID, templateOwnerID, testProps }: Props) => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();

  const [isLoading, setIsLoading] = useState(true);
  const [templateStats, setTemplateStats] = useState<GetTemplateStatistics200Response>();
  const { selectedOrgId } = useLoggedInAuthState();
  const styles = StyleSheet.create({
    container: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      minHeight: 139,
      width: 656,
    },
    horizontalLine: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
    spinnerContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      minHeight: 117,
    },
    statsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    totalStatsContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.surface.subdued,
      borderRadius: theme.borderRadii.s,
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    verticalLine: {
      backgroundColor: theme.colors.border.default,
      height: 40,
      width: 1,
    },
  });

  const { None } = Helpers;

  useEffect(() => {
    const fetchTemplateStats = async (queryOrgID: string) => {
      try {
        const resp = await api.getTemplateStatistics({ orgID: queryOrgID, templateID });
        setTemplateStats(resp);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (templateOwnerID === selectedOrgId) {
      fetchTemplateStats(templateOwnerID);
    }
  }, [api, errorHandler, selectedOrgId, templateID, templateOwnerID]);

  if (Some(templateOwnerID) && templateOwnerID !== selectedOrgId) {
    return (
      <View style={styles.container}>
        <VerticalSpacer size={theme.spacing.xxl} />
        <View style={styles.totalStatsContainer}>
          <Body size="l">Analytics for shared templates not yet supported.</Body>
        </View>
      </View>
    );
  }

  if (isLoading || None(templateOwnerID)) {
    return (
      <View style={styles.container}>
        <View style={styles.spinnerContainer}>
          <Spin color={theme.colors.icon.alert.success} size={20} />
          <HorizontalSpacer size={theme.spacing.l} />
          <Body size="l">Loading analytics</Body>
        </View>
      </View>
    );
  }

  return (
    <>
      <View style={styles.container}>
        <>
          <View style={styles.totalStatsContainer}>
            <Body>Total</Body>
            <HorizontalSpacer size={10} />
            <Heading
              level="3"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}TotalCount`,
              }}
            >
              {Some(templateStats) && templateStats.total !== 0
                ? templateStats.total.toLocaleString()
                : "0"}
            </Heading>
          </View>
          <View style={styles.horizontalLine} />
          <View style={styles.statsContainer}>
            <View>
              <Heading
                level="3"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}UnissuedCount`,
                }}
              >
                {Some(templateStats) && templateStats.unissued !== 0
                  ? formatNumber(templateStats.unissued)
                  : "0"}
              </Heading>
              <VerticalSpacer size={theme.spacing.xs} />
              <Body>Unissued</Body>
            </View>
            <View style={styles.verticalLine} />
            <View>
              <Heading
                level="3"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}PendingCount`,
                }}
              >
                {Some(templateStats) && templateStats.pending !== 0
                  ? formatNumber(templateStats.pending)
                  : "0"}
              </Heading>
              <VerticalSpacer size={theme.spacing.xs} />
              <Body>Pending</Body>
            </View>
            <View style={styles.verticalLine} />
            <View>
              <Heading
                level="3"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}AcceptedCount`,
                }}
              >
                {Some(templateStats) && templateStats.accepted !== 0
                  ? formatNumber(templateStats.accepted)
                  : "0"}
              </Heading>
              <VerticalSpacer size={theme.spacing.xs} />
              <Body>Accepted</Body>
            </View>
            <View style={styles.verticalLine} />
            <View>
              <Heading
                level="3"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}RejectedCount`,
                }}
              >
                {Some(templateStats) && templateStats.rejected !== 0
                  ? formatNumber(templateStats.rejected)
                  : "0"}
              </Heading>
              <VerticalSpacer size={theme.spacing.xs} />
              <Body>Rejected</Body>
            </View>
            <View style={styles.verticalLine} />
            <View>
              <Heading
                level="3"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}RevokedCount`,
                }}
              >
                {Some(templateStats) && templateStats.revoked !== 0
                  ? formatNumber(templateStats.revoked)
                  : "0"}
              </Heading>
              <VerticalSpacer size={theme.spacing.xs} />
              <Body>Revoked</Body>
            </View>
          </View>
        </>
      </View>
    </>
  );
};
