// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../components/Modals";
import { Fields } from "./Fields/Fields";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { Name } from "./Name";
import { Permissions } from "./Permissions";
import { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum as PermissionsActionEnum } from "../../gen";
import { Rules } from "./Rules";
import { Settings } from "./Settings";
import { Spin, Tabs } from "../../components";
import { StyleSheet, View } from "react-native";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useNavigationState, useRoute } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
  EditTemplateOperationRequest,
  EditTemplateRequest,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as TemplateFields,
  GetDatasource200ResponseMappedTemplatesInnerTypeEnum as TemplateType,
} from "../../gen";
import type { FormValues as FieldFormValues } from "./Fields/TemplateFields";
import type { FormikProps } from "formik";
import type {
  GetDatasource200ResponseMappedTemplatesInner,
  GetDatasource200ResponseMappedTemplatesInnerTypeEnum,
} from "../../gen/models/GetDatasource200ResponseMappedTemplatesInner";
import type { LDFeatureFlags } from "../../configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PermissionValue, FormValues as PermissionsFormValues } from "./Permissions";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";
import type { FormValues as RulesFormValues } from "./Rules/OrgCreatedRules";
import type { FormValues as SettingFormValues } from "./Settings";
// HACK: below properties can't be optional, remove these once it fixed from BE
type Template = GetDatasource200ResponseMappedTemplatesInner & {
  readonly name: string;
  readonly type: GetDatasource200ResponseMappedTemplatesInnerTypeEnum;
  readonly templateFields: readonly TemplateFields[];
};

export type FormValues = {
  readonly name: string;
  readonly description?: string;
  readonly templateType: TemplateType;
};

export const SCREEN_NAME = "ConfigureTemplate";

const { None, Some } = Helpers;

type TabName = "fields" | "name" | "permissions" | "rules" | "settings";

const tabs: readonly { readonly key: TabName; readonly label: string }[] = [
  { key: "name", label: "Name" },
  { key: "fields", label: "Fields" },
  { key: "rules", label: "Rules" },
  { key: "permissions", label: "Permissions" },
];

export const ConfigureTemplate = () => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { deleteAlert, setAlert } = useAlertStore();
  const {
    params: { id: templateId },
  } = useRoute<RouteProp<RouteParams, "ConfigureTemplate">>();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const rulesFormRef = useRef<FormikProps<RulesFormValues>>(null);
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const permissionFormRef = useRef<FormikProps<PermissionsFormValues>>(null);
  const fieldsFormRef = useRef<FormikProps<FieldFormValues>>(null);
  const settingsFormRef = useRef<FormikProps<SettingFormValues>>(null);

  const { showAutoApproveFeature, showAutoRevokeFeature } = useFlags<LDFeatureFlags>();

  const updatedTabs = useMemo(() => {
    if (showAutoApproveFeature || showAutoRevokeFeature) {
      return [...tabs, { key: "settings", label: "Settings" } as const];
    }

    return tabs;
  }, [showAutoApproveFeature, showAutoRevokeFeature]);

  if (None(selectedOrgId)) {
    throw new Error("Somehow org id not found");
  }

  const styles = StyleSheet.create({
    content: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
    tabs: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      paddingTop: theme.spacing.l,
    },
  });

  const [selectedTab, setSelectedTab] = useState<TabName>(tabs[0].key);
  const [templateDetails, setTemplateDetails] = useState<Template>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUnSaveModalOpen, setIsUnSaveModalOpen] = useState(false);
  const [selectedTabToSwitch, setSelectedTabToSwitch] = useState<TabName>();
  const { errorHandler } = useServerErrorHandler();

  const routes = useNavigationState(state => state.routes);
  const prevRoute = routes[routes.length - 2];

  useEffect(() => {
    if (!showAutoApproveFeature && !showAutoRevokeFeature) {
      setSelectedTab(tabs[0].key);
    }
  }, [showAutoApproveFeature, showAutoRevokeFeature]);

  const getContainer = useCallback(async () => {
    try {
      setIsLoading(true);
      const template = await api.getTemplate({
        orgID: selectedOrgId,
        templateID: templateId,
      });
      // @ts-expect-error can remove this comment once optional properties are fixed
      setTemplateDetails(template);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, errorHandler, selectedOrgId, templateId]);

  useEffect(() => {
    getContainer();
  }, [getContainer]);

  const getEditPermission = (appPermission: string, sharePermission: string) => {
    const readable = (p: string) => p === "read" || p === "extend";
    const extendable = (p: string) => p === "extend";

    return [
      {
        action: PermissionsActionEnum.Read,
        permissibleToPermit: {
          action: PermissionsActionEnum.Read,
          grantedToName: readable(sharePermission) ? "All" : "None",
        },
        permitted: {
          action: PermissionsActionEnum.Read,
          grantedToName: readable(appPermission) ? "All" : "None",
        },
      },
      {
        action: PermissionsActionEnum.Extend,
        permissibleToPermit: {
          action: PermissionsActionEnum.Extend,
          grantedToName: extendable(sharePermission) ? "All" : "None",
        },
        permitted: {
          action: PermissionsActionEnum.Extend,
          grantedToName: extendable(appPermission) ? "All" : "None",
        },
      },
    ];
  };

  const GetExistingPermission = (perms: readonly string[]): string => {
    if (perms.length === 0) {
      return "none";
    }

    if (perms.includes("extend")) {
      return "extend";
    }

    return "read";
  };

  const editTemplate = async (requestBody: EditTemplateOperationRequest) => {
    const existingAppPermission = GetExistingPermission(templateDetails?.permittedActions ?? []);
    const existingSharePermission = GetExistingPermission(
      templateDetails?.shareablePermissions ?? []
    );
    const editPermission = getEditPermission(existingAppPermission, existingSharePermission);

    const editTemplateRequestWithPermissions: EditTemplateRequest = {
      permissions: [...editPermission],
      // will overwrite the above permission if it has been provided e.g. on the permissions tab
      ...requestBody.editTemplateRequest,
    };
    const requestBodyWithPermissions: EditTemplateOperationRequest = {
      ...requestBody,
      editTemplateRequest: editTemplateRequestWithPermissions,
    };

    setIsLoading(true);
    try {
      await api.editTemplate(requestBodyWithPermissions);
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "Template has been saved.",
        type: "success",
      });
      await getContainer();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetTab = () => {
    setSelectedTab(tabs[0].key);
  };

  const closeScreen = () => {
    if (Some(formRef.current) && formRef.current.dirty) {
      setIsUnSaveModalOpen(true);

      return;
    }

    if (Some(rulesFormRef.current) && rulesFormRef.current.dirty) {
      setIsUnSaveModalOpen(true);

      return;
    }

    if (Some(permissionFormRef.current) && permissionFormRef.current.dirty) {
      setIsUnSaveModalOpen(true);

      return;
    }

    if (Some(fieldsFormRef.current) && fieldsFormRef.current.dirty) {
      setIsUnSaveModalOpen(true);

      return;
    }

    if (Some(settingsFormRef.current) && settingsFormRef.current.dirty) {
      setIsUnSaveModalOpen(true);

      return;
    }

    resetTab();
    if (navigation.canGoBack()) {
      if (prevRoute.name === "CreateTemplate") {
        navigation.navigate("Templates");
      } else {
        navigation.goBack();
      }

      return;
    }

    navigation.navigate("Templates", { initialTemplateId: templateId });
  };

  const changeTab = (currentTab: TabName) => {
    if (selectedTab === "name" && Some(formRef.current) && formRef.current.dirty) {
      setSelectedTabToSwitch(currentTab);

      return;
    }

    if (Some(rulesFormRef.current)) {
      if (selectedTab === "rules" && rulesFormRef.current.dirty) {
        setSelectedTabToSwitch(currentTab);

        return;
      }
    }

    if (Some(permissionFormRef.current) && permissionFormRef.current.dirty) {
      setSelectedTabToSwitch(currentTab);

      return;
    }

    if (Some(fieldsFormRef.current)) {
      if (selectedTab === "fields" && fieldsFormRef.current.dirty) {
        setSelectedTabToSwitch(currentTab);

        return;
      }
    }

    if (Some(settingsFormRef.current)) {
      if (selectedTab === "settings" && settingsFormRef.current.dirty) {
        setSelectedTabToSwitch(currentTab);

        return;
      }
    }

    setSelectedTab(currentTab);
  };

  const savePermissions = (readPermission: PermissionValue, extendPermission: PermissionValue) => {
    const requestBody = {
      editTemplateRequest: {
        description: templateDetails?.description,
        name: templateDetails?.name,
        permissions: [
          {
            action: "read",
            permissibleToPermit: { action: "read", grantedToName: readPermission },
            permitted: { action: "read", grantedToName: readPermission },
          },
          {
            action: "extend",
            permissibleToPermit: { action: "extend", grantedToName: extendPermission },
            permitted: { action: "extend", grantedToName: extendPermission },
          },
        ],
      } as EditTemplateRequest,
      orgID: selectedOrgId,
      templateID: templateId,
    };

    editTemplate(requestBody);
  };

  return (
    <FullScreenModalLayout
      onClose={closeScreen}
      testProps={{ elementName: "configureTemplate", screenName: SCREEN_NAME }}
      title={`Configure ${Some(templateDetails) ? templateDetails.name : "template"}`}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.tabs}>
          <View style={{ minWidth: 960 }}>
            <Tabs
              items={updatedTabs}
              onChange={changeTab}
              selected={selectedTab}
              testProps={{
                elementId: templateId,
                elementName: "configureTemplate",
                screenName: SCREEN_NAME,
              }}
            />
          </View>
        </View>

        <View style={styles.content}>
          {isLoading ? (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Spin />
            </View>
          ) : (
            <>
              {Some(templateDetails) && (
                <>
                  {selectedTab === "name" && (
                    <Name
                      formRef={formRef}
                      onFormSubmit={values => {
                        const requestBody = {
                          editTemplateRequest: {
                            description: values.description,
                            name: values.name,
                          },
                          orgID: selectedOrgId,
                          templateID: templateId,
                        };
                        editTemplate(requestBody);
                      }}
                      templateDetails={{
                        description: templateDetails.description,
                        name: templateDetails.name,
                        templateType: templateDetails.type,
                      }}
                      unSaveConfirmation={closeScreen}
                    />
                  )}
                  {selectedTab === "rules" && (
                    <Rules
                      formRef={rulesFormRef}
                      onSave={rules => {
                        const requestBody = {
                          editTemplateRequest: {
                            description: templateDetails.description,
                            name: templateDetails.name,
                            rules,
                          },
                          orgID: selectedOrgId,
                          templateID: templateId,
                        };
                        editTemplate(requestBody);
                      }}
                      template={templateDetails}
                      unSaveConfirmation={closeScreen}
                    />
                  )}
                  {selectedTab === "fields" && Some(templateDetails) && (
                    <Fields
                      formRef={fieldsFormRef}
                      getTemplatesContainer={() => {
                        getContainer();
                      }}
                      templateFields={templateDetails.templateFields}
                      templateID={templateDetails.id}
                      unSaveConfirmation={closeScreen}
                    />
                  )}
                  {selectedTab === "permissions" && (
                    <Permissions
                      formRef={permissionFormRef}
                      onSave={savePermissions}
                      permissions={templateDetails.permittedActions ?? []}
                      shareablePermissions={templateDetails.shareablePermissions ?? []}
                      unSaveConfirmation={closeScreen}
                    />
                  )}
                </>
              )}
              {selectedTab === "settings" && Some(templateDetails) && (
                <Settings
                  autoApprove={templateDetails.autoApprove === true}
                  autoRevoke={templateDetails.autoRevoke}
                  formRef={settingsFormRef}
                  onSave={settingFormValues => {
                    const requestBody = {
                      editTemplateRequest: {
                        autoApprove: settingFormValues.autoApprove,
                        autoRevoke: settingFormValues.autoRevoke,
                        description: templateDetails.description,
                        name: templateDetails.name,
                      },
                      orgID: selectedOrgId,
                      templateID: templateId,
                    };
                    editTemplate(requestBody);
                  }}
                  type={templateDetails.type}
                  unSaveConfirmation={closeScreen}
                />
              )}
            </>
          )}
        </View>
      </View>
      {isUnSaveModalOpen && (
        <ConfirmationModal
          onClose={() => {
            setIsUnSaveModalOpen(false);
          }}
          onOk={() => {
            resetTab();
            setIsUnSaveModalOpen(false);
            if (navigation.canGoBack()) {
              navigation.goBack();

              return;
            }

            navigation.navigate("Templates", { initialTemplateId: templateId });
          }}
          testProps={{
            elementName: "configureTemplateUnSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
      {Some(selectedTabToSwitch) && (
        <ConfirmationModal
          onClose={() => {
            setSelectedTabToSwitch(undefined);
          }}
          onOk={() => {
            setSelectedTab(selectedTabToSwitch);
            setSelectedTabToSwitch(undefined);
          }}
          testProps={{
            elementName: "configureTemplateUnSave",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </FullScreenModalLayout>
  );
};
